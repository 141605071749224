//---------------------------------------------------------//
@import 'normalize'; /* site wide variables (colours, font sizes) */
@import 'variables'; /* site wide variables (colours, fonts) */
@import 'toolkit'; /* mixin (@include font-size(??); , @include svg_img("../images/??");) and common classes (.clearfix, .img-replacment) */
@import 'general-tags-and-classes';
@import 'grid';/* adapted boostap 4 grid*/
@import 'header';
@import 'footer';
@import 'buttons';
@import 'forms';
@import 'swiper';
@import 'fancybox';
@import 'woocommerce';
//@import 'tabs';

//
//!!SITE WIDE STYLE
//

//
//!!STRUCTURE START
//

.lazy-loader{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: white;
	z-index: 9999;

	&.loaded{
		opacity: 0;
		display: none;
	}
}

.main-wrapper{
	padding-top: 0;
	padding-bottom: 0;
	width: 100%;

	section{
		overflow: hidden;
	}
}

.page-wrapper{
	margin: 0 auto;
	padding: 0;
	width: 100%;
	position: relative;
	transition: .25s;
	height: auto;
	padding-top: 0;
	bottom: 0;
}

//
//!!STRUCTURE END
//

//
//!!SIMPLE BASE STYLES START
//

.inner{
	position: relative;
}

img.full-bg,
.full-bg{
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}

img.full-bg{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 50% 50%;
}

//
//!!SIMPLE BASE STYLES END
//

//
//!! BREADCRUM START
//

// .breadcrumb-con{
// 	@include font-size(18);
// 	line-height: 1.2em;
// 	margin-bottom: 50px;
// 	color: $colour;
// 	font-weight: 400;
// 	display: flex;
// 	justify-content: flex-start;

// 	@media (max-width: $b3){
// 		margin-bottom: 30px;
// 	}

// 	span{
// 		color: white;
// 		@include font-size(18);
// 		line-height: 1.2em;
// 		display: inline-block;

// 		@media (max-width: $b3){
// 			@include font-size(12);
// 		}
// 	}

// 	a{
// 		@include font-size(18);
// 		letter-spacing: .015em;
// 		line-height: 1.2em;
// 		text-decoration: none;
// 		@include inline;
// 		vertical-align: middle;
// 		color: $red;
// 		font-weight: 400;
// 		margin-right: .2em;
// 		position: relative;
// 		display: inline-block;

// 		@media (max-width: $b3){
// 			@include font-size(12);
// 		}

// 		&:after{
// 			content: '/';
// 			display: inline-block;
// 			margin-left: .4em;
// 			color: $colour;
// 		}


// 		&:hover,
// 		&:focus-visible{
// 			color: $red;
// 			text-decoration: none;
// 		}
// 	}

// 	> a{
// 		margin-left: 0;
// 	}

// 	.breadcrumb_last{
// 		@include font-size(18);
// 		letter-spacing: .015em;
// 		line-height: 1.2em;
// 		color: $colour;
// 		text-decoration: none;
// 		@include inline;
// 		vertical-align: middle;
// 		font-weight: 400;
// 		position: relative;

// 		@media (max-width: $b3){
// 			@include font-size(12);
// 		}
// 	}
// }

//
//!! BREADCRUM END
//

// .pagination-con{
// 	display: flex;
// 	justify-content: flex-start;
// 	align-items: flex-start;
// }

.pagination.pagination.pagination{
	margin-bottom: 0;
	vertical-align: middle;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 3.1rem;

	.pagination-mid{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	div{
		text-align: center;
		margin: 0 .7rem;
		@include font-size(20);
		position: relative;
		z-index: 1;

		&:not(.prev):not(.next){

			&.active{
				pointer-events: none;

				a{
					color: $coral;
				}
			}

			span,
			a{
				@include font-size(24);
				display: block;
				color: $blue;
				font-weight: 500;
				padding: 0;
				text-decoration: none;

				&:hover,
				&:focus-visible{
					color: $coral;
				}
			}


			&.ellipse{
				color: $blue;
				font-weight: 500;
			}
		}

		&.next,
		&.prev{

			a{
				@include font-size(0);
				display: flex;
				justify-content: center;
				align-items: center;
				text-decoration: none;
				background-position: 50% 20%;
				background-repeat: no-repeat;
				height: 2.398rem;
				width: 2.398rem;
				background-size: 100% auto;

				&:hover,
				&:focus-visible{
					text-decoration: none;
				}
			}
		}

		&.next{
			
			a{
				background-image: url(../images/arrow-right.svg);

				&:hover,
				&:focus-visible{
					background-image: url(../images/arrow-right-coral.svg);
				}
			}
			
		}

		&.prev{
			
			a{
				background-image: url(../images/arrow-left.svg);

				&:hover,
				&:focus-visible{
					background-image: url(../images/arrow-left-coral.svg);
				}
			}
		
		}
	}
}

//
//!!SOCIAL MENU START
//
$social-size:		27.81;

$facebook:		#3b5999;
$twitter:		#55acee;
$linkedin:		#007bb5;
$pinterest:		#cb2027;
$instagram:		#8a3ab9;
$youtube:		#bb0000;

.social-menu{
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;

	a{
		@include font-size($social-size);
		line-height: 2.781rem;
		height: 2.781rem;
		min-width: 2.781rem;	
		display: block;
		text-align: center;
		color: $white;
		text-decoration: none;
		padding: 0;
		font-weight: 100;
		margin: 0 .75rem .75rem 0;

		&:last-of-type{
			margin-right: 0;
		}
		
		&:hover,
		&:focus-visible{
			
			i{
				color: $coral!important;
			}
		}

		i{
			@include font-size($social-size);
			line-height: 2.781rem;
			height: 2.781rem;
			min-width: 2.781rem;		
			display: block;
			transition: $time;
			display: flex;
			align-items: center;
		}

		&.facebook{

			&:hover,
			&:focus-visible{

				i{
					color: $facebook!important;
				}
			}
		}

	
		&.twitter{

			&:hover,
			&:focus-visible{

				i{
					color: $twitter!important;
				}
			}
		}

		&.linkedin{

			&:hover,
			&:focus-visible{

				i{
					color: $linkedin!important;
				}
			}
		}

		&.pinterest{

			&:hover,
			&:focus-visible{

				i{
					color: $pinterest!important;
				}
			}
		}

		&.instagram{

			&:hover,
			&:focus-visible{

				i{
					color: $instagram!important;
				}
			}
		}
	}
}

//
//!!SOCIAL MENU END
//

.waypoint{
	transition: .4s;
	opacity: 0;
	visibility: hidden;
	transform: translateY(5.0rem);
	will-change: transform;

	&.animate{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
}


@media (min-width: $b3 + 1){
	
	.main-wrapper{

		section{
			padding-right: 2.2rem;
		}
	}
}


.parallax-mirror{
	max-width: none;
}

section.parallax-window + section, 
section:has(+ section.parallax-window){
	background-color: white;
}

.parallax-window {
	background: transparent;
}

.image-hero-section{
	position: relative;
	
	&:before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		mix-blend-mode: multiply;
	}

	&.big{

		&:before{
			background-color: rgba($blue, .29);
		}

		.row{
			min-height: 83.5rem;
			padding-top: 30.6rem;
			padding-bottom: 10rem;

			.inner{
				width: 100%;
				position: relative;
			}
		}

		.reflection{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			opacity: .35;
			transform: rotateX(180deg);
			backface-visibility: visible!important;
			transform-origin: bottom;
			backdrop-filter: blur(.3rem);
			z-index: -1;

			*{
				color: transparent;
   				text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
			}

			hr{
				background-color: rgba(white,.56);
   				box-shadow: 0 0 15px 4px white;
			}

			p{
				display: none;
			}
		}

		hr{
			width: 60.3rem;
			height: 0.9rem;
			margin-bottom: 2.7rem;
		}

		h1{
			@include font-size(149);
			@include line-height(149,186);
			line-height: 0.959;
			margin-bottom: -0.11em;

			+ p{
				margin-top: .7em;
			}

			@media (max-width: $b3){
				@include font-size(49);
			}
		}

		p{
			@include font-size(29);
			@include line-height(29,41);
			letter-spacing: 0;
			margin-bottom: .95em;
			width: 80rem;
			margin-right: auto;
			margin-left: auto;

			@media (max-width: $b3){
				@include font-size(19);
			}
		}
	}

	&.medium{

		.page-id-281 &{

			.row{

				.inner{
					width: 110rem;
				}
			}
		}

		.page-id-604 &{

			.row{
				min-height: 88.3rem;
				padding-top: 30.5rem;

				@media (max-width: $b3){
					padding-top: 25rem;
				}

				.inner{
					width: 110rem;
				}

				hr{
					margin-bottom: 1.5rem;
				}


				h1{
					@include font-size(71);
					@include line-height(71,80);
					letter-spacing: .065rem;
					margin-bottom: .17em;

					@media (max-width: $b3){
						@include font-size(49);
					}
				
					@media (max-width: 380px){
						@include font-size(46);
					}
				}

				p{
					@include font-size(22);
					@include line-height(22,29);
					width: 93.6rem;
					margin-right: auto;
					margin-left: auto;
					letter-spacing: .024em;
					margin-bottom: 1.8em;

					@media (max-width: $b3){
						@include font-size(19);
						@include line-height(19,29);
					}

					+ p{
						width: 105.6rem;
					}

					strong{
						letter-spacing: .016em;
					}
				}
			}
		}

		&:before{
			background-color: rgba($blue, .46);
		}

		.row{
			min-height: 75.3rem;
			padding-top: 35rem;
			padding-bottom: 10rem;

			@media (min-width: $b3 + 1){

				.parent-pageid-506 &{
					min-height: 83.8rem;
					padding-top: 40.4rem;
				}
			}

			@media (max-width: $b3){
				min-height: 55.3rem;
			}
		}

		p{
			@include font-size(29);
			@include line-height(29,41);
			letter-spacing: 0;
			margin-bottom: .95em;

			@media (max-width: $b3){
				@include font-size(19);
			}

			&:has(+ h1){
				margin-bottom: .05em;
			}
		}

		h1 + hr{
			width: 13.8rem;
			margin: 1.5rem auto 1rem;
		}


	}

	&.small{

		&:before{
			background-color: rgba($blue, .46);
		}

		.row{
			min-height: 52rem;
			padding-top: 29.75rem;
			padding-bottom: 2rem;

			@media (max-width: $b3){
				min-height: 45rem;
			}
		}
	}

	.container{
		max-width: 159.6rem;
	}

	.btn{
		min-width: 25.9rem;

		@media (max-width: $b3){
			min-width: 0;
			font-size: 1.5rem!important;
			padding: 1.15rem 1.6rem;
			border-width: .8rem;
		}
	}

	.row{
		text-align: center;
		position: relative;
		z-index: 1;

		*{
			color: white;
		}

		hr{
			background-color: white;

			&:first-child{
				margin-top: 0;
			}
		}

		.inner{
			width: 80rem;
			margin-right: auto;
			margin-left: auto;

			.error404 &{
				width: 120rem;
			}
		}
	}
}

.image-hero-section + .block-links-section{

	*{
		color: white;
	}
}

.block-links-section{
	margin-top: -9.6rem;
	padding-bottom: 6.4rem;
	position: relative;
	z-index: 1;

	@media (max-width: $b3){
		margin-top: -13.6rem;
		padding-bottom: 4.4rem;

		.swiper-wrapper-con{
			overflow: visible!important;		
		}
	}

	.container{
		max-width: 154.2rem;
	}

	.block-links-slide{
		width: calc(100% + 1.65rem + 1.65rem);
		max-width: calc(100% + 1.65rem + 1.65rem);
		margin-left: -1.65rem;

		@media (max-width: $b3){
			width: calc(100% + .85rem + .85rem);
			max-width: calc(100% + .85rem + .85rem);
			margin-left: -.85rem
		}

		.swiper-wrapper{
			//align-items: flex-end;
		}
	}

	.swiper-slide{
		padding: 0 1.65rem 1.65rem;
		margin-top: 1.7rem;

		@media (max-width: $b3){
			padding: 0 .85rem .85rem;
		}

		&[data-swiper-slide-index="0"],
		&[aria-label="1 / 3"]{

			.inner{
				border-top: 2.3rem solid $coral;
				position: relative;

				&:after{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba($purple, .45);
					backdrop-filter: blur(.3rem);
					filter: brightness(.85);
					z-index: -1;
				}
			}
		}

		&[data-swiper-slide-index="1"],
		&[aria-label="2 / 3"]{

			.inner{
				border-top: 2.3rem solid $green-light;
				position: relative;

				&:after{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba($green, .45);
					backdrop-filter: blur(.3rem);
					filter: brightness(.85);
					z-index: -1;
				}
			}
			
		}

		&[data-swiper-slide-index="2"],
		&[aria-label="3 / 3"]{

			.inner{
				border-top: 2.3rem solid $blue-light;
				position: relative;

				&:after{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba($blue, .45);
					backdrop-filter: blur(.3rem);
					filter: brightness(.85);
					z-index: -1;
				}
			}
		}
		
		.inner{
			position: relative;

			*:last-child{
				margin-bottom: 0;
			}

			&.has-link{

				a{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 1;

					&:hover,
					&:focus-visible{

						~ .bottom{
			
							.img-con{
				
								.img{
									transform: translate(-50%, -50%) scale(1.01);
								}
							}
						}
					}
				}

				// .top{
				// 	position: relative;

				// 	&:before,
				// 	&:after{
				// 		transform: translateY(100%);
				// 	}

				// 	&:before{
				// 		content: '';
				// 		position: absolute;
				// 		top: 0;
				// 		left: 0;
				// 		width: 100%;
				// 		height: 100%;
				// 		background-color: rgba($white, .39);
				// 		backdrop-filter: blur(.9rem);
				// 		transition: $time;
				// 		z-index: -1;
				// 	}

				// 	&:after{
				// 		content: 'FIND OUT MORE';
				// 		font-style: italic;
				// 		font-weight: 900;
				// 		position: absolute;
				// 		top: 0;
				// 		left: 0;
				// 		width: 100%;
				// 		height: 100%;
				// 		display: flex;
				// 		align-items: flex-end;
				// 		padding: 2.9rem 4.1rem 1.6rem;
				// 		letter-spacing: .277em;
				// 		transition: $time;
				// 	}
				// }
			}
		}
	
		.top{
			padding: 1.9rem .5rem 1.9rem 3.6rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			transition: $time;
		}

		h4{
			@include font-size(38);
			@include line-height(38,41);
			letter-spacing: .03em;
		}

		p{
			@include line-height(19,23);
			transition: $time;
			opacity: 1;
		}

		.bottom{
			//box-shadow: 0 .3rem .6rem rgba($black, .38);
			padding: 0 0 0 3.6rem;
			
			.img-con{
				width: 100%;
				aspect-ratio: 1.4664/1;
				position: relative;
				overflow: hidden;
				box-shadow: 0 .3rem .6rem rgba($black, .38);

				.img{
					position: absolute;
					top: 50%;
					left: 50%;
					width: 100%;
					height: 100%;
					transform: translate(-50%, -50%) scale(1.05);
					transition: $time;
				}
			}
		}
	}
}

.wide-image-section + .text-with-image-section{


	.text-side{

		@media (min-width: $b3 + 1){
			padding-left: 11.75rem;
		}

		p{
			margin-bottom: 3.1em;


			a{
				text-decoration: none;
				font-weight: 700;
			}
		}
	}
}

.leaders-section + .text-with-image-section{
	padding-top: 0;
	padding-bottom: 8.4rem;
	margin-top: -5.8rem;

	&::before{
		right: -4.6rem;
		bottom: 0;
		background-image: url(../images/waves-light.svg);

		@media (max-width: $b3){
			display: none;
		}
	}

	.container{
		max-width: 155.5rem;
	}

	.row{
		align-items: flex-start;
	}

	.text-side.text-side{
		padding-top: 3.1rem;

		@media (min-width: $b3 + 1){
			flex: 0 0 60%;
			max-width: 60%;
			padding-right: 1.75rem;
			padding-left: 15rem;
		}

		p{
			color: rgba(black, .48);
			opacity: 1;
		}

		h3{
			margin-bottom: .7em;
			color: $coral;
			letter-spacing: .02em;
		}

		p.large{
			margin-bottom: 1.3em;
			letter-spacing: .024em;
		}
	}

	.image-side{

		@media (min-width: $b3 + 1){
			flex: 0 0 40%;
			max-width: 40%;
		}

		img{
			aspect-ratio: 1.2119/1;
			object-fit: cover;
			object-position: 50% 50%;
		}
	}
}

.text-with-image-section{
	padding-top: 6.3rem;
	padding-bottom: 3.9rem;
	position: relative;

	@media (max-width: $b3){
		padding-top: 4.2rem;
	}

	&::before{
		content: '';
		position: absolute;
		right: 0;
		bottom: 0;
		width: 53.44946rem;
		height: 17.30278rem;
		background-image: url(../images/waves.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: 100% 0;

		@media (max-width: $b3){
			display: none;
		}
	}

	.container{
		max-width: 171.8rem;
	}

	.row{
		align-items: center;
	}

	.text-side{
		text-align: right;

		@media (max-width: $b3){
			text-align: left;
			padding-right: 3.7rem;
			padding-left: 3.7rem;
		}

		@media (min-width: $b3 + 1){
			flex: 0 0 46%;
			max-width: 46%;
			padding-right: 5.05rem;
			padding-left: 2.75rem;
			padding-top: .5rem;
		}

		p{

			&:first-of-type{
				opacity: .5;
			}
		}
	}

	h1{
		@include font-size(82);
		letter-spacing: .02em;
		font-weight: 900;
		margin-bottom: -.02em;

		@media (max-width: $b3){
			@include font-size(33);
			margin-bottom: .2em;
		}
	}

	p{
		letter-spacing: .026em;
	}

	.btn-con{
		margin-top: 2rem;

		@media (max-width: $b3){
			margin-top: 2.3rem;
			justify-content: flex-start;
		}
	}

	.image-side{

		a{
			position: relative;

			&:hover,
			&:focus-visible{

				&::before{
					background-color: white;	
				}

				&:after{
					border-color: transparent transparent transparent $blue;
				}
			}

			&::before{
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 23.5rem;
				height: 23.5rem;
				border-radius: 23.5rem;
				border: 1.4rem solid white;

				@media (max-width: $b3){
					width: 10.5rem;
					height: 10.5rem;
				}
			}

			&:after{
				content: '';
				position: absolute;
				top: 50%;
				left: 51.1%;
				transform: translate(-50%, -50%);
				width: 0;
				height: 0;
				border-width: 4.702rem 0 4.702rem 8.173rem;
				border-color: transparent transparent transparent white;
				border-style: solid;

				@media (max-width: $b3){
					border-width: 2.302rem 0 2.302rem 3.773rem;
				}
			}
		}

		@media (min-width: $b3 + 1){
			flex: 0 0 54%;
			max-width: 54%;
		}

		@media (max-width: $b3){
			margin-top: 4.5rem;
			padding: 0;

			img{
				aspect-ratio: .955/1;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}
	}
}

.text-with-image-alternative-section{
	padding-bottom: 9.9rem;

	@media (max-width: $b3){
		padding-bottom: 5.9rem;
	}

	.container{
		max-width: 160.8rem;
	}

	.row{
		align-items: center;

		@media (min-width: $b3 + 1){

			.single-events &{
				align-items: flex-start;
			}
		}
	}

	*{
		color: $blue;
	}

	.text-side{
		padding-top: 7.1rem;

		@media (min-width: $b3 + 1){
			flex: 0 0 53.9%;
			max-width: 53.9%;
			padding-left: 4.6rem;

			.single-events &{
				padding-left: 4.5rem;
				padding-top: 7.7rem;
				padding-right: 2rem;
			}
		}
	}

	h6{
		width: 100%;
		@include font-size(17);
		@include line-height(17,32);
		letter-spacing: .197em;
		margin-bottom: 2.3em;

		@media (min-width: $b3 + 1){
			
			.single-events &{
				margin-bottom: 2.9em;
			}
		}

		&:before{
			background-color: $blue;
			opacity: .81;
		}
	}

	p{
		letter-spacing: .037em;

		@media (min-width: $b3 + 1){
			
			.single-events &{
				margin-bottom: 1.4em;
				padding-right: 2rem;
			}
		}

		@media (min-width: $b3 + 1){
			padding-left: 3.9rem;
		}

		&.large{
			@include font-size(23);
			@include line-height(23,32);
			letter-spacing: .028em;

			@media (max-width: $b3){
				@include font-size(19);
				@include line-height(19,29);
			}
		}
	}

	.btn-con{
		margin-top: 2rem;
	}

	.image-side{

		@media (min-width: $b3 + 1){
			flex: 0 0 46.1%;
			max-width: 46.1%;

			img{
				width: calc(100% + 1.7rem + ((100vw - 160.8rem)/2));
				max-width: calc(100% + 1.7rem + ((100vw - 160.8rem)/2));
				float: right;
			}
		}

		img{
			box-shadow: 3.6rem 3.1rem 0 $coral;
		}
	}
}

.steps-section{
	padding-top: 12.6rem;
	padding-bottom: 7.1rem;

	@media (max-width: $b3){
		padding-top: 0.6rem;
		padding-bottom: 3.2rem;
	}

	.container{
		max-width: 159.4rem;
	}

	.row{

		@media (max-width: $b3){
			justify-content: flex-end;
		}
	}

	.image-side{
		font-size: 0;
		
		@media (min-width: $b3 + 1){
			flex: 0 0 60.55%;
			max-width: 60.55%;

			img{
				width: calc(100% + 1.7rem + ((100vw - 159.4rem)/2));
				max-width: calc(100% + 1.7rem + ((100vw - 159.4rem)/2));
				float: right;
			}
		}

		@media (max-width: $b3){
			order: 2;
			padding-left: 0;
			padding-right: 0;
		}
	}

	hr{
		margin: 1.3rem 0 1.5rem;
		width: 58.2%;

		@media (min-width: $b3 + 1){
			width: calc(58.2% + 12.75rem + 2.75rem);
			margin-left: -12.75rem - 2.75rem;
		}

		@media (max-width: $b3){
			width: calc(96.3% + 4.2rem + 5.5rem);
			max-width: calc(96.3% + 4.2rem + 5.5rem);
			margin-left: -9.65rem;
		}
	}

	.text-side{
		padding-top: 5.4rem;
		position: relative;

		@media (max-width: $b3){
			padding-top: 0.4rem;
			flex: 0 0 87.3%;
			max-width: 87.3%;
			background-color: rgba(white, .39);
			padding-left: 4.2rem;
			padding-bottom: 4rem;
			z-index: 1;
			margin-bottom: -11.9rem;
		}

		@media (min-width: $b3 + 1){
				
			&.waypoint:after{
				max-height: 0;
			}
			
			&.animate:after{
				max-height: 56.1rem;
				transition: $time*2 $time*2;
			}
		}

		&:after{
			content: '';
			position: absolute;
			width: 37rem;
			height: 56.1rem;
			background-image: url(../images/gl1.svg);
			background-repeat: no-repeat;
			background-size: 100% 56.1rem;
			background-position: 50% 0;
			z-index: -1;
			left: -30.3%;
			top: calc(100% + -12.4rem);
			
			@media (max-width: $b3){
				display: none;
			}
		}

		@media (min-width: $b3 + 1){
			flex: 0 0 39.45%;
			max-width: 39.45%;
			// padding-right: 5.05rem;
			padding-left: 12.75rem;
			// padding-top: .5rem;
		}

		p{
			letter-spacing: .197em;
			opacity: .39;
			color: black;
		}

		h1{
			@include font-size(72);
			@include line-height(72,68);
			font-weight: 900;
			letter-spacing: .02em;
			padding-right: 10rem;
			text-transform: uppercase;

			@media (max-width: $b3){
				padding-right: 0;
				@include font-size(49);
				@include line-height(49,52);
			}

			em{
				font-weight: 400;
				letter-spacing: -.005em;
			}
		}

		h1,h2,h3{
			color: $coral;
		}

		hr{
			background-color: $coral;
		}
	}

	.row:not(.goal){
		margin-bottom: 25.4rem;

		@media (max-width: $b3){
			margin-bottom: 6.8rem;
		}
	}

	.row.goal{
		align-items: center;


		&:nth-of-type(3n + 2){
			margin-bottom: 8rem;

			@media (max-width: $b3){
				margin-bottom: 3rem;
			}

			.img-side{
				z-index: 1;

				&::before{
					left: -1.2rem;
					bottom: -5.7em;
				}
				
				@media (min-width: $b3 + 1){
					flex: 0 0 47.5%;
					max-width: 47.5%;
				}

				@media (max-width: $b3){
					
					img{
						width: 124%;
						max-width: 124%;
						margin-top: -1.8rem;
					}
				}
			}

			.goal-side{

				@media (min-width: $b3 + 1){
				
					&.waypoint:after{
						max-height: 0;
					}
					
					&.animate:after{
						max-height: 54.9rem;
						transition: $time*2 $time*2;
					}
				}

				&:after{
					width: 56.4rem;
					height: 54.9rem;
					background-image: url(../images/gl2.svg);
					left: -64.9%;
					top: calc(100% + 0rem);
					background-size: 100% 54.9rem;

					@media (max-width: $b3){
						display: none;
					}
				}

				@media (min-width: $b3 + 1){
					flex: 0 0 52.5%;
					max-width: 52.5%;
					padding-left: 0;
					margin-top: 1rem;

					.goal{
						width: calc(100% + 3.2rem);
						max-width: calc(100% + 3.2rem);
						margin-left: -3.2rem;
					}
				}
			}
		}

		&:nth-of-type(3n + 3){
			margin-bottom: -2.1rem;

			.img-side{

				&::before{
					right: -36.2rem;
					top: -9.1em;
				}

				@media (min-width: $b3 + 1){
					flex: 0 0 47.5%;
					max-width: 47.5%;
					order: 2;
				}
			}

			.goal-side{

				@media (min-width: $b3 + 1){
				
					&.waypoint:after{
						max-height: 0;
					}
					
					&.animate:after{
						max-height: 57.5rem;
						transition: $time*2 $time*2;
					}
				}

				&:after{
					width: 52.1rem;
					height: 57.5rem;
					background-image: url(../images/gl3.svg);
					left: 14.2%;
					top: calc(100% + 0rem);
					background-size: 100% 57.5rem;

					@media (max-width: $b3){
						display: none;
					}
				}

				@media (min-width: $b3 + 1){
					flex: 0 0 45.5%;
					max-width: 45.5%;
					margin-left: 7%;

					.number{
						left: -1.9rem;
					}
				}

				@media (max-width: $b3){
				
					.number{
						top: -16.5rem;
						left: 1.2rem;
					}
				}
			}

			.img-side{

				@media (min-width: $b3 + 1){
					
					img{
						width: 120.4%;
						max-width: 122%;
						margin-left: -6.4rem;
						margin-top: -12.8rem;
					}
				}

				@media (max-width: $b3){
					padding-right: 0;
					padding-left: 0;
				}
			}
		}

		&:nth-of-type(3n + 4){

			.img-side{

				&::before{
					right: -22.6rem;
					top: -2.4em;
				}

				@media (min-width: $b3 + 1){
					flex: 0 0 43.4%;
					max-width: 43.4%;

					img{
						float: right;
						width: 110%;
						max-width: 110%;
					}
				}

				@media (max-width: $b3){
					padding-left: 0;
					padding-right: 0;
					margin-top: 4rem;
				}
			}

			.goal-side{

				@media (min-width: $b3 + 1){
					flex: 0 0 53.6%;
					max-width: 53.6%;
					padding-left: 0;
					margin-top: 17.2rem;

					.number{
						top: -19.4rem;
						left: -5.9rem;
					}

					.goal{
						width: calc(100% + 1.5rem);
						max-width: calc(100% + 1.5rem);
						margin-left: -1.5rem;
					}
				}

				@media (max-width: $b3){
					
					.number{
						top: -16.6rem;
						right: -2.6rem;
						left: auto;
					}
				}
			}
		}

		.img-side{
			position: relative;

			@media (max-width: $b3){
				order: 2;
			}

			&::before{
				content: '';
				position: absolute;
				width: 53.44946rem;
				height: 17.30278rem;
				background-image: url(../images/waves.svg);
				background-repeat: no-repeat;
				background-size: 100% 100%;
				background-position: 100% 0;
				z-index: -1;

				@media (max-width: $b3){
					display: none;
				}
			}

			img{
				filter: drop-shadow(.4rem .6rem .9rem rgba($black, .26));
			}
		}

		.goal-side{
			position: relative;

			@media (max-width: $b3){
				padding-left: 0;
				padding-right: 0;
			}

			&:after{
				content: '';
				position: absolute;
				background-repeat: no-repeat;
				background-size: 100% 100%;
				background-position: 50% 0;
				z-index: -1;
			}

			.number{
				position: absolute;
				top: -19.1rem;
				left: -8rem;
				@include font-size(236);
				opacity: .13;
				color: $blue;
				z-index: -1;
				font-weight: 700;
				font-family: $alt-font2;
				letter-spacing: -.02em;

				@media (max-width: $b3){
					top: -6.6rem;
					left: -6rem;
				}
			}

			.goal{
				display: flex;
				border-top: 1px solid $blue;
				border-bottom: 1px solid $blue;

				.icon-con{
					border-right: 1px solid $blue;
					padding: 2rem;
					display: flex;
					align-items: center;

					@media (max-width: $b3){
						padding-left: 0;
						width: 6rem;
						min-width: 6rem;

						img{
							width: 199%;
							max-width: 199%;
							margin-left: -99%;
						}
					}
				}

				.goal-con{
					border-right: 1px solid $blue;
					padding: 1rem;
					display: flex;
					
					div{
						@include font-size(30);
						@include line-height(30,41);
						letter-spacing: .02em;
						font-weight: 600;
						font-style: italic;
						writing-mode: vertical-lr;
						text-align: center;
						color: $blue;
						transform: rotate(180deg);

						@media (max-width: $b3){
							@include font-size(34);
						}
					}
				}
			}

			.text-con{
				flex: 1 1 auto;

				h2{
					@include line-height(62,41);
					font-weight: 900;
					letter-spacing: .410em;
					margin-bottom: 0;
					border-bottom: 1px solid $blue;
					padding: 2.3rem 0 2rem 1.8rem;
					text-transform: uppercase;

					@media (max-width: $b3){
						padding: 2.1rem 0 2.1rem 2.1rem;
						letter-spacing: 0.05em;
						padding-right: 1rem;
						@include font-size(35);
					}
				}

				p{
					padding: 1.2rem 0 1.2rem 1.8rem;
					margin-bottom: 0;
					color: $blue;
					letter-spacing: .117em;
					width: calc(100% + 1rem);
					max-width: calc(100% + 1rem);

					@media (max-width: $b3){
						padding: 0.7rem 0 0.7rem 2.1rem;
					}
				}
			}
		}
	}
}

.steps-alternative-section{
	padding-top: 16.4rem;
	padding-bottom: 13.6rem;

	@media (max-width: $b3){
		padding-top: 7.5rem;
		padding-bottom: 7.5rem;
	}

	.container{
		max-width: 165.6rem;
	}

	hr{
		margin: 1.3rem 0 1.5rem;
		width: 58.2%;

		@media (min-width: $b3 + 1){
			width: calc(96% + 10.75rem + ((100vw - 165.6rem)/2));
			max-width: calc(96% + 10.75rem + ((100vw - 165.6rem)/2));
			margin-left: -22.9rem;
		}
	}

	.text-side{
		position: relative;

		@media (min-width: $b3 + 1){
			flex: 0 0 41.35%;
			max-width: 41.35%;
			// padding-right: 5.05rem;
			padding-left: 10.75rem;
			// padding-top: .5rem;

			&::before{
				content: '';
				position: absolute;
				left: -27.6rem;
				bottom: 28.2rem;
				width: 53.44946rem;
				height: 17.30278rem;
				background-image: url(../images/waves.svg);
				background-repeat: no-repeat;
				background-size: 100% 100%;
				background-position: 100% 0;

				@media (max-width: $b3){
					display: none;
				}
			}
		}

		p{
			letter-spacing: .197em;
			opacity: .39;

			~ p{
				letter-spacing: .037em;
				opacity: .50;
				margin-bottom: 1.7em;
			}
		}

		h1{
			@include font-size(72);
			@include line-height(72,68);
			font-weight: 900;
			letter-spacing: .01em;
			text-transform: uppercase;
			margin-bottom: .3em;

			@media (max-width: $b3){
				@include font-size(49);
			}

			em{
				font-weight: 400;
				letter-spacing: .01em;
			}
		}

		hr{
			background-color: $blue;
		}
	}

	.goal-side{

		@media (min-width: $b3 + 1){
			flex: 0 0 58.65%;
			max-width: 58.65%;
		}

		.goal-outer{
			position: relative;
			padding-top: 4.7rem;
			padding-left: 7.6rem;
			margin-bottom: 10.1rem;

			@media (max-width: $b3){
				padding-left: 0;
			}

			@media (min-width: $b3 + 1){
				
				&:last-of-type{
				
					&:before{
						content: '';
						position: absolute;
						bottom: 7.5rem;
						width: 16.3rem;
						height: .3rem;
						background-color: $coral;
						right: calc(100% - 4.3rem);
					}
				}
			}
			
			&:last-of-type{
				margin-bottom: 0;
			}

			&:after{
				content: '';
				position: absolute;
				background-repeat: no-repeat;
				background-size: 100% 100%;
				background-position: 50% 0;
				z-index: -1;
			}

			.number{
				position: absolute;
				top: -13.2rem;
				left: .6rem;
				@include font-size(236);
				opacity: .13;
				color: $blue;
				z-index: -1;
				font-weight: 700;
				font-family: $alt-font2;
				letter-spacing: -.02em;
			}

			.goal{
				display: flex;
				border-top: .3rem solid $blue;
				border-bottom: .3rem solid $blue;

				.icon-con{
					border-right: .3rem solid $blue;
					padding: 2rem 3.3rem 2rem 0rem;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					flex: 0 0 10.8rem;
					
					@media (max-width: $b3){
						max-width: 5.5rem;	
						padding: 2rem 2.5rem 2rem 0rem;
					}
					
					img{
						float: right;
						width: 10rem;
						max-width: 10rem;
						margin-left: -2.7rem;
					}
				}

				.goal-con{
					border-right: .3rem solid $blue;
					padding: .9rem;
					display: flex;
					
					div{
						@include font-size(30);
						@include line-height(30,41);
						letter-spacing: .02em;
						font-weight: 600;
						font-style: italic;
						writing-mode: vertical-lr;
						text-align: center;
						color: $blue;
						transform: rotate(180deg);
					}
				}
			}

			.text-con{
				flex: 1 1 auto;

				h2{
					@include line-height(62,41);
					font-weight: 900;
					letter-spacing: .410em;
					margin-bottom: 0;
					border-bottom: .3rem solid $blue;
					padding: 2.3rem 0 1.7rem 1.8rem;
					text-transform: uppercase;

					@media (max-width: $b3){
						padding: 2.1rem 0 2.1rem 2.1rem;
						letter-spacing: 0.05em;
						padding-right: 1rem;
						@include font-size(35);
					}
				}

				p{
					padding: 1.45rem 0 2rem 1.7rem;
					margin-bottom: 0;
					color: $blue;
					letter-spacing: .107em;
					text-transform: capitalize;

					@media (max-width: $b3){
						padding: 0.7rem 0 0.7rem 2.1rem;
					}
				}
			}
		}
	}

	.image-side-under{
		font-size: 0;

		@media (max-width: $b3){
			margin-top: 5rem;
			margin-bottom: 2rem;
		}

		@media (min-width: $b3 + 1){
			flex: 0 0 32.6%;
			max-width: 32.6%;
			margin-top: -7.7rem;

			.img-con{
				position: relative;
				display: flex;
				justify-content: flex-end;

				&:before{
					content: '';
					position: absolute;
					bottom: 4.2rem;
					width: 8.3rem;
					height: .3rem;
					background-color: $coral;
					left: 100%;
				}

				&:after{
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					height: 100%;
					width: .3rem;
					background-color: $coral;
					right: -8.3rem;
				}
			}

			img{
				width: calc(100% + 1.7rem + ((100vw - 165.6rem)/2));
				max-width: calc(100% + 1.7rem + ((100vw - 165.6rem)/2));
				float: right;
			}
		}

		img{
			box-shadow: -3rem -2.7rem 0 $coral;
		}
	}

	.text-side-under{
		
		@media (min-width: $b3 + 1){
			flex: 0 0 37.6%;
			max-width: 37.6%;
			margin-top: 13.4rem;
			padding-left: 8.8rem;
			position: relative;

			&::before{
				content: '';
				position: absolute;
				right: -74.7rem;
				bottom: 5.8rem;
				width: 53.44946rem;
				height: 17.30278rem;
				background-image: url(../images/waves.svg);
				background-repeat: no-repeat;
				background-size: 100% 100%;
				background-position: 100% 0;

				@media (max-width: $b3){
					display: none;
				}
			}
		}

		p{
			margin-bottom: 1.7em;
		}

		.btn-con{
			margin-top: -.6rem;

			.btn{
				width: 100%;
				max-width: 25.9rem;

				@media (min-width: $b3 + 1){
					padding-right: 3rem;
					padding-left: 3rem;
				}
			}
		}
	}
}

.steps-alternative-2-section{
	padding-top: 17.8rem;
	padding-bottom: 17.1rem;
	position: relative;

	@media (max-width: $b3){
		padding-top: 7.5rem;
		padding-bottom: 7.5rem;
	}

	&:after{
		content: '';
		position: absolute;
		top: 19.9rem;
		left: 0;
		background-image: url(../images/ship-side.svg);
		background-repeat: no-repeat;
		width: 71.95rem;
		height: 81.057%;
		background-size: 100% auto;

		@media (max-width: $b3){
			display: none;
		}
	}

	.container{
		max-width: 165.6rem;
	}

	.filler-side{

		@media (min-width: $b3 + 1){
			flex: 0 0 38.7%;
			max-width: 38.7%;
		}		
	}

	.goal-side{

		@media (min-width: $b3 + 1){
			flex: 0 0 61.3%;
			max-width: 61.3%;
		}

		.goal-outer{
			position: relative;
			padding-left: 7.6rem;
			margin-bottom: 12rem;

			@media (max-width: $b3){
				padding-left: 4.3rem;
			}
			
			&:last-of-type{
				margin-bottom: 0;
			}

			&:after{
				content: '';
				position: absolute;
				background-repeat: no-repeat;
				background-size: 100% 100%;
				background-position: 50% 0;
				z-index: -1;
			}

			.number{
				position: absolute;
				top: -16.2rem;
				left: -.6rem;
				@include font-size(236);
				opacity: .13;
				color: $green;
				z-index: -1;
				font-weight: 700;
				font-family: $alt-font2;
				letter-spacing: -.02em;
			}

			.number-inner{
				letter-spacing: .197em;
				margin-bottom: .6em;
				color: rgba(black, .44);
			}
			
			.text-con{
				flex: 1 1 auto;
				position: relative;

				&:after{
					content: '';
					position: absolute;
					left: 0;
					bottom: 0;
					height: .2rem;
					width: 100%;
					width: calc(100% + 1.7rem + 2.75rem + ((100vw - 165.6rem)/2));
					max-width: calc(100% + 1.7rem + 2.75rem + ((100vw - 165.6rem)/2));
					background-color: $green;
				}

				h4{
					letter-spacing: .02em;
					font-weight: 600;
					color: rgba($green, .9);
				}

				p{
					@include font-size(25);
					@include line-height(25,41);
					padding: 1.25rem 0 3.6rem;
					margin-bottom: 0;
					color: rgba($green, .9);
					letter-spacing: 0;
					font-weight: 600;

					@media (max-width: $b3){
						@include font-size(18);
					}
				}
			}
		}
	}
}

.our-ship-section + .where-we-are-section{
	margin-top: -4.2rem;
}

.where-we-are-section{
	padding-top: 7.5rem;
	padding-bottom: 7.5rem;

	@media (max-width: $b3){
		padding-top: 4.1rem;
		padding-bottom: 5.6rem;
	}

	&:has(.map-bottom:first-of-type){
		padding-top: 0rem;
		padding-bottom: 9.9rem;

		.map-bottom{
			padding-top: 0;
			padding-bottom: 0;

			&:before,
			&:after{
				display: none;
			}
		}
	}

	.container{
		max-width: 169rem;
	}

	.text-top{
		text-align: center;
		position: relative;
		padding-top: 2.8rem;
		padding-bottom: 3.6rem;

		*:last-child{
			margin-bottom: 0;
		}

		&:before,
		&:after{
			content: '';
			position: absolute;
			left: 50%;
			height: .2rem;
			background-color: $green;
			width: 61.8rem;
			transform: translateX(-50%);

			@media (max-width: $b3){
				width: calc(100% - 8.4rem);
			}
		}

		&:before{
			top: 0;
		}

		&:after{
			bottom: 0;
		}

		*{
			color: $green;
		}

		h2{
			letter-spacing: .07em;
			margin-bottom: .2em;

			@media (max-width: $b3){
				@include font-size(42);
				@include line-height(42,49);
				margin-bottom: 0.6em;
			}
		}

		p{
			width: 71.7rem;
			margin-right: auto;
			margin-left: auto;
		}
	}

	.map-bottom{
		position: relative;
		padding-top: 5.9rem;
		padding-bottom: 6.2rem;

		@media (max-width: $b3){
			padding-left: 0;
			padding-right: 0;
		}

		iframe{
			width: 100%;
			aspect-ratio: 1.8268/1;
			height: auto!important;
		}

		&:before{
			content: '';
			position: absolute;
			left: 50%;
			height: 100%;
			background-color: $green;
			width: .2rem;
			transform: translateX(-50%);
			top: 0;
			z-index: -1;
		}

		&:after{
			content: '';
			position: absolute;
			left: 50%;
			height: .2rem;
			background-color: $green;
			width: 61.8rem;
			transform: translateX(-50%);
			bottom: 0;

			@media (max-width: $b3){
				width: calc(100% - 8.4rem);
			}
		}
	}
}

.simple-hero-section + .leaders-section{

	h6{
		padding-left: 4.7rem;
		margin-top: 1.1em;

		&::before{
			left: .8rem;
			background-color: $blue;
		}
	}

	p{
		margin-bottom: 1.7em;
	}

	.row{
		align-items: flex-start;
	}

	.text-side{
		padding-top: 1.4rem;
		padding-bottom: 9.1rem;
	}
}

.leaders-section{
	padding-top: 9.4rem;
	padding-bottom: 10.4rem;
	background-image: url(../images/back-blue.jpg);
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position: 50% 100%;

	@media (max-width: $b3){
		padding-top: 4.7rem;
		padding-bottom: 13.9rem;
	}

	.container{
		max-width: 157.3rem;
	}

	.row{
		align-items: center;
	}
	
	.image-side{
		position: relative;

		@media (min-width: $b3 + 1){
			flex: 0 0 50.9%;
			max-width: 50.9%;
			height: 54.5rem;
		}

		@media (max-width: $b3){
			order: 2;
			display: flex;
			flex-wrap: wrap;
		}

		&::before{
			content: '';
			position: absolute;
			top: 57.3%;
			left: 45%;
			width: 50.4672rem;
			height: 37.4787rem;
			background-size: 100% auto;
			background-image: url(../images/leader-line.svg);
			background-repeat: no-repeat;
			transform: translate(-50%, -50%);
			z-index: 1;

			@media (max-width: $b3){
				top: 39.3%;
				left: 49%;
				width: 131%;
				max-width: 131%;
				height: 43.3%;
			}
		}

		.img-con{
			width: 27.3rem;
			position: absolute;

			@media (max-width: $b3){
				position: relative;
			}

			&:before{
				content: '';
				position: absolute;
				top: 1.1rem;
				left: -1.8rem;
				width: calc(100% + 1rem);
				max-width: calc(100% + 1rem);
				height: calc(100% + .8rem);
				background-color: $blue;
				z-index: -1;
			}

			&:after{
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				top: -1rem;
				left: 1rem;
				border: .3rem solid $blue;
			}
			
			&.img-1{
				left: 2.75rem;
				top: 10%;
				z-index: 3;

				@media (max-width: $b3){
					order: 3;
					left: 8.05rem;
					top: -4.6rem;
				}
			}

			&.img-2{
				left: 24.8rem;
				bottom: 0%;
				z-index: 2;

				@media (max-width: $b3){
					order: 2;
					left: 1.5rem;
					bottom: 2rem;
				}

				&:before{
					left: 1rem;
					max-width: calc(100% + 0.7rem);
				}

				&:after{
					top: -.7rem;
					left: -1rem;
				}
			}

			&.img-3{
				left: 42rem;
				top: 0%;
				z-index: 1;

				@media (max-width: $b3){
					order: 1;
					left: 9rem;
				}

				&:before{
					left: -1.7rem;
					max-width: calc(100% + 0.7rem);
					height: calc(100% + .6rem);
				}
			}
		}
	}

	.text-side{
		padding-top: 2.4rem;

		@media (min-width: $b3 + 1){
			flex: 0 0 49.1%;
			max-width: 49.1%;
		}

		@media (max-width: $b3){
			padding-right: 6.0rem;
			padding-left: 6.0rem;
			margin-bottom: 6rem;
		}

		*{
			color: $blue;
		}

		h4{
			margin-bottom: .35em;

			@media (max-width: $b3){
				margin-bottom: 0.55em;
			}
		}

		.btn-con{
			margin-top: -1.3rem;
		}
	}
}

.current-leader-section{
	overflow: visible!important;
	padding-bottom: 5.8rem;

	@media (max-width: $b3){
		padding-bottom: 0;
	}

	.container {
		max-width: 111.8rem;

		@media (max-width: $b3){
			padding-right: 8.4rem;
			padding-left: 7.2rem;
		}
	}

	.row{
		margin-top: -7.9rem;

		@media (max-width: $b3){
			margin-top: -11.1rem;
		}
	}

	.text-side{
		text-align: right;

		@media (min-width: $b3 + 1){
			flex: 0 0 75%;
			max-width: 75%;
			padding-right: 4.3rem;
		}

		@media (max-width: $b3){
			order: 2;
			text-align: left;
			padding-top: 1.5rem;
		}

		h3{
			color: $coral;
			width: 40rem;
			float: right;
			font-weight: 900;
			letter-spacing: .02em;

			@media (max-width: $b3){
				margin-bottom: 3.3rem;
			}

			~ *{
				clear: both;

				@media (max-width: $b3){
					width: 110%;
					max-width: 110%;
				}
			}
		}
	}

	.leader-side{

		@media (min-width: $b3 + 1){
			flex: 0 0 25%;
			max-width: 25%;
		}

		.btn{
			width: 100%;
		}
	}

	.img-con{
		aspect-ratio: .6292/1;

		@media (max-width: $b3){
			aspect-ratio: .765/1;
		}
	}
}

.videos-section{
	padding-top: 7.4rem;
	text-align: center;
	position: relative;

	&.two-columns{

		&::before{
			content: '';
			position: absolute;
			left: -16rem;
			top: 3.5rem;
			width: 53.44946rem;
			height: 17.30278rem;
			background-image: url(../images/waves.svg);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: 100% 0;

			@media (max-width: $b3){
				display: none;
			}
		}

		+ .videos-section.two-columns{
			padding-top: 0;	
	
			&::before{
				left: auto;
				top: 3rem;
				right: -10.5rem;
			}
		}
	}

	&.three-columns{
		padding-top: 3.5rem;
		padding-bottom: 7.6rem;

		.container{
			max-width: 149.1rem;
		}

		@media (min-width: $b3 + 1){
		
			.container,
			.col-12{
				padding-right: 6.1rem;
				padding-left: 6.1rem;
			}
	
			.row{
				margin-right: -6.1rem;
				margin-left: -6.1rem;
			}
		}

		.title-con{
			margin-bottom: 3.1rem;
		}

		.video-side{
			position: relative;

			a{
				box-shadow: .8rem .8rem 0 $coral;

				&::before{
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 9.1rem;
					height: 9.1rem;
					border-radius: 23.5rem;
					border: 1rem solid white;
				}
	
				&:after{
					content: '';
					position: absolute;
					top: 50%;
					left: 51.1%;
					transform: translate(-50%, -50%);
					width: 0;
					height: 0;
					border-width: 1.802rem 0 1.802rem 3.073rem;
					border-color: transparent transparent transparent white;
					border-style: solid;
				}
			}

			.number{
				position: absolute;
				top: -7.6rem;
				left: 8.4rem;
				@include font-size(150);
				opacity: .13;
				color: $blue;
				z-index: -1;
				font-weight: 700;
				font-family: $alt-font2;
				letter-spacing: -.02em;

				@media (max-width: $b3){
					left: 2.4rem;
				}
			}

			p{
				@include font-size(22);
				@include line-height(22,29);
				margin-top: 4.5rem;
				margin-bottom: 1.5rem;
			}
		}

		
	}

	.container{
		max-width: 144.6rem;
	}

	@media (min-width: $b3 + 1){
		
		.container,
		.col-12{
			padding-right: 4.8rem;
			padding-left: 4.8rem;
		}

		.row{
			margin-right: -4.8rem;
			margin-left: -4.8rem;
		}
	}

	.title-con{
		text-align: center;
		margin-bottom: 5.3rem;
		
		h4{
			font-weight: 900;
		}
	}

	.video-side{
		margin-bottom: 6.9rem;

		a{
			position: relative;
			display: block;
			box-shadow: 1.2rem 1.2rem 0 $coral;
			margin-bottom: 4.1rem;

			&:hover,
			&:focus-visible{

				&::before{
					background-color: white;	
				}

				&:after{
					border-color: transparent transparent transparent $blue;
				}
			}

			&::before{
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 15.2rem;
				height: 15.2rem;
				border-radius: 23.5rem;
				border: 1rem solid white;

				@media (max-width: $b3){
					width: 9.1rem;
					height: 9.1rem;
				}
			}

			&:after{
				content: '';
				position: absolute;
				top: 50%;
				left: 51.1%;
				transform: translate(-50%, -50%);
				width: 0;
				height: 0;
				border-width: 3.102rem 0 3.102rem 5.273rem;
				border-color: transparent transparent transparent white;
				border-style: solid;

				@media (max-width: $b3){
					border-width: 1.802rem 0 1.802rem 3.073rem;
				}
			}
		}

		p{
			@include font-size(25);
			@include line-height(25,42);
			font-weight: 500;
			color: $blue;
			letter-spacing: .024em;
			margin-bottom: 0;
		}

		@media (min-width: $b3 + 1){
			
		}
	}
}

.objective-files-section{
	padding-top: 6.3rem;
	text-align: center;
	position: relative;
	padding-bottom: 7.8rem;

	@media (max-width: $b3){
		
	}

	&:has(+ .objective-files-section){
		padding-bottom: 0;
	}

	.container{
		max-width: 149.3rem;

		.row{
			justify-content: center;
		}
	}

	@media (min-width: $b3 + 1){
		
		.container,
		.col-12{
			padding-right: 6.1rem;
			padding-left: 6.1rem;
		}

		.row{
			margin-right: -6.1rem;
			margin-left: -6.1rem;
		}
	}

	.title-con{
		text-align: center;
		margin-bottom: 10.3rem;
		
		h4{
			font-weight: 900;
		}
	}

	.col-md-4{
		margin-bottom: 5.3rem;
		position: relative;
		z-index: 2;

		p{
			@include font-size(22);
			@include line-height(22,29);
			color: $blue;
			margin-bottom: 0;

			@media (max-width: $b3){
				@include font-size(19);
				@include line-height(19,29);
			}
		}

		&.video-item{

			a{
				position: relative;
				display: block;
				box-shadow: .7rem .7rem 0 $coral;
				margin-bottom: 3.5rem;

				@media (max-width: $b3){
					width: 90%;
					margin-left: 10%;
				}

				+ svg{
					width: 9.2958rem;
					position: absolute;
					top: 6.5rem;
					left: 1.6rem;
					z-index: -1;

					@media (max-width: $b3){
						top: calc(50% - 8.6rem);
					}
				}
	
				&:hover,
				&:focus-visible{
	
					&::before{
						background-color: white;	
					}
	
					&:after{
						border-color: transparent transparent transparent $blue;
					}
				}
	
				&::before{
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 9.2rem;
					height: 9.2rem;
					border-radius: 23.5rem;
					border: 1rem solid white;
				}
	
				&:after{
					content: '';
					position: absolute;
					top: 50%;
					left: 51.1%;
					transform: translate(-50%, -50%);
					width: 0;
					height: 0;
					border-width: 1.902rem 0 1.902rem 3.173rem;
					border-color: transparent transparent transparent white;
					border-style: solid;
				}
			}
		}

		&.photos-item{

			a{
				position: relative;
				display: block;
				box-shadow: .7rem .7rem 0 $coral;
				margin-bottom: 3.5rem;

				@media (max-width: $b3){
					width: 90%;
					margin-left: 10%;
				}

				+ a{
					display: none;
				}

				+ svg{
					width: 11.1523rem;
					position: absolute;
					top: 6.5rem;
					left: 1.6rem;
					z-index: -1;

					@media (max-width: $b3){
						top: calc(50% - 8.6rem);
					}
				}
			}
		}


		&.article-item{
			
			a{
				position: relative;
				display: block;
				box-shadow: .7rem .7rem 0 $coral;
				margin-bottom: 3.5rem;

				@media (max-width: $b3){
					width: 90%;
					margin-left: 10%;
				}

				svg{
					position: absolute;
					bottom: 1.9rem;
					right: 2.3rem;
					width: 3.9rem;
				}

				+ svg{
					width: 9.6398rem;
					position: absolute;
					top: 5.7rem;
					left: 1.4rem;
					z-index: -1;

					@media (max-width: $b3){
						top: calc(50% - 9.7rem);
					}
				}
			}
		}
	}
}

.drawing-competition-section{
	padding-top: 12.9rem;
	padding-bottom: 10.4rem;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position: 50% 100%;
	background-image: url(../images/back-blue-dark.jpg);

	.container{
		max-width: 149.1rem;
	}

	*{
		color: white;
	}

	@media (min-width: $b3 + 1){
	
		.container,
		.col-12{
			padding-right: 6.1rem;
			padding-left: 6.1rem;
		}

		.row{
			margin-right: -6.1rem;
			margin-left: -6.1rem;
		}
	}

	.title-con{
		text-align: center;
		margin-bottom: 11.6rem;

		h4{
			letter-spacing: .100em;
		}
	}

	.i-item{
		margin-bottom: 5.8rem;

		.inner{
			position: relative;
		}

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;

			&:hover,
			&:focus-visible{
				
				~ .img-con{

					.img{
						transform: translate(-50%, -50%) scale(1.19);
					}
				}

				~ p{
					padding: 3.3rem 0 .4rem 5.7rem;
				}
			}
		}

		.img-con{
			overflow: hidden;
			width: 100%;
			aspect-ratio: 1.5432/1;
			position: relative;
		}

		.img{
			aspect-ratio: 1.5432/1;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(1.01);
			transition: $time;
			width: 100%;
		}



		p{
			@include font-size(22);
			@include line-height(22,29);
			color: rgba(white, .53);
			padding: 3.3rem 0 .4rem 6.9rem;
			margin-bottom: 0;
			position: relative;
			transition: $time;

			&:before{
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 3.5rem;
				width: .3rem;
				background-color: rgba(white, .53);
			}

			strong{
				font-weight: 700;
			}
		}
	}
}

.video-with-text-section{
	padding-top: 6rem;
	padding-bottom: 11rem;

	@media (max-width: $b3){
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.single-events &{
		padding-top: 4.7rem;
		position: relative;

		&::before{
			content: '';
			position: absolute;
			top: 8.6rem;
			bottom: 7.25rem;
			left: 0;
			right: 0;
			background-color: $coral;

			@media (max-width: $b3){
				bottom: 0rem;
			}
		}

		.container{
			position: relative;
			z-index: 1;
		}

		.row{
			align-items: center;
		}

		.video-side{

			@media (min-width: $b3 + 1){
				flex: 0 0 56.35%;
				max-width: 56.35%;
			}

			a{
				box-shadow: none;
			}

			img{
				object-fit: cover;
				object-position: 50% 50%;
				aspect-ratio: 1.605/1;
			}
		}

		.text-side{
			padding-bottom: .5rem;

			@media (min-width: $b3 + 1){
				flex: 0 0 39.8%;
				max-width: 39.8%;
			}

			*{
				color: white;
			}

			h4{
				margin-bottom: .48em;

				@media (min-width: $b3 + 1){
					padding-right: 0rem;
				}
			}
		}
	}

	.single-leaders &{
		padding-top: 2.1rem;

		.video-side{

			@media (min-width: $b3 + 1){
				flex: 0 0 56.45%;
				max-width: 56.45%;
			}
		}

		.text-side{

			@media (min-width: $b3 + 1){
				flex: 0 0 39.75%;
				max-width: 39.75%;
			}

			*{
				color: $blue;
			}

			h4{
				margin-bottom: .46em;

				@media (min-width: $b3 + 1){
					padding-right: 0rem;
				}
			}
		}

	}

	.container{
		max-width: 176.1rem;
	}

	.row{
		align-items: center;
	}

	.video-side{

		@media (max-width: $b3){
			padding-left: 0;
			padding-right: 3.4rem;

			img{
				aspect-ratio: 1.4/1;
			}
		}

		a{
			position: relative;
			display: block;
			box-shadow: 4.3rem 3.9rem 0 $coral;

			@media (max-width: $b3){
				box-shadow: 0rem 1.9rem 0 $coral, 1.9rem 1.9rem 0 $coral;
			}

			&:hover,
			&:focus-visible{

				&::before{
					background-color: white;	
				}

				&:after{
					border-color: transparent transparent transparent $blue;
				}
			}

			&::before{
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 23.5rem;
				height: 23.5rem;
				border-radius: 23.5rem;
				border: 1.4rem solid white;

				@media (max-width: $b3){
					width: 10.5rem;
					height: 10.5rem;
				}
			}

			&:after{
				content: '';
				position: absolute;
				top: 50%;
				left: 51.1%;
				transform: translate(-50%, -50%);
				width: 0;
				height: 0;
				border-width: 4.702rem 0 4.702rem 8.173rem;
				border-color: transparent transparent transparent white;
				border-style: solid;

				@media (max-width: $b3){
					border-width: 2.302rem 0 2.302rem 3.773rem;
				}
			}
		}

		@media (min-width: $b3 + 1){
			flex: 0 0 59.95%;
			max-width: 59.95%;

			a{
				float: right;
				width: calc(100% + 1.7rem + ((100vw - 176.1rem)/2));
				max-width: calc(100% + 1.7rem + ((100vw - 176.1rem)/2));
			}
		}
	}

	.text-side{
		padding-top: 9rem;

		@media (max-width: $b3){
			padding-top: 6.4rem;
			padding-left: 4.7rem;
			padding-right: 4.3rem;
		}

		@media (min-width: $b3 + 1){
			flex: 0 0 40.05%;
			max-width: 40.05%;
			padding-left: 9.8rem;
		}

		*{
			color: $coral;
		}

		h4{
			font-weight: 900;
			margin-bottom: 0.36em;

			@media (min-width: $b3 + 1){
				padding-right: 6rem;
			}
		}

		.btn-con{
			margin-top: 4.3rem;

			@media (max-width: $b3){
				margin-top: 2.3rem;
			}

		}
	}
}

.past-future-section.past-future-section + .image-with-text-over-section.image-with-text-over-section{
	padding-top: 8.4rem;
	padding-bottom: 13.7rem;

	@media (max-width: $b3){
		padding-top: 5.4rem;
		padding-bottom: 5.7rem;
	}
}

.leg-section + .image-with-text-over-section.image-with-text-over-section,
.who-is-going-section + .image-with-text-over-section.image-with-text-over-section,
.tour-section + .image-with-text-over-section.image-with-text-over-section{
	padding-top: 0;
}

.leg-section + .image-with-text-over-section.image-with-text-over-section{

	@media (min-width: $b3 + 1){
		
		.text-con{
			width: 59.2rem;
			padding: 4rem 4.6rem 2.7rem 4.3rem;
			margin-top: -11.6rem;
			margin-left: 45.9%;
		}
	}
}

.who-is-going-section + .image-with-text-over-section.image-with-text-over-section{

	.over-slide{
		
		.swiper-slide.swiper-slide{
			max-height: 64rem;
			
			.inner{
				max-height: 64rem;
			}

			img{
				max-height: 64rem;
			}
		} 
	}
}

.image-with-text-over-section.image-with-text-over-section{
	padding-right: 0;
	padding-top: 10.6rem;

	@media (max-width: $b3){
		padding-top: 2rem;
	}

	&:has(+ .join-section){
		padding-bottom: 10.6rem;

		@media (max-width: $b3){
			padding-bottom: 6.6rem;
		}
	}

	.container{
		max-width: 100%;
		padding: 0;
	}

	.over-slide-con{
		// display: flex;

		// img{
		// 	height: 57.5rem;
		// 	object-fit: cover;
		// 	object-position: 50% 50%;
		// }
	}

	.text-con{
		width: 67.5rem;
		padding: 5rem 4.6rem 1rem 5.4rem;
		margin-top: -17rem;
		margin-left: 45.3%;
		position: relative;
		z-index: 1;

		@media (max-width: $b3){
			margin-top: -3.3rem;
			margin-left: 6.3%;
			padding: 5rem 9rem 2rem 5.4rem;
		}

		&::before{
			content: '';
			position: absolute;
			top: .1rem;
			left: 0;
			width: 100%;
			height: 17rem;
			background-color: white;
			z-index: -1;
		}

		h4{
			margin-bottom: 0.4em;
			font-weight: 900;
		}

		.btn-con{
			margin-top: 2.7rem;

			@media (max-width: $b3){
				margin-top: 3.3rem;
			}

			a{

				~ a{
					display: none;
				}
			}
		}
	}

	.text-bottom{
		z-index: 1;
	}

	.over-slide{

		.swiper-slide.swiper-slide{
			max-width: 111.4rem;
			max-height: 57.5rem;

			.inner{
				max-height: 57.5rem;
				overflow: hidden;
			}

			img{
				max-width: 100%!important;
				max-height: 57.5rem;
				transform: scale(1.002);
			}
		}
	}
}

.charles-darwin-section{
	padding-top: 11.4rem;
	padding-bottom: 12.7rem;

	@media (min-width: $b3 + 1){
		overflow: visible!important;
	}

	@media (max-width: $b3){
		padding-top: 14.4rem;
		padding-bottom: 5.7rem;
	}
	

	.container{
		max-width: 147.1rem;
	}

	.image-side{
		
		@media (min-width: $b3 + 1){
			flex: 0 0 55%;
			max-width: 55%;
			margin-top: -30rem;

			&:before{
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				background-color: #FBFBFB;
				height: 100%;
				left: -21.4rem;
				width: 100%;
				max-width: 100vw;
				z-index: -1;
			}

			img{
				width: calc(100% + 1.7rem + ((100vw - 147.1rem)/2));
				max-width: calc(100% + 1.7rem + ((100vw - 147.1rem)/2));
				float: right;
				position: relative;
				z-index: -1;
			}
		}

		@media (max-width: $b3){
			padding-left: 0;
			padding-right: 0;
		}
	}

	.text-side{

		@media (min-width: $b3 + 1){
			flex: 0 0 45%;
			max-width: 45%;
			padding-left: 4.7rem;
		}

		@media (max-width: $b3){
			padding-right: 5.5rem;
			padding-left: 5.5rem;
			margin-top: -143vw;
		}

		> p{
			
			&:first-of-type{
				letter-spacing: .197em;
				opacity: .39;
			}
		}

		p{
			opacity: .48;

			&:not(:first-of-type){
				position: relative;

				&:before{
					content: '';
					position: absolute;
					top: .9rem;
					left: -2.3rem;
					width: .2rem;
					height: calc(100% - .9rem - .9rem);
					background-color: rgba($black, .48);
				}
			}
		}

		h1{
			@include font-size(87);
			@include line-height(87,67);
			letter-spacing: .02em;
			color: $coral;
			mix-blend-mode: multiply;
			font-weight: 900;
			width: 131%;
			max-width: 131%;
			margin-left: -31%;
			margin-top: .26em;
			margin-bottom: .35em;

			@media (max-width: $b3){
				margin-left: 0;
				@include font-size(71);
				@include line-height(71,68);
				margin-bottom: 112vw;
			}

			em{
				font-weight: 700;
			}
		}

		.img-con{
			margin-top: 11.3rem;
			width: 153.3%;
			max-width: 153.3%;
			margin-left: -28%;
			position: relative;
			z-index: 1;

			@media (max-width: $b3){
				margin-left: -4.5%;
				margin-top: 4rem;
				width: 114.7%;
				max-width: 153.3%;
			}

			&:before{
				content: '';
				position: absolute;
				top: -3rem;
				left: -2.7rem;
				width: 101.4%;
				max-width: 110%;
				height: 102%;
				background-color: $coral;
				z-index: -1;

				@media (max-width: $b3){
					top: -1.3rem;
					left: -1.2rem;
					width: 109.4%;
					max-width: 110%;
				}
			}
		}
	}
}

.facts-section{
	padding-top: 9.2rem;
	padding-bottom: 12.5rem;
	background-image: url(../images/back-green.jpg);
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position: 50% 100%;

	@media (min-width: $b3 + 1){
		overflow: visible!important;
	}

	@media (max-width: $b3){
		padding-top: 4.2rem;
		padding-bottom: 8.5rem;
	}

	.container{
		max-width: 171.7rem;
	}

	h1{
		text-align: right;

		@media (max-width: $b3){
			@include font-size(82);
			@include line-height(82,88);
			font-style: italic;
			border-bottom: .2rem solid white;
			letter-spacing: .02em;
			padding-bottom: .08em;
		}
	}

	*{
		color: white;
	}

	.title-side{

		@media (min-width: $b3 + 1){
			flex: 0 0 41.5%;
			max-width: 41.5%;
		}

		@media (max-width: $b3){
			padding-left: 0;
			padding-right: 3.6rem;
			margin-bottom: 6.4rem;
		}

		h1{
			text-align: right;
			position: relative;
			position: sticky;
			top: 9.2rem;
			
			@media (min-width: $b3 + 1){
				
				br{
					display: none;
				}
			}

			@media (max-width: $b3){
				position: relative;
				top: 0;
			}

			&:after{
				content: '';
				position: absolute;
				bottom: 0;
				right: 0;
				width: 100vw;
				max-width: 100vw;
				height: .2rem;
				background-color: white; 
			}
		}
	
	}

	.fact-side{

		@media (min-width: $b3 + 1){
			flex: 0 0 58.5%;
			max-width: 58.5%;
		}

		@media (max-width: $b3){
			padding-left: 5.3rem;
			padding-right: 0;
		}
	}

	.number-con{
		padding-bottom: 3.2rem;
		border-bottom: .2rem solid white;

		@media (max-width: $b3){
			padding-bottom: 2.7rem;
		}

		+ .number-con{
			margin-top: 5.15rem;

			@media (max-width: $b3){
				margin-top: 5.9rem;
			}
		}
	}

	.number{
		@include font-size(75);
		@include line-height(75,75);
		font-weight: 900;
		font-style: italic;
		text-transform: uppercase;

		@media (max-width: $b3){
			@include font-size(53);
			@include line-height(53,41);
		}
		
		div{
			display: inline-block;
		}
	}

	p{
		@include font-size(25);
		@include line-height(25,41);
		font-weight: 600;
		letter-spacing: 0;
		margin-bottom: 0;
	}
}

.purple-text-with-image-section{
	padding-top: 14.6rem;
	padding-bottom: 16.2rem;

	@media (max-width: $b3){
		padding-top: 0;
		padding-bottom: 0;
	}

	.container{
		max-width: 167.8rem;
	}

	.row{
		align-items: center;
	}

	*{
		color: white;
	}

	.text-side{
		z-index: 2;

		@media (min-width: $b3 + 1){
			flex: 0 0 53.25%;
			max-width: 53.25%;
		}

		@media (max-width: $b3){
			padding: 0;
		}

		.inner{
			margin-bottom: .5rem;
			min-height: 61.6rem;
			padding: 5.9rem 7.9rem 2rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: relative;
			z-index: 1;
			background-color: $purple;
			background-image: url(../images/back-purple-text.svg);
			background-repeat: no-repeat;
			//background-attachment: fixed;
			//background-size: 43.6% auto;
			background-position: 12.8% 50%;
			background-size: cover;	

			@media (max-width: $b3){
				padding: 6.1rem 3.1rem 3.4rem;
				min-height: 0;
				margin-bottom: 0;

				img{
					margin-bottom: 4.5rem;
				}
			}
		}
	}

	.image-side{

		@media (min-width: $b3 + 1){
			flex: 0 0 46.75%;
			max-width: 46.75%;
		}

		@media (max-width: $b3){
			padding: 0;
		}

		img{
			width: 129.7%;
			max-width: 129.7%;
			margin-left: -19%;

			@media (max-width: $b3){
				width: 100%;
				margin: 0;
				aspect-ratio: .877/1;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}
	}
}

.blue-light-text-with-image-section{
	padding-top: 5rem;
	padding-bottom: 3.8rem;
	background-image: url(../images/back-blue.jpg);
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position: 50% 100%;
	margin-bottom: 24.3rem;

	@media (min-width: $b3 + 1){
		overflow: visible!important;
	}

	@media (max-width: $b3){
		margin-bottom: 0rem;
	}

	.container{
		max-width: 138.3rem;
	}

	.row{
		align-items: center;
	}

	.text-side{
		text-align: right;
		display: flex;
		justify-content: flex-end;

		@media (min-width: $b3 + 1){
			flex: 0 0 56.35%;
			max-width: 56.35%;
		}

		*{

			&:last-child{
				margin-bottom: 0;
			}
		}

		.inner{
			width: 51rem;
			padding-top: 2rem;
			position: relative;

			&:before{
				content: '';
				position: absolute;
				top: 39.4%;
				left: -33.9rem;
				width: 51.25838rem;
				height: 39.15132rem;
				background-image: url(../images/blue-line-shape.svg);
				background-repeat: no-repeat;
				transform: translateY(-50%) rotate(-.7deg);
				background-size: 100% 100%;

				@media (max-width: $b3){
					display: none;
				}

			}


		}
	}

	.image-side{

		@media (min-width: $b3 + 1){
			flex: 0 0 43.65%;
			max-width: 43.65%;
			padding-left: 4.8rem;
		}

		.img-con{
			position: relative;
			margin-top: -9rem;
			margin-bottom: -9rem;
			z-index: 1;

			@media (max-width: $b3){
				margin-top: 3rem;
				margin-bottom: 3rem;
			}

			&:before{
				content: '';
				position: absolute;
				top: 2.3rem;
				left: 2.3rem;
				width: calc(100% + 1rem);
				max-width: calc(100% + 1rem);
				height: calc(100% + 1.2rem);
				background-color: $blue;
				z-index: -1;
			}

			&:after{
				content: '';
				position: absolute;
				width: 99.7%;
				height: 99.6%;
				top: -1.2rem;
				left: -1.4rem;
				border: .3rem solid $blue;
			}
		}
	}
}

.quotes-section{
	padding-top: 9.4rem;
	padding-bottom: 13.4rem;
	background-color: $green;
	padding-left: 5.4rem;
	background-image: url(../images/back-leaf.jpg);
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position: 50% 50%;

	@media (max-width: $b3){
		padding-top: 4rem;
		padding-bottom: 3.9rem;
		padding-left: 0;
	}

	.container {
		max-width: 138.3rem;

		@media (max-width: $b3){
			padding-right: 2.0rem;
			padding-left: 2.0rem;
		}
	}

	.swiper-button-next,
	.swiper-button-prev{
		width: 2.4rem;
		height: 2.4rem;
		top: 12.5rem;

		@media (max-width: $b3){
			top: calc(100% - 10rem);
		}

		&:hover,
		&:focus-visible{
			
			svg{

				*{
					fill: $green;
				}
			}
		}

		svg{

			*{
				fill: #828282;
			}
		}
	}

	.swiper-button-next{
		right: 20.5%;

		@media (max-width: $b3){
			right: auto;
			left: 12.7rem;
		}
	}

	.swiper-button-prev{
		right: 24.2%;
		left: auto;

		@media (max-width: $b3){
			right: auto;
			left: 7.8rem;
		}
	}

	.swiper-slide{
		display: flex;
		flex-wrap: wrap;
		min-height: 32rem;
	}

	.quote-slide{
		background-color: rgba($white, .39);
		backdrop-filter: blur(.9rem);

		@media (max-width: $b3){
			background-color: transparent;
			backdrop-filter: blur(0);
			
			&::before{
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 1.4rem;
				right: 1.4rem;
				background-color: rgba($white, .39);
				backdrop-filter: blur(.9rem);
			}
		}
	}

	.quote-side{
		width: 68.5%;
		padding: 1rem 5.45rem 1rem 7.85rem;
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-width: $b3){
			width: 100%;
			padding: 5.6rem 6.45rem 4.1rem 7.85rem;
		}

		blockquote{
			margin-bottom: 0;
			color: white;
		}
		
	}

	.by-side{
		width: 29.3%;
		background-color: white;
		padding: 16.3rem 2rem 2rem 7.2rem;

		@media (max-width: $b3){
			width: 100%;
			padding: 4.4rem 1rem 11.6rem 7.7rem;
			margin-bottom: 3.9rem;
		}

		.by{
			@include font-size(25);
			@include line-height(25,31);
			opacity: .49;
			font-weight: 600;
			font-style: italic;
			color: black;
			position: relative;
			letter-spacing: .08em;

			&::before{
				content: '';
				position: absolute;
				top: 0.66em;
				left: -1.57em;
				width: 0.96em;
				height: .2rem;
				opacity: .49;
				z-index: 1;
				background-color: black;
				display: block;
			}
		}
	}
}

.follow-our-journey-section,
.instagram-section{
	padding-top: 10.2rem;
	padding-bottom: 5.4rem;

	@media (max-width: $b3){
		padding-top: 4.6rem;
		padding-bottom: 4.4rem;

		.container{
			padding-left: 3.8rem;
			padding-right: 3.8rem;
		}
	}
	
	.title-con{
		margin-bottom: 5.4rem;

		@media (max-width: $b3){
			margin-bottom: 3.4rem;
		}

		h4{
			margin-bottom: 0;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			font-weight: 900;

			em{
				margin-left: .3em;

				@media (max-width: $b3){
					margin-left: 1em;
				}
			}

			svg{
				margin-right: .35em;

				@media (max-width: b3){
					min-width: 2rem;
				}
			}
		}
	}

	.slide-con{
		background-color: rgba($red, .4);
		height: 35.9rem;

		@media (max-width: $b3){
			height: 24.7rem;
		}
	}
}


.follow-our-journey-section{
	padding-top: 28.8rem;
	padding-bottom: 5.2rem;

	@media (max-width: $b3){
		padding-top: 25.8rem;
	}

	.container {
		max-width: 150.4rem;
	}

	.main-title-con{
		text-align: center;
		margin-bottom: 5.3em;

		h1{

			em{
				font-weight: 400;
			}
			
			@media (min-width: $b3 + 1){
				@include font-size(72);
			}
		}
	}

	.title-con{
		margin-bottom: 9.75rem;
	}

	.slide-con{
		margin-bottom: 4.2rem;

		&.tiktok{
			height: 68.3rem;
		}
	}

	.social-menu{
		justify-content: center;
		margin-top: 2.1rem;

		*{
			color: $blue;
		}
	}
}

.blog-section{
	padding-top: 6.5rem;
	padding-bottom: 5.4rem;

	@media (max-width: $b3){
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.container {
		max-width: 158.9rem;

		@media (max-width: $b3){
			padding-right: 3.4rem;
			padding-left: 3.4rem;
		}
	}

	.title-con{
		margin-bottom: 2.3rem;

		@media (max-width: $b3){
			margin-bottom: 1.3rem;
		}

		h1{
			margin-bottom: 0;
			font-weight: 900;
			color: $coral;
			@include font-size(72);
			letter-spacing: .02em;
		}
	}

	@media (max-width: $b3){
		
		.blog-slide{
			width: calc(100% + 1.1rem + 1.1rem);
			max-width: calc(100% + 1.1rem + 1.1rem);
			margin-left: -1.1rem;
		}
	
		.swiper-slide{
			padding: 0 1.1rem;
		}
	}

	.swiper-button-next,
	.swiper-button-prev{
		top: -8.5rem;
		transform: none;

		svg{

			*{
				fill: $coral;
			}
		}

		&:hover,
		&:focus-visible{
			
			svg{

				*{
					fill: $blue;
				}
			}
		}
	}

	.swiper-wrapper-con{
		overflow: visible!important;
	}

	.swiper-button-next{
		right: auto;
		left: 54.9rem;
	}

	.swiper-button-prev{
		left: 47.7rem;
	}

	.ns-item{

		&.swiper-slide-active{

			.inner{

				.img-con{
					margin-top: 0;
					height: 39.8rem + 4.9rem;

					@media (max-width: $b3){
						height: 27.3rem;
					}
				}
			}
		}

		.inner{
			display: block;
			width: 100%;
			

			.img-con{
				height: 39.8rem;
				aspect-ratio: 0;
				margin-bottom: 3.45rem;
				margin-top: 4.9rem;

				@media (max-width: $b3){
					margin-top: 0rem;
					height: 27.3rem;
					margin-bottom: 2.95rem;
				}
			}

			.text-con{
				padding: 0;

				h4{
					@include font-size(42);
					margin-bottom: 0.45em;

					@media (max-width: $b3){
						@include font-size(36);
						@include line-height(36,41);
						margin-bottom: 0.68em;
					}
				}

				p{
					color: rgba(black, .48);
					@include font-size(19);
					@include line-height(19,32);
					padding-left: 0;
					letter-spacing: .037em;
					margin-bottom: 1.2em;

					@media (max-width: $b3){
						@include font-size(18);
						@include line-height(18,28);
						margin-bottom: .8em;
					}

					&:before{
						display: none;
					}
				}

				.date{

					span{
						@include font-size(20);
						color: rgba(#707070, .3);
						margin-right: 3rem;
						text-transform: uppercase;

						+ span{
							margin-right: 0;
						}
					}
				}
			}
		}
	}
}

.competition-entry-section + .join-section{
	position: relative;
	z-index: 1;
}

.join-section{
	padding-top: 10.5rem;
	padding-bottom: 6.5rem;
	background-image: url(../images/back-green-light.jpg);
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position: 50% 100%;

	@media (max-width: $b3){
		padding-top: 6.1rem;
		padding-bottom: 4.9rem;
	}

	.container {
		max-width: 152.1rem;

		@media (max-width: $b3){
			padding-right: 6.4rem;
			padding-left: 6.4rem;
		}
	}

	.text-side{
		text-align: right;

		@media (max-width: $b3){
			text-align: left;
			margin-bottom: 3.1rem;
		}
		
		@media (min-width: $b3 + 1){
			flex: 0 0 45.1%;
			max-width: 45.1%;
		}

		*{
			color: $green;
		}

		h4{
			letter-spacing: .02em;
			font-weight: 900;
			margin-bottom: .85em;

			@media (max-width: $b3){
				margin-bottom: .3em;
			}
		}

		p{
			opacity: .48;
			padding-left: 9rem;
			letter-spacing: .024em;

			@media (max-width: $b3){
				padding-left: 0;
			}
		}
	}

	.form-side{
		padding-top: .2rem;

		@media (min-width: $b3 + 1){
			flex: 0 0 51.1%;
			max-width: 51.1%;
		}

		.wpcf7-form-control-wrap{
			width: calc(100% - 13.5rem - 1rem);
			max-width: calc(100% - 13.5rem - 1rem);

			@media (max-width: $b3){
				width: 100%;
				max-width: 100%;
			}
		}

		input,
		input.input-text,
		textarea,
		.fake-select,
		select{
			color: $green;

			@media (max-width: $b3){
				padding-left: 6.1rem;
			}
		}

		legend,
		label{
			width: 13.5rem;
			margin-right: 1rem;
			color: $green;
			opacity: .48;

			@media (max-width: $b3){
				position: absolute;
				left: 0;
				top: .4rem;
				width: 6rem;
			}
		}

		*[type="submit"]{
			margin-left: 14.5rem;
			background-color: transparent;
			border-color: $green;
			color: $green!important;

			@media (max-width: $b3){
				margin-left: 13.1rem;
				margin-left: calc(100% - 16.7rem);
				margin-top: -.1rem;

			}
	
			&:hover,
			&:focus-visible{
				background-color: $green;
				border-color: $green;
				color: $green-light!important;
			}
		}

		div.wpcf7-response-output.wpcf7-response-output.wpcf7-response-output,
		div.wpcf7-mail-sent-ok,
		div.wpcf7-validation-errors,
		div.wpcf7-acceptance-missing{
			width: calc(100% - 13.5rem - 1rem);
			max-width: calc(100% - 13.5rem - 1rem);
			margin-left: 13.5rem + 1rem;
		}
	}
}

.our-ship-section{
	padding-top: 14.2rem;
	padding-bottom: 14.3rem;
	background-image: url(../images/back-grey-long.jpg);
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-size: 100% auto;

	@media (max-width: $b3){
		padding-top: 9.2rem;
		padding-bottom: 5.3rem;
	}

	.container{
		max-width: 153.1rem;
	}

	.row1{
		margin-bottom: 13.1rem;

		@media (max-width: $b3){
			margin-bottom: 3rem;
		}

		&:has(h5){
			margin-bottom: -12.3rem;

			@media (max-width: $b3){
				margin-bottom: 2rem;
			}

			.text-side{
				padding-top: 2.8rem;

				.inner{

					&:after{
						width: 53.6835rem;
						height: 77.1737rem;
						background-image: url(../images/ol3.svg);
						right: calc(100% + 5.5rem);
						top: 6.2rem;

						@media (max-width: $b3){
							display: none;
						}
					}
				}
			}

			+ .row2{
				margin-bottom: -19.6rem;

				@media (max-width: $b3){
					margin-bottom: 2rem;
				}

				@media (min-width: $b3 + 1){
					
					.text-side{
						padding-top: 30.6rem;
					}
				}

				p{
					margin-bottom: 1.6em;
				}

				.text-side{
					
					*:last-child{
						margin-bottom: 0;
					}
				}
			}

			~ .row3{

				.image-side{

					&:before{
						right: 38.5rem;
						bottom: -13.7rem;
					}

					@media (min-width: $b3 + 1){
						flex: 0 0 47.2%;
						max-width: 47.2%;

						img{
							aspect-ratio: .6649/1;
						}
					}
				}

				.text-side{

					@media (min-width: $b3 + 1){
						flex: 0 0 52.8%;
						max-width: 52.8%;
						padding-top: 35.2rem;
						padding-left: 9.3rem;
					}

					h1{
						margin-bottom: .55em;
						margin-left: -14.1rem;

						@media (max-width: $b3){
							margin-left: 0;
						}

						&:before{
							top: calc(100% + 90.1rem);
							right: calc(100% + -6.5rem);
							width: 92.3rem;
							max-width: 92.3rem;
						}

						&:after{
							left: 6.5rem;
							height: 89.4rem;
						}
					}

					table{
						margin-bottom: 1.1em!important;

						+ p{
							margin-top: 2.3em;
						}
					}

					table tbody th, 
					table tbody td{
						padding: .7rem 1.5rem!important;
					}

					table tbody th:first-of-type, 
					table tbody td:first-of-type{
						border-left: none!important;
						padding: .7rem 0.5rem .7rem 0rem!important;
					}

					.btn-con{
						margin-top: 5rem;

						.btn{
							min-width: 29.5rem;
						}
					}
				}
			}
		}

		.image-side{

			&::before{
				left: -21.4rem;
				bottom: -11.2rem;
			}
			
			@media (min-width: $b3 + 1){
				flex: 0 0 49.3%;
				max-width: 49.3%;

				img{
					width: calc(100% + 1.7rem + ((100vw - 153.1rem)/2));
					max-width: calc(100% + 1.7rem + ((100vw - 153.1rem)/2));
					float: right;
				}
			}

			img{
				box-shadow: -2.5rem -2.9rem $blue;
			}
		}

		.text-side{
			padding-top: 7.5rem;

			@media (max-width: $b3){
				padding-top: 2rem;
			}

			@media (min-width: $b3 + 1){
				flex: 0 0 50.7%;
				max-width: 50.7%;
				padding-left: 12.5rem;
			}

			@media (min-width: $b3 + 1){
				
				&.waypoint .inner:after{
					max-height: 0;
				}
				
				&.animate .inner:after{
					max-height: 72.0rem;
					transition: $time*2 $time*2;
				}
			}

			.inner{
				position: relative;
				z-index: 1;
				

				&:after{
					content: '';
					position: absolute;
					background-repeat: no-repeat;
					background-size: 100% 72.0rem;
					background-position: 50% 0;
					z-index: -1;
					width: 55.8rem;
					height: 72.0rem;
					background-image: url(../images/ol1.svg);
					right: calc(100% + 3.4rem);
					top: 16.7rem;

					@media (max-width: $b3){
						display: none;
					}
				}
			}

			p{
				margin-bottom: 1.6em;
				
				&:first-of-type{
					opacity: .39;
					letter-spacing: .197em;
					margin-bottom: .6em;
				}
			}
			
			hr{
				margin: 1.3rem 0 1.3rem;
				width: 47%;
				background-color: $blue;
		
				@media (min-width: $b3 + 1){
					width: calc(47% + 12.5rem + 2.75rem);
					margin-left: -12.5rem - 2.75rem;

					&:has(+ h5){
						width: calc(83.8% + 12.5rem + 2.75rem);
						max-width: calc(83.8% + 12.5rem + 2.75rem);
						margin-bottom: 2.2rem;
					}
				}
			}
		}

		h1{

			em{
				font-weight: 400;
				letter-spacing: .008em;
			}
		}
	}

	.row2{
		margin-bottom: -28.9rem;
		align-items: flex-start;

		@media (max-width: $b3){
			margin-bottom: 3rem;
		}

		.image-side{

			@media (max-width: $b3){
				order: 1;
				margin-bottom: 1rem;
			}

			&::before{
				right: -23.1rem;
				top: 33.4rem;
			}

			@media (min-width: $b3 + 1){
				flex: 0 0 39.3%;
				max-width: 39.3%;
			}
		}

		.text-side{
			text-align: right;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;
			align-items: flex-start;
			
			@media (min-width: $b3 + 1){
				flex: 0 0 59.5%;
				max-width: 59.5%;
				padding-left: 12.5rem;
				padding-top: 18.6rem;
			}

			@media (max-width: $b3){
				order: 2;
			}

			@media (min-width: $b3 + 1){
				
				&.waypoint .inner:after{
					max-height: 0;
				}
				
				&.animate .inner:after{
					max-height: 57.5rem;
					transition: $time*2 $time*2;
				}
			}

			.inner{
				width: 63.7rem;
				background-color: white;
				padding: 4.4rem 8.4rem 3.7rem 10rem;
				position: relative;
				z-index: 1;

				@media (max-width: $b3){
					padding: 3.7rem 2rem 3.7rem 2rem;
				}

				&:after{
					content: '';
					position: absolute;
					background-repeat: no-repeat;
					background-size: 100% 57.5rem;
					background-position: 50% 0;
					z-index: -1;
					width: 40.4rem;
					height: 57.5rem;
					background-image: url(../images/ol2.svg);
					right: calc(100% + 1.1rem);
					top: calc(100% - .4rem);

					@media (max-width: $b3){
						display: none;
					}
				}
				
				&::before{
					content: '';
					position: absolute;
					top: .4rem;
					right: 100%;
					bottom: .4rem;
					width: 2.4rem;
					background-color: $blue;

					@media (max-width: $b3){
						width: 1.8rem;
					}
				}
				
				@media (min-width: $b3 + 1){
					margin-right: -5.5rem;
				}
			}

			.btn-con{
				padding-right: 8.4rem;
				margin: 0;
				margin-top: 3.3rem;

				@media (min-width: $b3 + 1){
					margin-right: -5.5rem;
				}

				.btn{
					margin: 0;
					min-width: 25.8rem;
				}
			}
		}
	}

	.row3{

		.image-side{

			@media (max-width: $b3){
				margin-bottom: 2rem;
			}

			&::before{
				right: -44.7rem;
				bottom: -3.7rem;
			}

			@media (min-width: $b3 + 1){
				flex: 0 0 40.6%;
				max-width: 40.6%;

				img{
					width: calc(100% + 1.7rem + ((100vw - 153.1rem)/2));
					max-width: calc(100% + 1.7rem + ((100vw - 153.1rem)/2));
					float: right;
				}
			}
		}

		.text-side{
			position: relative;
			z-index: 2;

			@media (min-width: $b3 + 1){
				flex: 0 0 40%;
				max-width: 40%;
				padding-top: 40.2rem;
			}

			p{
				margin-bottom: 1.6em;
			}

			h1{
				@include line-height(72,68);
				color: $coral;
				font-weight: 900;
				margin-bottom: .98em;

				@media (min-width: $b3 + 1){
					margin-left: -7rem;
					position: relative;

					&:before{
						content: '';
						position: absolute;
						top: calc(100% + 78.4rem);
						right: calc(100% + -3.5rem);
						width: 79.8rem;
						max-width: 79.8rem;
						background-color: $coral;
						height: .3rem;
					}

					&:after{
						content: '';
						position: absolute;
						top: calc(100% + 1rem);
						left: 3.5rem;
						width: 0.3rem;
						background-color: $coral;
						height: 77.7rem;
					}
				}
				

				+ p{
					opacity: .39;
					letter-spacing: .197em;
					margin-bottom: .6em;
					text-transform: uppercase;
				}
			}

			h2{
				line-height: 1.265;
				letter-spacing: .310em;
				margin-bottom: .2em;

				@media (max-width: 420px){
					letter-spacing: 0.2em;
				}
			}
		}
	}

	.image-side{
		position: relative;
		z-index: 1;

		&::before{
			content: '';
			position: absolute;
			width: 53.44946rem;
			height: 17.30278rem;
			background-image: url(../images/waves-light.svg);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: 100% 0;
			z-index: -1;

			@media (max-width: $b3){
				display: none;
			}
		}
	}	

	h1{
		@include font-size(72);
		@include line-height(72,72);
		letter-spacing: .02em;
		margin-bottom: .3em;

		@media (max-width: $b3){
			@include font-size(40);
		}
	}

	h5{
		@include font-size(32);
		@include line-height(32,48);
		letter-spacing: .02em;
	}
}

.take-a-look-section.take-a-look-section{
	position: relative;
	padding: 0;

	img{
		width: 100%;
	}

	.container{
		position: absolute;
		top: 0;
		height: 100%;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
		max-width: 167.1rem;

		.row{
			height: 100%;
		}

		*{
			color: white;
			text-align: right;
		}
	}

	h1{
		@include font-size(112);
		@include line-height(112,108);
		font-weight: 900;
		letter-spacing: .02em;

		@media (max-width: $b3){
			@include font-size(52);
		}
	}

	.text-side{

		@media (min-width: $b3 + 1){
				
			&.waypoint .text-con:after{
				max-height: 0;
			}
			
			&.animate .text-con:after{
				max-height: 500%;
				transition: $time*2 $time*2;
			}
		}
	}

	.text-con{
		float: right;
		width: 58rem;
		margin-top: 41.3rem;
		position: relative;

		@media (max-width: $b3){
			margin-top: 6vw;
		}

		&:after{
			content: '';
			position: absolute;
			top: calc(100% + 2.1rem);
			right: 1.7rem;
			background-image: url(../images/take-line.svg);
			background-repeat: no-repeat;
			width: 5.428rem;
			height: 500%;
			background-position: 50% 0;
			background-size: 100% auto;
		}
	}
}

.tour-section{
	padding-top: 10.05rem;
	padding-bottom: 11.3rem;

	.container {
		max-width: 170.1rem;
	}

	.text-top{
		text-align: center;
		position: relative;
		padding-bottom: 2.7rem;

		*:last-child{
			margin-bottom: 0;
		}

		&:after{
			content: '';
			position: absolute;
			left: 50%;
			height: .2rem;
			background-color: $green;
			width: 61.8rem;
			transform: translateX(-50%);

			@media (max-width: $b3){
				width: calc(100% - 8.4rem);
			}
		}


		&:after{
			bottom: 0;
		}

		*{
			color: $green;
		}

		h2{
			letter-spacing: .07em;
			margin-bottom: .2em;

			@media (max-width: $b3){
				line-height: 1.2;
			}
		}

		p{
			width: 71.7rem;
			margin-right: auto;
			margin-left: auto;
		}
	}

	.tour-slide-con{
		position: relative;
		padding-top: 6.5rem;
		pointer-events: none;

		&:before{
			content: '';
			position: absolute;
			left: 50%;
			height: 99%;
			background-color: $green;
			width: .2rem;
			transform: translateX(-50%);
			top: 0;
			z-index: -1;
		}
	}

	.swiper-wrapper-con{
		overflow: visible!important;

		.inner{
			@include font-size(0);
		}

		.swiper-slide{
			transform: scale(.9);
			transform-origin: 50% 50%;

			iframe{
				height: auto;
				aspect-ratio: 1.8577/1;
				border: none;
				pointer-events: auto;
			}

			&.swiper-slide-prev{
				transform-origin: 100% 50%;
			}

			&.swiper-slide-next{
				transform-origin: 0% 50%;
			}

			&.swiper-slide-active{
				transform: scale(1);
				transition: $time;
			}
		}
	}
}

.image-cta-section{
	padding-top: 11.9rem;
	height: 107.9rem;

	@media (max-width: $b3){
		height: 75rem;
	}

	.container {
		max-width: 174.1rem;
	}

	*{
		color: white;
	}

	.text-con{
		display: flex;
		justify-content: flex-end;
	}

	.inner{
		width: 26.75%;

		@media (max-width: $b3){
			width: 100%;
		}
	}

	.btn-con{
		margin-top: 3.2rem;

		.btn{
			border-width: .9rem;
			padding: 1.15rem 3rem;
		}
	}
}

.sponsors-section.sponsors-section{
	padding-top: 17.7rem;
	padding-right: 0rem;

	@media (max-width: $b3){
		padding-top: 10.7rem;
	}

	.container {
		max-width: 165rem;
	}

	.col-12{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 16.1rem;

		@media (max-width: $b3){
			margin-bottom: 10.7rem;
		}
	}
}

.text-links-section{
	padding-top: 17.1rem;
	padding-bottom: 18.4rem;
	background-image: url(../images/back-green-big.jpg);
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position: 50% 100%;
	overflow: visible!important;

	@media (max-width: $b3){
		padding-top: 7.5rem;
		padding-bottom: 7.5rem;
	}

	.container{
		max-width: 171.6rem;
	}

	*{
		color: white;
	}

	.filler-side{

		h1{
			text-align: right;
			position: relative;
			position: sticky;
			top: 17.1rem;

			@media (max-width: $b3){
				position: relative;
				top: 0;
			}

			&:after{
				content: '';
				position: absolute;
				bottom: 0;
				right: 0;
				width: 100vw;
				max-width: 100vw;
				height: .2rem;
				background-color: white; 
			}
		}
	}

	h1{
		@include font-size(72);
		@include line-height(72,76);
		opacity: .9;
		letter-spacing: .03em;
		font-weight: 900;
		margin-bottom: 0.34em;

		@media (max-width: $b3){
			@include font-size(36);
		}

		@media (max-width: 380px){
			@include font-size(34);
		}

		em{
			font-weight: 400;
			letter-spacing: .023em;
		}
	}

	p{
		@include font-size(25);
		@include line-height(25,41);
		letter-spacing: .008em;

		@media (min-width: $b3 + 1){
			padding-right: 1rem;
		}

		@media (max-width: $b3){
			@include font-size(18);
		}
	}

	.btn-con{
		margin-top: 3.3rem;

		.btn{
			font-weight: 400;
			font-style: italic;
			text-transform: uppercase;
			padding: 1.4rem 2.7rem;
			flex-wrap: wrap;

			@media (min-width: $b3 + 1){
				font-size: 3.3rem !important;
			}

			strong{
				font-style: normal;
				font-weight: 900;
				margin: 0 .2em;
			}
		}
	}

	.filler-side{

		@media (min-width: $b3 + 1){
			flex: 0 0 41.5%;
			max-width: 41.5%;
		}
	}

	.text-side{

		@media (min-width: $b3 + 1){
			flex: 0 0 58.5%;
			max-width: 58.5%;
		}


		.inner{
			border-bottom: .2rem solid white;
			padding-bottom: 3.7rem;
			margin-bottom: 5.5rem;

			&:last-of-type{
				margin-bottom: 0;
			}
		}	
	}
}

.contact-section{
	padding-top: 26.8rem;
	padding-bottom: 9.8rem;

	@media (max-width: $b3){
		padding-top: 25.2rem;
	}

	.container{
		max-width: 148.1rem;
	}

	.row{
		align-items: flex-start;
	}

	.top-text{
		margin-bottom: 1.7em;

		.inner{
			width: 70rem;
			margin-right: auto;
			margin-left: auto;
			text-align: center;
		}

		*{
			color: $blue;
		}
	}

	h4{
		margin-bottom: 1.54em;
	}

	.form-side{
		position: relative;

		@media (min-width: $b3 + 1){
			padding-right: 8rem;
		}
	}

	.text-side{
		position: relative;
		padding-top: 2.8rem;

		@media (min-width: $b3 + 1){
			padding-left: 3.8rem;
			padding-right: 5.8rem;
			position: relative;

			&:before{
				content: '';
				position: absolute;
				top: 3.7rem;
				left: 0rem;
				width: .3rem;
				opacity: .41;
				background-color: $blue;
				height: calc(100% - 4.2rem);
			}
		}

		*{
			text-transform: uppercase;
		}

		p{
			@include font-size(17);
			@include line-height(17,32);
			letter-spacing: .197em;
			margin-bottom: .8em;
			color: rgba($blue, .56);

			a{
				color: $blue;
				font-style: italic;
			}

			[href^="mailto:"]{
				font-style: normal;
				text-decoration: none;
			}
		}

		.social-menu{
			margin-top: 1.6em;

			a{
				color: $blue;
			}
		}
	}
}

.simple-hero-section{
	padding-top: 25.9rem;
	padding-bottom: 2.2em;
	text-align: center;

	.page-id-3371 &,
	.page-id-3343 &,
	.page-id-3332 &,
	.page-id-791 &,
	.single-events &{
		padding-top: 26.5rem;

		@media (max-width: $b3){
			padding-top: 25.9rem;
		}

		.inner{
			width: 150rem;
		}

		h1{

			em{
				font-weight: 400;
			}
		}
	}

	.page-id-3371 &,
	.page-id-3343 &,
	.page-id-3332 &,
	.page-id-791 &{
		padding-top: 23rem;
		padding-bottom: 1.2em;

		h1{
			margin-bottom: .27em;

			@media (min-width: $b3 + 1){
				@include font-size(72);
			}
		}

		h5{
			
			@media (min-width: $b3 + 1){
				@include font-size(37);
			}
		}
	}

	// .page-id-3332 &{

	// 	@media (min-width: $b3 + 1){

	// 		.row{
	// 			padding-top: 25.3rem;
	// 		}


			
	// 	}
	// }

	.inner{
		width: 80rem;
		margin-right: auto;
		margin-left: auto;
	}

	h1{
		margin-bottom: -.12em;
	}

	h5,
	h4{
		color: $coral;
	}

	h4{
		margin-top: 1em;
		margin-bottom: .64em;
	}

	h5{
		@include font-size(31);
		margin-bottom: .7em;
	}
}

.main-wrapper:has(.text-with-image-section + .image-with-icons-section){
	background-image: url(../images/back-grey-alt.jpg);
	background-repeat: no-repeat;
	background-position: 50% 125.5rem;
	background-size: 100% auto;
}

.image-with-icons-section{
	padding-top: 8.6rem;
	padding-bottom: 15rem;

	@media (max-width: $b3){
		padding-top: 6.4rem;
		padding-bottom: 5rem;
	}

	.container{
		max-width: 162.1rem;
	}

	.image-side{

		@media (min-width: $b3 + 1){
			flex: 0 0 49.4%;
			max-width: 49.4%;

			img{
				width: calc(100% + 1.7rem + ((100vw - 162.1rem)/2));
				max-width: calc(100% + 1.7rem + ((100vw - 162.1rem)/2));
				float: right;
			}
		}

		img{
			box-shadow: -2.6rem -2.9rem 0 $coral;
		}
	}

	.text-side{
		padding-top: 11.1rem;
		position: relative;
		z-index: 1;

		@media (min-width: $b3 + 1){
			flex: 0 0 50.6%;
			max-width: 50.6%;
			padding-left: 12.3rem;
		}
	}

	h1{
		@include font-size(72);
		@include line-height(72,72);
		letter-spacing: .02em;
		color: $coral;

		@media (max-width: $b3){
			@include font-size(35);
		}

		em{
			font-weight: 400;
		}
	}

	hr{
		margin: 1rem 0 2.1rem;
		background-color: $coral;
		width: 61.4%;

		@media (min-width: $b3 + 1){
			width: calc(61.4% + 12.3rem + 2.75rem);
			max-width: calc(61.4% + 12.3rem + 2.75rem);
			margin-left: -15.05rem;
		}
	}

	.text-top{
		margin-bottom: 6rem;

		h1{
			margin-bottom: .68em;
			position: relative;

			&::before{
				content: '';
				position: absolute;
				left: 4.6rem;
				top: calc(100% + 1.8rem);
				height: 80.8rem;
				width: .2rem;
				display: block;
				z-index: -1;
				background: repeating-linear-gradient($coral, $coral 1.9rem, transparent 1.9rem, transparent 3.8rem);
			}

			&:after{
				content: '';
				position: absolute;
				left: 4.6rem;
				top: calc(100% + 1.8rem + 80.8rem);
				height: .2rem;
				width: calc((100% - 4.6rem + 1.7rem + 2.75rem) + ((100vw - 162.1rem)/2));
				max-width: calc((100% - 4.6rem + 1.7rem + 2.75rem) + ((100vw - 162.1rem)/2));
				display: block;
				z-index: -1;
				background: repeating-linear-gradient(to right, $coral, $coral 1.9rem, transparent 1.9rem, transparent 3.8rem);
			}
		}
	}

	.text-under{
		padding-top: 5.6rem;
		padding-bottom: 3.5rem;
	}

	p{
		padding-left: 15.3rem;

		@media (max-width: $b3){
			padding-left: 9.3rem;
		}
	}

	.icons{
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.icon-con{
			width: 15.3rem;

			@media (max-width: $b3){
				width: 8.3rem;
				margin-right: 1rem;
			}
		}

		+ .icons{
			margin-top: 10rem;
		}
	}
}

.banner-section{
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position: 50% 100%;
	padding-top: 17.4rem;
	padding-bottom: 24.4rem;
	text-align: center;
	position: relative;

	&::before{
		content: '';
		position: absolute;
		left: calc(50% - 1.1rem);
		transform: translateX(-50%);
		top: 0;
		height: 17.4rem;
		width: .2rem;
		display: block;
		z-index: 0;
		background: repeating-linear-gradient(transparent, transparent 1.9rem, white 1.9rem, white 3.8rem);
		filter: drop-shadow(0 0 .4rem rgba($black, .15));
	}

	*{
		color: white;
	}

	.container {
		max-width: 112.1rem;
	}

	h4{
		@include font-size(32);
		@include line-height(32,38);
		letter-spacing: .02em;
		font-weight: 900;
		margin-bottom: 1.2em;
	}

	.text{
		position: relative;

		&::before{
			content: '';
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: 100%;
			height: 24.4rem;
			width: .2rem;
			display: block;
			z-index: 0;
			background: repeating-linear-gradient(white, white 1.9rem, transparent 1.9rem, transparent 3.8rem);
			filter: drop-shadow(0 0 .4rem rgba($black, .15));
		}

		.inner{
			padding: 3.3rem 0 3.2rem;
			position: relative;

			&:before,
			&:after{
				content: '';
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				height: .2rem;
				width: 44.6rem;
				background-color: white;
				filter: drop-shadow(0 0 .4rem rgba($black, .15));
			}

			&:before{
				top: 0;
			}

			&:after{
				bottom: 0;
			}

			*{
				text-shadow: 0 0 .5rem rgba($black, .15);
			}

			*:last-child{
				margin-bottom: 0;
			}
		}
	}
}

.purple-hero-section{
	padding-top: 28.3rem;
	padding-bottom: 4rem;

	@media (max-width: $b3){
		padding-top: 25.3rem;
	}

	.container{
		max-width: 151.1rem;
	}

	.row{
		align-items: center;
	}

	.image-side{

		@media (max-width: $b3){
			margin-bottom: -5rem;
			z-index: 1;
			position: relative;
		}

		@media (min-width: $b3 + 1){
			flex: 0 0 43.7%;
			max-width: 43.7%;
			padding-right: 0;

			img{
				width: calc(100% + 1.7rem + ((100vw - 151.1rem)/2));
				max-width: calc(100% + 1.7rem + ((100vw - 151.1rem)/2));
				float: right;
			}
		}
	}

	.text-side{
		position: relative;
		padding-top: 10.7rem;
		padding-bottom: 9.1rem;
		margin-top: .9rem;
		background-image: url(../images/back-purple.jpg);
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: cover;
		background-position: 50% 100%;

		@media (max-width: $b3){
			padding-top: 8.7rem;
			padding-bottom: 3.1rem;
		}
		
		&:after{
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: calc(100% + 1.7rem + ((100vw - 151.1rem)/2));
			max-width: calc(100% + 1.7rem + ((100vw - 151.1rem)/2));
			background-color: $purple;
			z-index: -1;
			background-image: url(../images/back-purple.jpg);
			background-repeat: no-repeat;
			background-attachment: fixed;
			background-size: cover;
			background-position: 92.5% 100%;
			background-size: 100vw auto;
		}

		*{
			color: $white;
		}

		@media (min-width: $b3 + 1){
			flex: 0 0 56.3%;
			max-width: 56.3%;
			padding-left: 13.7rem;
		}

		h4{
			font-weight: 900;
			margin-bottom: .8em;
		}

		h6{

			&::before{
				background-color: white;
			}
		}

		p{
			margin-bottom: 1.45em;
		}
	}
}

.project-lead-section{
	padding-top: 9.5rem;
	padding-bottom: 12.2rem;

	@media (max-width: $b3){
		padding-top: 5.2rem;
		padding-bottom: 5.2rem;
	}

	.container{
		max-width: 148.2rem;

		@media (min-width: $b3 + 1){
			padding-left: 9.3rem;
		}
	}
}

.team-members-section{
	padding-top: 12.6rem;
	padding-bottom: 12.8rem;

	@media (max-width: $b3){
		padding-top: 5.2rem;
		padding-bottom: 5.2rem;
	}

	.container{
		position: relative;
		z-index: 1;
		max-width: 152.6rem;
	}

	@media (min-width: $b3 + 1){
		
		.container,
		.col-12{
			padding-right: 11.31rem;
			padding-left: 11.31rem;
		}

		.row{
			margin-right: -11.31rem;
			margin-left: -11.31rem;
		}
	}
	
	&.grey,
	&.purple,
	&.green{
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: cover;
		background-position: 50% 100%;
	}

	&.grey{
		background-image: url(../images/back-grey-team.jpg);

		+ .grey{
			background-image: url(../images/back-grey-team2.jpg);

			*{
				color: $green;
			}

			.t-item{

				.img-side{
	
					&:before{
						background-color: $green;
					}
	
					&:after{
						border-color: $green;
					}
				}
	
				.email-con a{
					color: $green;

					&:hover,
					&:focus-visible{
						color: $coral;

						*{
							color: $coral;
						}
					}
				}

				.social-menu a{

					&:hover,
					&:focus-visible{
						color: $coral!important;
	
						*{
							color: $coral!important;
						}
					}
				}
	
				.bio::before{
					background-color: $green;
				}
			}
		}

		*{
			color: $coral;
		}

		.t-item{

			.img-side{

				&:before{
					background-color: $coral;
				}

				&:after{
					border-color: $coral;
				}
			}

			.email-con a{
				color: $coral;

				&:hover,
				&:focus-visible{
					color: $blue;

					*{
						color: $blue;
					}
				}
			}

			.social-menu a{

				&:hover,
				&:focus-visible{
					color: $blue!important;

					*{
						color: $blue!important;
					}
				}
			}

			.bio::before{
				background-color: $coral;
			}
		}
	}

	&.purple{
		background-image: url(../images/back-purple-team.jpg);

		.title-con{

			*{
				color: $coral;
			}
		}
	}

	&.green{
		background-image: url(../images/back-green-team.jpg);

		.title-con{

			*{
				color: $green-light;
			}
		}
	}


	&.purple,
	&.green{

		*{
			color: white;
		}

		.t-item{

			.img-side{

				&:before{
					background-color: $white;
				}

				&:after{
					border-color: $white;
				}
			}

			.email-con a{
				color: $white;

				&:hover,
				&:focus-visible{
					color: $coral;

					*{
						color: $coral;
					}
				}
			}

			.bio::before{
				background-color: $white;
			}
		}
	}

	.title-con{
		text-align: center;
		margin-bottom: 7.43em;

		@media (max-width: $b3){
			margin-bottom: 3.43em;
		}

		h1{
			@include font-size(72);
			@include line-height(72,68);
			letter-spacing: .02em;
			font-weight: 700;

			@media (max-width: $b3){
				@include font-size(49);
			}
		}
	}

	.t-item{

		&:last-of-type{
			margin-bottom: 0;
		}
	}
}


.t-item{
	
	&.leader{

	}
	
	
	@media (min-width: $b3 + 1){
		
		&.wide{
			margin-bottom: 23.2rem;

			.img-side{
				width: 32.2%;

				.img{
					aspect-ratio: .9045/1;
				}
			}

			.text-side{
				width: 67.8%;
				padding-left: 6.9rem;

				h4{
					margin-top: -.2em;
				}
			}

			&.right{

				.img-side{
					order: 2;

					&:before{
						top: .8rem;
						left: 1.1rem;
					}

					&:after{
						top: -1rem;
						left: -1.1rem;
					}
				}

				.text-side{
					padding-left: 0rem;
					padding-right: 7.9rem;
					text-align: right;

					.bio{
						padding-left: 0;
						padding-right: 3.7rem;
						justify-content: flex-end;

						&:before{
							left: auto;
							right: -.1rem;
						}
					}

					.email-con{
						order: 3;
						margin-right: 0;
						margin-left: 1.7rem;

						a{

							i{
								margin-right: 0;
								margin-left: 1.7rem;
								order: 2;
							}
						}
					}
				}
			}
		}

		&.half{
			margin-bottom: 3rem;

			.img-side{
				width: 100%;
				margin-bottom: 7.6rem;

				.img{
					aspect-ratio: 1.5982/1;
				}
			}

			.text-side{
				width: 100%;
				padding-left: 0rem;

				h4{
					margin-top: 0em;
					margin-bottom: .3em;
				}

				h5{
					margin-bottom: 1.1em;
				}

				.bio{
					padding-left: 0;

					&::before{
						left: -3.8rem;
						height: calc(100% - 12.4rem);
					}
				}

				p{
					margin-bottom: 1.5em;
				}

				.email-con{
					width: 100%;
					margin: 0 0 2.3rem;
				}
			}
		}
	}

	@media (max-width: $b3){
		
		&.wide,
		&.half{
			margin-bottom: 3rem;
		}
	}

	.inner{
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}

	.img-side{
		position: relative;

		@media (max-width: $b3){
			width: 100%;
			margin-bottom: 2rem;
		}

		&:before{
			content: '';
			position: absolute;
			top: 1rem;
			left: -1.7rem;
			width: calc(100% + .6rem);
			max-width: calc(100% + .6rem);
			height: calc(100% + .5rem);
			background-color: $blue;
			z-index: -1;
		}

		&:after{
			content: '';
			position: absolute;
			width: 99.7%;
			height: 99.8%;
			top: -.9rem;
			left: 1.2rem;
			border: .3rem solid $blue;
		}

		@media (min-width: $b3 + 1){
			width: 30.6%;
		}

		.img{
			aspect-ratio: 0.698/1;

			@media (max-width: $b3){
				aspect-ratio: 0.998/1;
			}
		}
	}

	.text-side{

		@media (min-width: $b3 + 1){
			width: 69.4%;
			padding-left: 6.5rem;
		}
	}

	h1{
		margin-top: .27em;
		@include font-size(72);
		@include line-height(72,68);
		letter-spacing: .02em;
		margin-bottom: .86em;

		@media (max-width: $b3){
			@include font-size(49);
		}
	}

	h4{
		font-weight: 900;
		text-transform: none;
		margin-bottom: .65em;

		@media (max-width: $b3){
			@include font-size(32);
			margin-top: 1rem;
		}
	}

	h5{
		font-weight: 900;
		letter-spacing: .02em;
		margin-bottom: 1.5em;
	}

	.bio{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding-left: 4.7rem;
		position: relative;

		&::before{
			content: '';
			position: absolute;
			top: .2rem;
			left: .8rem;
			width: .3rem;
			opacity: .41;
			background-color: $blue;
			height: calc(100% - 1.3rem);
		}
	}

	.email-con{
		display: flex;
		align-items: center;

		&:has(+ .social-menu){
			margin-right: 3.9rem;
		}

		a{
			display: flex;
			align-items: center;
			text-decoration: none;
			color: $blue;
			@include font-size(17);
			@include line-height(17,32);
			letter-spacing: .197em;
			font-weight: 500;
		}

		i{
			margin-right: 2.4rem;

			@media (max-width: $b3){
				margin-right: 1.5rem;
			}
		}
	}

	.social-menu{

		a{
			color: $blue;
			margin: .5rem 3.4rem .5rem 0;

			@media (max-width: $b3){
				margin: .5rem 1.5rem .5rem 0;
			}

			&.disabled{
				pointer-events: none;
				opacity: .12;
			}
		}
	}

	i{
		@include font-size(27.81);
	}

	p{
		width: 100%;
		@include font-size(17);
		@include line-height(17,30);
		font-weight: 500;
		letter-spacing: .087em;
		color: rgba($blue, .56);
		margin-bottom: 2.7em;
	}
}

.form-section{
	padding-top: 11.3rem;
	padding-bottom: 14.7rem;

	@media (max-width: $b3){
		padding-top: 5.3rem;
		padding-bottom: 6.7rem;
	}

	.container{
		max-width: 162.8rem;
	}

	.title-con{
		text-align: center;
		margin-bottom: 6rem;

		h4{
			font-weight: 900;
		}
	}

	.wpcf7-form{
		align-items: baseline;

		
		.left{
			width: 40.5%;

			@media (max-width: $b3){
				width: 100%;
			}

			legend, 
			label{
				width: 19.6rem;
				margin-right: 2.82rem;

				@media (max-width: $b3){
					margin-right: 0;
					margin-bottom: 1rem;
				}
			}

			.wpcf7-form-control-wrap{
				max-width: calc(100% - 19.6rem - 2.82rem);

				@media (max-width: $b3){
					width: 100%;
					max-width: 100%;
				}
			}
		}

		.right{
			width: 53.8%;

			@media (max-width: $b3){
				width: 100%;
			}

			legend, 
			label{
				width: 38rem;
				margin-right: 2.82rem;

				@media (max-width: $b3){
					margin-right: 0;
					margin-bottom: 1rem;
				}
			}

			.wpcf7-form-control-wrap{
				max-width: calc(100% - 38rem - 2.82rem);

				@media (max-width: $b3){
					width: 100%;
					max-width: 100%;
				}
			}

			input[type="radio"],
			input[type="checkbox"]{

				& + span,
				& + .wpcf7-list-item-label,
				& + label{
					padding-right: 2.82rem + 4.3rem!important;
					width: calc(38rem + 2.82rem + 4.3rem);
				}
			}
		}
	}

	.submit-con{
		margin-top: 4.3rem;
		justify-content: center;
	}

	.form-section .wpcf7-form .left legend,
	.form-section .wpcf7-form .left label {
		width: 19.6rem;
		margin-right: 2.82rem;
	}


	.wpcf7 div.wpcf7-response-output.wpcf7-response-output.wpcf7-response-output,
	.wpcf7 div.wpcf7-mail-sent-ok, 
	.wpcf7 div.wpcf7-validation-errors, 
	.wpcf7 div.wpcf7-acceptance-missing{
		width: 100%;
		max-width: 100%;
		margin-left: 0;
	}
}

.distance-travelled-section{
	padding-top: 12.35rem;
	padding-bottom: 12.8rem;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position: 50% 100%;
	position: relative;

	.container {
		max-width: 165rem;
	}

	&::before{
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(black, .48);
		mix-blend-mode: multiply;
	}

	.container{
		position: relative;
		z-index: 1;
	}
	
	*{
		color: white;
	}

	h1{
		text-align: center;
		@include font-size(72);
		@include line-height(72,68);
		letter-spacing: .02em;
		font-weight: 900;
		position: relative;
		margin-bottom: 6.1rem;

		@media (max-width: $b3){
			@include font-size(49);
		}

		.back{
			@include font-size(182);
			@include line-height(182,68);
			position: absolute;
			top: 7rem;
			left: 50%;
			transform: translateX(-50%);
			opacity: .13;
			max-width: none;
			white-space: nowrap;

			@media (max-width: $b3){
				@include font-size(150);
			}
		}
	}

	.distance{
		text-align: center;
		@include font-size(169);
		@include line-height(169,210);
		letter-spacing: -.02em;
		font-weight: 700;
		position: relative;
		margin-bottom: 4.6rem;

		@media (max-width: $b3){
			@include font-size(49);
		}

		.back{
			@include font-size(290);
			@include line-height(290,360);
			position: absolute;
			top: .2rem;
			left: 50%;
			transform: translateX(-50%);
			opacity: .13;
			max-width: none;
			white-space: nowrap;

			@media (max-width: $b3){
				@include font-size(150);
			}
		}
	}

	.chart{
		position: relative;
		display: flex;
		align-items: flex-end;
		@include font-size(29);
		@include line-height(29,36);
		letter-spacing: -.02em;

		@media (max-width: $b3){
			@include font-size(20);
		}

		&:before{
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			height: 1.1rem;
			background-color: rgba(white, .34);
			bottom: 4rem;
			bottom: 7.8rem;
		}

		.start,
		.current,
		.end{
			position: relative;

			.location,
			span{
				position: absolute;
				bottom: -.8rem;
				width: auto;
				max-width: none;
				font-weight: 700;

				@media (max-width: $b3){
					bottom: -.5rem;

					h5{
						@include font-size(12);
					}
				}
				
			}

			span{
				white-space: nowrap;
			}
		}

		.start{
			border-left: 1.1rem solid white;
			height: 12.3rem;

			span{
				left: 1.1rem;

				@media (max-width: $b3){
					bottom: -3.4rem;
					left: -1.1rem;
				}
			}
		}

		.current{
			//border-right: 1.1rem solid white;
			height: 19.3rem;

			&:before{
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				height: 1.1rem;
				background-color: rgba(white, 1);
				bottom: 4rem;
				bottom: 7.8rem;
			}

			&:after{
				content: '';
				position: absolute;
				top: 0;
				right: -1.1rem;
				width: 1.1rem;
				max-width: 1.1rem;
				background-color: white;
				height: calc(100% - 0%);
			}

			.location{
				bottom: auto;
				top: 0;
				min-width: 26.3rem;

				@media (max-width: $b3){
					min-width: 14.3rem;
				}

				h5{
					letter-spacing: .04em;
				}

				em,
				div{
					//white-space: nowrap;
					font-style: italic;
				}

				em{
					margin-bottom: 0.2em;
					display: inline-block;
				}
			}

			&.under-50{

				&.less-than-6{
					
					@media (min-width: $b3 + 1){

						&:after{
							height: calc(100% - 3rem);
						}
					
						span{
							bottom: 2.3rem;
						}
					}
				}

				.location,
				span{
					left: calc(100% + 2.7rem);
				}
			}

			&.over-50{

				@media (min-width: $b3 + 1){
					
					&.more-than-77{

						&:after{
							height: calc(100% - 3rem);
						}

						span{
							bottom: 2.3rem;
						}
					}
				}

				.location,
				span{
					right: 1.1rem;
					text-align: right;
				}
			}
		}

		.end{
			border-right: 1.1rem solid white;
			height: 12.3rem;
			margin: 0 0 0 auto;

			span{
				right: 1.1rem;

				@media (max-width: $b3){
					bottom: -3.4rem;
					right: -1.1rem;
				}
			}
		}
	}
}

.explore-section{
	padding-top: 12.4rem;
	padding-bottom: 4.6rem;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position: 50% 100%;
	background-image: url(../images/back-grey-team.jpg);

	@media (max-width: $b3){
		padding-top: 7.4rem;
	}

	.container{
		max-width: 160.6rem;
	}

	@media (min-width: $b3 + 1){
		
		.container,
		.col-12{
			padding-right: 3.0rem;
			padding-left: 3.0rem;
		}

		.row{
			margin-right: -3.0rem;
			margin-left: -3.0rem;
		}
	}

	.title-con{
		margin-bottom: 8.5rem;

		h1{
			@include font-size(72);
			@include line-height(72,68);
			font-style: italic;
			font-weight: 900;
			color: $coral;
			letter-spacing: .02em;
			display: flex;
			align-items: baseline;

			@media (max-width: $b3){
				@include font-size(32);
			}

			span{
				flex: 1 1 auto;
				background: repeating-linear-gradient(to right, $coral, $coral 1.9rem, transparent 1.9rem, transparent 3.8rem);
				height: .3rem;
				margin: 0 5.3rem 0 2rem;
				position: relative;

				svg{
					position: absolute;
					bottom: 0rem;
					right: -3.1rem;
					width: 11.07rem;
				}
			}
		}
	}
}

.b-item{
	margin-bottom: 6rem;

	.inner{
		width: 100%;
		position: relative;

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;

			&:hover,
			&:focus-visible{
				
				~ .img-con{

					.img{
						transform: translate(-50%, -50%) scale(1.19);
					}
				}

				~ h4{
					padding: 4.8rem 1rem 0rem 2.8rem;
				}
			}
		}

		.img-con{
			overflow: hidden;
			width: 100%;
			aspect-ratio: .979827/1;
			box-shadow: .6rem .4rem 0 $coral;
			position: relative;
		}

		.img{
			aspect-ratio: .979827/1;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(1.01);
			transition: $time;
			width: 100%;
		}

		h4{
			@include font-size(32);
			@include line-height(32,39);
			font-weight: 900;
			font-style: italic;
			letter-spacing: .02em;
			color: $coral;
			margin-bottom: 0;
			padding: 4.8rem 1rem 0rem 4rem;
			margin-left: .6rem;
			position: relative;
			transition: $time;

			&:before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: .3rem;
				height: calc(100% - .2rem);
				background-color: $coral;
			}
		}
	}
}

.global-voyage-legs-section{
	padding-top: 9.6rem;
	padding-bottom: 7.5rem;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position: 50% 100%;
	background-image: url(../images/back-blue-dark.jpg);

	.container {
		max-width: 141.7rem;
	}

	*{
		color: white;
	}

	.title-con{
		position: relative;
		text-align: center;
		padding-bottom: 6.1rem;

		@media (min-width: $b3 + 1){
			padding-left: 28rem;
		}

		@media (max-width: $b3){
			padding-bottom: 7.1rem;
		}

		h2{
			letter-spacing: .02em;
		}

		svg{
			position: absolute;
			bottom: 0;
			left: 15.2%;
			width: 11.07rem;

			*{
				fill: white;
			}
		}
	}

	.continue{
		@include font-size(41);
		@include line-height(41,52);
		font-weight: 400;
		text-align: center;
		margin-top: .5rem;

		@media (max-width: 380px){
			@include font-size(36);
		}

		div{
			font-weight: 400;
		}
	}

	.leg{
		margin-bottom: 11.9rem;

		&:nth-of-type(2){
			position: relative;

			&:after{
				content: '';
				position: absolute;
				top: -3.9rem;
				left: 16.9%;
				background-image: url(../images/go-line1.svg);
				background-repeat: no-repeat;
				width: 55.8rem;
				height: 72.0rem;
				background-position: 50% 0;
				background-size: 100% auto;

				@media (max-width: $b3){
					display: none;
				}
			}
		}

		&:nth-of-type(4){
			position: relative;

			&:after{
				content: '';
				position: absolute;
				top: -13.8rem;
				left: 7.7%;
				background-image: url(../images/go-line2.svg);
				background-repeat: no-repeat;
				width: 57.3rem;
				height: 83.4rem;
				background-position: 50% 0;
				background-size: 100% auto;

				@media (max-width: $b3){
					display: none;
				}
			}
		}

		.inner{
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;

			.img-side{
				width: 58.4%;
				position: relative;

				@media (max-width: $b3){
					width: 100%;
				}

				&:before{
					content: '';
					position: absolute;
					top: 1.1rem;
					left: 1.4rem;
					width: 100%;
					height: calc(100% + .7rem);
					background-color: white;
				}

				.img-con{
					@include font-size(0);
					aspect-ratio: 2.1550339/1;
					position: relative;
					z-index: 1;
					
				}
			}

			.info-side{
				width: 41.6%;
				padding-left: 8.6rem;
				padding-top: 4.3rem;

				@media (max-width: $b3){
					width: 100%;
					padding-left: 0rem;
				}

				.leg-number{
					@include font-size(32);
					@include line-height(32,29);
					color: rgba(white, .54);
					font-weight: 500;
					font-style: italic;
					margin-bottom: .35em;
				}

				h4{
					@include line-height(41,46);
					margin-bottom: .4em;
				}

				table{
					margin-bottom: .8em!important;

					+ table{
						margin-bottom: 0!important;
					}
				}

				table tbody th, 
				table tbody td{
					padding: .7rem 1.5rem!important;
					color: rgba(white, .6)!important;
					text-transform: uppercase;
				}

				table tbody th:first-of-type, 
				table tbody td:first-of-type{
					border-left: none!important;
					padding: .7rem 0.1rem .7rem 0rem!important;
					color: white!important;
					width: 18.1rem!important;
				}

				.btn-con{
					margin-top: 2rem;
				}
			}
		}
	}
}


.activites-section.activites-section{
	padding-top: 0rem;
	padding-bottom: 10.3rem;
	background-image: url(../images/back-grey-long.jpg);
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-size: 100% auto;
	overflow: visible;
	position: relative;
	z-index: 1;

	.container{
		max-width: 153.1rem;
	}

	.row1{
		margin-bottom: 19.9rem;

		@media (max-width: $b3){
			margin-bottom: 3rem;
		}

		.image-side{

			a{
				position: relative;
				display: inline-block;
	
				&:hover,
				&:focus-visible{
	
					&::before{
						background-color: white;	
					}
	
					&:after{
						border-color: transparent transparent transparent $blue;
					}
				}
	
				&::before{
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 23.5rem;
					height: 23.5rem;
					border-radius: 23.5rem;
					border: 1.4rem solid white;
	
					@media (max-width: $b3){
						width: 10.5rem;
						height: 10.5rem;
					}
				}
	
				&:after{
					content: '';
					position: absolute;
					top: 50%;
					left: 51.1%;
					transform: translate(-50%, -50%);
					width: 0;
					height: 0;
					border-width: 4.702rem 0 4.702rem 8.173rem;
					border-color: transparent transparent transparent white;
					border-style: solid;
	
					@media (max-width: $b3){
						border-width: 2.302rem 0 2.302rem 3.773rem;
					}
				}
			}

			&::before{
				left: -23.6rem;
				bottom: -7.9rem;
			}
			
			@media (min-width: $b3 + 1){
				flex: 0 0 49.1%;
				max-width: 49.1%;
				margin-top: -3.2rem;

				a{
					width: calc(100% + 1.7rem + ((100vw - 153.1rem)/2));
					max-width: calc(100% + 1.7rem + ((100vw - 153.1rem)/2));
					float: right;

					img{
						width: 100%;
						float: none;
					}
				}

				img{
					width: calc(100% + 1.7rem + ((100vw - 153.1rem)/2));
					max-width: calc(100% + 1.7rem + ((100vw - 153.1rem)/2));
					float: right;
				}
			}

			img{
				box-shadow: -3.3rem -2.7rem $blue-light;
			}
		}

		.text-side{
			padding-top: 9.1rem;

			@media (min-width: $b3 + 1){
				flex: 0 0 50.7%;
				max-width: 50.7%;
				padding-left: 11.6rem;
			}

			@media (min-width: $b3 + 1){
				
				&.waypoint .inner:after{
					max-height: 0;
				}
				
				&.animate .inner:after{
					max-height: 72.0rem;
					transition: $time*2 $time*2;
				}
			}

			.inner{
				position: relative;
				z-index: 1;

				&:after{
					content: '';
					position: absolute;
					background-repeat: no-repeat;
					background-size: 100% 100%;
					background-position: 50% 0;
					z-index: -1;
					width: 55.8rem;
					height: 72.0rem;
					background-image: url(../images/ol1.svg);
					right: calc(100% + 3.3rem);
					top: 13.6rem;
					background-size: 100% 72.0rem;
				}
			}

			p{
				margin-bottom: 1.3em;
				
				&:first-of-type{
					opacity: .5;
					letter-spacing: .027em;
				}

				strong{
					font-weight: 600;
					letter-spacing: .037em;
				}
			}
			
			hr{
				margin: 1.3rem 0 1.1rem;
				width: 47%;
				background-color: $blue;
		
				@media (min-width: $b3 + 1){
					width: calc(47% + 12.5rem + 2.75rem);
					margin-left: -12.5rem - 2.75rem;

					&:has(+ h5){
						width: calc(83.8% + 12.5rem + 2.75rem);
						max-width: calc(83.8% + 12.5rem + 2.75rem);
						margin-bottom: 2.2rem;
					}
				}
			}
		}

		h1{

			em{
				font-weight: 400;
				letter-spacing: .008em;
			}
		}
	}

	.row2{
		margin-bottom: 14.6rem;
		align-items: flex-start;

		@media (max-width: $b3){
			margin-bottom: 3rem;
		}

		.image-side{

			@media (max-width: $b3){
				margin-bottom: 2rem;
			}

			&.col-md-3{

				&::before{
					display: none;
				}

				@media (min-width: $b3 + 1){
					flex: 0 0 14.2%;
					max-width: 14.2%;

					img{
						width: calc(100% + 1.7rem + ((100vw - 153.1rem)/2));
						max-width: calc(100% + 1.7rem + ((100vw - 153.1rem)/2));
						float: right;
					}
				}
				
			}

			&.col-md-6{

				&::before{
					right: -30.9rem;
					bottom: -24.4rem;
				}

				@media (min-width: $b3 + 1){
					flex: 0 0 42.45%;
					max-width: 42.45%;

					img{
						width: calc(100% + 1.7rem + 2.2rem + ((100vw - 153.1rem)/2));
						max-width: calc(100% + 1.7rem + 2.2rem + ((100vw - 153.1rem)/2));
					}
				}
			}
		}

		.text-side{
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;
			align-items: flex-start;
			
			@media (min-width: $b3 + 1){
				flex: 0 0 43.35%;
				max-width: 43.35%;
				padding-right: 2.5rem;
				padding-top: 17.6rem;
			}

			@media (min-width: $b3 + 1){
				
				&.waypoint .inner:after{
					max-height: 0;
				}
				
				&.animate .inner:after{
					max-height: 18.285rem;
					transition: $time*2 $time*2;
				}
			}

			.inner{
				position: relative;
				z-index: 1;

				&:after{
					content: '';
					position: absolute;
					background-repeat: no-repeat;
					background-size: 100% 100%;
					background-position: 50% 0;
					z-index: -1;
					width: 54.819rem;
					height: 18.285rem;
					background-image: url(../images/ol4.svg);
					left: 1.1rem;
					top: calc(100% - -6.2rem);
					transform: rotate(13deg);
					background-size: 100% 18.285rem;
				}
			}

			.btn-con{
				padding-right: 8.4rem;
				margin: 0;
				margin-top: 3.3rem;

				@media (min-width: $b3 + 1){
					margin-right: -5.5rem;
				}

				.btn{
					margin: 0;
					min-width: 25.8rem;
				}
			}

			h1{
				margin-bottom: .07em;

				em{
					font-weight: 400;
					letter-spacing: .014em;
				}
			}

			p{
				padding-left: .6rem;
				width: 50rem;
			}
		}
	}

	.row3{

		.text-side{
			position: relative;
			z-index: 2;
			text-align: center;

			p{
				margin-bottom: 1.6em;
			}

			h1{
				@include line-height(72,68);
				margin-bottom: .92em;
				position: relative;
				
				+ p{
					opacity: .39;
					letter-spacing: .197em;
					margin-bottom: .55em;
					text-transform: uppercase;
				}
			}

			h4{
				color: $coral;
				margin-bottom: .6em;
				font-weight: 900;

				em{
					color: $blue;
				}

			}
		}
	}

	.image-side{
		position: relative;
		z-index: 1;

		&::before{
			content: '';
			position: absolute;
			width: 53.44946rem;
			height: 17.30278rem;
			background-image: url(../images/waves.svg);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: 100% 0;
			z-index: -1;

			@media (max-width: $b3){
				display: none;
			}
		}
	}	

	h1{
		@include font-size(72);
		@include line-height(72,72);
		letter-spacing: .02em;
		margin-bottom: .3em;

		@media (max-width: $b3){
			@include font-size(49);
		}
	}

	h5{
		@include font-size(32);
		@include line-height(32,48);
		letter-spacing: .02em;
	}
}

.who-is-going-section{
	padding-top: 10.1rem;
	padding-bottom: .1rem;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position: 50% 100%;
	text-align: center;
	position: relative;

	&:before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		mix-blend-mode: multiply;
		background-color: rgba($blue, .48);
	}

	.container{
		position: relative;
		z-index: 1;
		max-width: 148.1rem;
	}

	.row{
		justify-content: center;
	}

	*{
		color: white;
	}

	.title-con{
		margin-bottom: 13.1rem;

		h1{
			text-align: center;
			@include font-size(72);
			@include line-height(72,68);
			letter-spacing: .02em;
			font-weight: 900;
			position: relative;
			margin-bottom: 6.1rem;

			@media (max-width: $b3){
				@include font-size(49);
			}
	
			.back{
				@include font-size(182);
				@include line-height(182,68);
				position: absolute;
				top: 7rem;
				left: 50%;
				transform: translateX(-50%);
				opacity: .13;
				max-width: none;
				white-space: nowrap;

				@media (max-width: $b3){
					@include font-size(150);
				}
			}
		}
	}

	.col-md-4{
		margin-bottom: 9.7rem;

		.inner{
			position: relative;

			.number{
				position: absolute;
				top: -10.9rem;
				left: -2.7rem;
				@include font-size(150);
				opacity: .13;
				color: $white;
				z-index: -1;
				font-weight: 700;
				font-family: $alt-font2;
				letter-spacing: -.02em;
			}

			h2{
				letter-spacing: .02em;
				margin-bottom: -.05em;
			}

			.s-number{
				@include font-size(106);
				@include line-height(106,132);
				letter-spacing: -.02em;
				font-weight: 700;
				
				@media (max-width: $b3){
					@include font-size(80);
				}
			}
		}
	}
}

.cta-section{
	padding-top: 10rem;
	padding-bottom: 10rem;
	text-align: center;

	@media (max-width: $b3){
		padding-top: 5rem;
		padding-bottom: 5rem;
	}

	h1{
		color: $coral;
		@include font-size(71);
		letter-spacing: .01em;
	
		@media (max-width: $b3){
			@include font-size(49);
		}

		@media (max-width: 380px){
			@include font-size(40);
		}
	}

	.btn-con{
		margin-top: 4.8rem;
		position: relative;
		z-index: 1;

		&:after{
			content: '';
			position: absolute;
			left: calc((100vw - 162.1rem)/-2 + 3.45rem);
			top: 50%;
			height: .8rem;
			transform: translateY(-50%);
			width: 100vw;
			max-width: 100vw;
			display: block;
			z-index: -1;
			background: repeating-linear-gradient(to right, $coral, $coral 2.7rem, transparent 2.7rem, transparent 3.8rem);
		}

		.btn{
			font-size: 3.3rem !important;
			font-weight: 400;
			font-style: italic;
			text-transform: uppercase;
			padding: 1.4rem 2.7rem;
			background-color: white;

			&:hover,
			&:focus-visible{
				background-color: $coral;
			}

			strong{
				font-style: normal;
				font-weight: 900;
				margin: 0 .2em;
			}
		}
	}
}

.events-section{
	padding-top: 2.6rem;
	padding-bottom: 8.8rem;
	text-align: center;

	@media (max-width: $b3){
		padding-bottom: 5.8rem;
	}

	.container{
		max-width: 177.3rem;
	}

	h4{
		font-weight: 900;
	}

	@media (min-width: $b3 + 1){
	
		.container,
		.col-12{
			padding-right: 3.4rem;
			padding-left: 3.4rem;
		}

		.row{
			margin-right: -3.4rem;
			margin-left: -3.4rem;
		}
	}

	.title-con{
		margin-bottom: 6.3rem;
	}
	
}

.e-item{
	margin-bottom: 4.7rem;

	.inner{
		position: relative;

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;

			&:hover,
			&:focus-visible{
				
				~ .img-con{

					.img{
						transform: translate(-50%, -50%) scale(1.19);
					}
				}
			}
		}
	}

	*{
		color: $blue;
	}

	.img-con{
		overflow: hidden;
		width: 100%;
		aspect-ratio: 1.5432/1;
		position: relative;
		box-shadow: .7rem .7rem 0 $coral;
		margin-bottom: 2.1rem;
	}

	.img{
		aspect-ratio: 1.5432/1;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(1.01);
		transition: $time;
		width: 100%;
	}

	p{
		margin-bottom: 0;
	}
}

.leaderboard-section{
	padding-top: 3.9rem;
	padding-bottom: 11.1rem;

	.container{
		max-width: 156rem;
	}

	.title-con{
		text-align: center;
		margin-bottom: 3.25rem;

		h4{
			opacity: .5;
		}
	}
}

.past-future-section{
	padding-top: 7.1rem;
	padding-bottom: 2.2rem;

	&.past{
		background-image: url(../images/back-grey-alt.jpg);
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: cover;
		background-position: 50% 100%;
	}

	&.future{
		padding-top: 8.05rem;


		*{
			color: $coral;
		}

		.pf-item{
	
			.img-side{
		
				&:before{
					background-color: $coral;
				}
		
				&:after{
					border-color: $coral;
				}
			}

			.bio-side{

				table tbody tr td:first-of-type{
					color: $coral!important;
					border-left-color: rgba($coral, .41)!important;
				}

				table tbody tr td:last-of-type{
					color: rgba($coral, .6)!important;
				}
			}
		}
	}

	.container{
		max-width: 151.9rem;
	}

	.title-con{
		text-align: center;
		margin-bottom: 6.65rem;
	}

	@media (min-width: $b3 + 1){
	
		.container,
		.col-12{
			padding-right: 6.85rem;
			padding-left: 6.85rem;
		}

		.row{
			margin-right: -6.85rem;
			margin-left: -6.85rem;
		}
	}
}

.pf-item{
	margin-bottom: 6.85rem;

	.inner{
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.img-side{
		width: 100%;
		position: relative;
		z-index: 1;
		margin-bottom: 4.85rem;

		&:before{
			content: '';
			position: absolute;
			top: .8rem;
			left: -1.6rem;
			width: calc(100% + .4rem);
			max-width: calc(100% + .4rem);
			height: calc(100% + .7rem);
			background-color: $blue;
			z-index: -1;
		}

		&:after{
			content: '';
			position: absolute;
			width: 99.3%;
			height: 100%;
			top: -1rem;
			left: 1.3rem;
			border: .3rem solid $blue;
		}

		.img-con{
			aspect-ratio: 1.0253/1;
		}
	}

	.bio-side{
		width: 100%;

		h5{
			@include font-size(22);
			@include line-height(22,41);
			font-weight: 600;
			margin-bottom: .65em;
		}

		.btn-con{
			margin-top: -0.2rem;
			padding-right: 2.4rem;
		}

		table{
			margin-bottom: 1.58em!important;
			margin-left: -1.7rem!important;

			html#BTT &{
				width: calc(100% + 1.7rem + 1.7rem)!important;
				max-width: calc(100% + 1.7rem + 1.7rem)!important;
			}

			*{
				text-transform: uppercase;
				font-size: 1.4rem!important;
			}
		}

		table tbody tr:nth-of-type(6) td{
			padding-top: 2.3rem!important;
		}

		table tbody td{
			padding-right: .2rem !important;
			padding-top: .45rem!important;
			padding-bottom: .45rem!important;
		}

		table tbody td:first-of-type{
			padding-left: 1.5rem !important;
			width: 13rem !important;
		}

		table tbody tr:first-of-type td{
			padding-top: .3rem !important;
		}

		table tbody tr:last-of-type td{
			padding-bottom: .1rem !important;
		}

		table tbody td:first-of-type{
			padding-right: 0.2rem !important;
			padding-left: 1.5rem !important;
			width: 13rem !important;
		}

		table tbody tr:nth-of-type(6) td:first-of-type,
		table tbody tr:nth-of-type(7) td:first-of-type{
			width: 20.9rem!important;
		}
	}
}

.c-item{

	.inner{
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.img-side{
		width: 27.8%;
		position: relative;
		z-index: 1;

		@media (max-width: $b3){
			width: 100%;
		}

		&:before{
			content: '';
			position: absolute;
			top: .8rem;
			left: -1.6rem;
			width: calc(100% + .4rem);
			max-width: calc(100% + .4rem);
			height: calc(100% + .7rem);
			background-color: $blue;
			z-index: -1;
		}

		&:after{
			content: '';
			position: absolute;
			width: 99.3%;
			height: 100%;
			top: -1rem;
			left: 1.3rem;
			border: .3rem solid $blue;
		}

		.img-con{
			aspect-ratio: .909/1;
		}
	}

	.bio-side{
		width: 44.3%;
		padding-left: 7rem;
		padding-right: 7rem;

		@media (max-width: $b3){
			width: 100%;
			padding-top: 5rem;
			padding-left: 0rem;
			padding-right: 0rem;
		}

		h6{
			@include font-size(17);
			@include line-height(17,32);
			color: rgba($blue, .56);
			padding-left: 4.55rem;
			width: 100%;

			@media (max-width: $b3){
				@include font-size(14);
				padding-left: 2.5rem;
			}

			p{
				@include font-size(17);
				@include line-height(17,32);
				color: inherit;

				@media (max-width: $b3){
					@include font-size(14);
				}
			}

			&:before{
				background-color: $blue;
				opacity: .41;
				left: .6rem;
			}
		}

		.btn-con{
			margin-top: 2rem;
			padding-left: 4.55rem;

			@media (max-width: $b3){
				padding-left: 2.5rem;
			}

			.btn{
				padding-right: 2.7rem;
				padding-left: 2.7rem;
			}
		}
	}

	h4{
		margin-top: -.8rem;
		margin-bottom: .8em;
	}

	.info-side{
		width: 27.9%;

		@media (max-width: $b3){
			width: 100%;
		}

		h4{
			margin-bottom: 1em;
		}

		img{
			margin-left: 2.8rem!important;
			width: 100%;
			max-width: 24.1rem;
		}

		table{
			margin-bottom: 1.58em!important;

			@media (max-width: $b3){

				html#BTT &{
					width: 100% !important;
				}
				
				*{
					text-transform: uppercase;
					font-size: 1.4rem!important;
				}
			}

			*{
				text-transform: uppercase;
			}
		}

		table tbody tr:nth-of-type(6) td{
			padding-top: 2.3rem!important;
		}

		table tbody td:first-of-type{
			padding-right: .2rem!important;
			padding-left: 2.6rem!important;
			width: 17.4rem!important;

			@media (max-width: $b3){
				padding-right: 0.2rem !important;
				padding-left: 1.5rem !important;
				width: 13rem !important;
			}
		}

		table tbody tr:nth-of-type(6) td:first-of-type,
		table tbody tr:nth-of-type(7) td:first-of-type{
			width: 20.9rem!important;
		}
	}
}

.leader-hero-section{
	padding-top: 35.9rem;
	padding-bottom: 11.1rem;

	@media (max-width: $b3){
		padding-top: 25.9rem;
	}

	.container{
		max-width: 154.8rem;
	}
}

.ct-item{

	.inner{
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.img-side{
		width: 34.8%;
		position: relative;
		z-index: 1;

		@media (min-width: $b3 + 1){
			margin-left: 4.2%;
		}

		@media (max-width: $b3){
			width: 100%;
		}
		
		&:before{
			content: '';
			position: absolute;
			top: .8rem;
			left: -1.6rem;
			width: calc(100% + .4rem);
			max-width: calc(100% + .4rem);
			height: calc(100% + .7rem);
			background-color: $blue;
			z-index: -1;
		}

		&:after{
			content: '';
			position: absolute;
			width: 99.4%;
			height: 99.7%;
			top: -.9rem;
			left: 1.3rem;
			border: .3rem solid $blue;
		}

		.img-con{
			aspect-ratio: 1.0635/1;
		}
	}

	.bio-side{
		width: 61%;
		padding-left: 9.8rem;
		padding-right: 1rem;
		padding-top: 5rem;

		@media (max-width: $b3){
			width: 100%;
			padding-left: 0;
			padding-right: 0;
		}

		h6{
			@include font-size(17);
			@include line-height(17,32);
			color: rgba($blue, .56);
			padding-left: 4.55rem;
			width: 100%;

			@media (max-width: $b3){
				@include font-size(14);
				padding-left: 2.5rem;
			}

			p{
				@include font-size(17);
				@include line-height(17,32);
				color: inherit;

				@media (max-width: $b3){
					@include font-size(14);
				}
			}

			&:before{
				background-color: $blue;
				opacity: .41;
				left: .6rem;
			}
		}

		.btn-con{
			margin-top: 2rem;
			padding-left: 4.55rem;

			.btn{
				padding-right: 2.7rem;
				padding-left: 2.7rem;
			}
		}
	}

	.info-side{
		width: 100%;
		padding-top: 11.1rem;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		@media (min-width: $b3 + 1){
			padding-left: 5.1rem;
		}
		
		@media (max-width: $b3){
			padding-top: 4.1rem;
		}

		h4{
			margin-bottom: 1em;
		}

		img{
			margin-left: 1rem!important;
			width: 100%;
			max-width: 17rem;
		}

		@media (min-width: $b3 + 1){
			
			table{
				margin-bottom: 0em!important;

				+ table{
					display: none!important;
				}
	
	
				html#BTT &{
					width: calc(100% - 25rem)!important;
				}
	
				*{
					text-transform: uppercase;
				}
			}
	
			table tbody td:nth-of-type(2n + 3){
				font-weight: 900!important;
				color: $blue!important;
				font-style: normal!important;
			}
	
			table tbody tr:nth-of-type(6) td{
				padding-top: 2.3rem!important;
			}
	
			table tbody td:first-of-type{
				padding-right: .2rem!important;
				padding-left: 2.2rem!important;
				width: 16.9rem!important;
			}
	
			table tbody tr:nth-of-type(6) td:first-of-type,
			table tbody tr:nth-of-type(7) td:first-of-type{
				width: 20.9rem!important;
			}
		}

		@media (max-width: $b3){
			
			table{
				margin-bottom: 1.58em!important;

				&:has( + table){
					display: none!important;
				}
	
				@media (max-width: $b3){
	
					html#BTT &{
						width: 100% !important;
					}
					
					*{
						text-transform: uppercase;
						font-size: 1.4rem!important;
					}
				}
	
				*{
					text-transform: uppercase;
				}
			}
	
			table tbody tr:nth-of-type(6) td{
				padding-top: 2.3rem!important;
			}
	
			table tbody td:first-of-type{
				padding-right: .2rem!important;
				padding-left: 2.6rem!important;
				width: 17.4rem!important;
	
				@media (max-width: $b3){
					padding-right: 0.2rem !important;
					padding-left: 1.5rem !important;
					width: 13rem !important;
				}
			}
	
			table tbody tr:nth-of-type(6) td:first-of-type,
			table tbody tr:nth-of-type(7) td:first-of-type{
				width: 20.9rem!important;
			}
		}

		
	}
}

.simple-text-section{
	padding-top: 10rem;
	padding-bottom: 10rem;

	@media (max-width: $b3){
		padding-top: 7.5rem;
		padding-bottom: 7.5rem;
	}

	.container{
		max-width: 117rem;
	}

	*{
		color: $blue;
	}

	h3{
		margin-bottom: 1.25em;

		@media (max-width: $b3){
			@include font-size(30);
		}

		@media (max-width: 380px){
			@include font-size(26);
		}

		~ h3{
			margin-bottom: .577em;
		}
	}

	.col-12{

		> p{
			padding-left: 9rem;

			@media (max-width: $b3){
				padding-left: 0rem;
			}
		}

		.p-img{
			padding-left: 0;
			
			img{
				margin-top: 2rem;
				margin-bottom: 2rem;
			}
		}
	}
	

	blockquote{
		text-align: right;
		@include font-size(36);
		@include line-height(36,36);
		padding-right: 9rem;

		@media (max-width: $b3){
			padding-right: 0rem;
			@include font-size(28);
		}

		> p{

			&:first-of-type{

				&:before{
					content: '“';
					width: auto;
				}
			}

			&:last-of-type{

				&:after{
					content: '”';
				}
			}
		}

		h5{
			@include font-size(26);
			color: #91a1b8;
			margin-top: 1.5rem;
			font-weight: 400;
			text-transform: none;

			@media (max-width: $b3){
				@include font-size(24);
			}
		}

		&:before,
		&:after{
			position: static;
			display: inline;
			width: auto;
			height: auto;
			display: none;

		}
	}
}

.related-section{
	padding-bottom: 5rem;

	.title-con{
		text-align: center;
	}
}

.news-archive-section{
	padding-top: 11rem;
	padding-bottom: 11rem;

	@media (max-width: $b3){
		padding-top: 5rem;
		padding-bottom: 5rem;
	}

	.container{
		max-width: 160rem;
	}

	.cats-con{
		text-align: center;
		margin-bottom: 10.5rem;

		@media (max-width: $b3){
			margin-bottom: 4.5rem;
		}

		a{
			text-decoration: none;
			color: rgba($blue, .5);
			text-transform: uppercase;
			margin: 0 .5rem .5rem;

			&.active{
				color: rgba($blue, 1);
			}
		}
	}

	.archives{

		@media (max-width: $b3){
			display: none;
		}

		#sidebar{

			> ul{

				&:first-of-type,
				&:last-of-type{
					display: none;
				}
			}

			[role="navigation"]{

				.pagenav,
				.categories{
					display: none;
				}

				li{
					padding: 0;
					list-style: none;

					&:before{
						display: none;
					}

					h2{
						@include font-size(15);
						color: rgba($blue, .5);
						text-align: right;
						margin-bottom: 2rem;
					}

					li{
						text-align: right;
						text-transform: uppercase;
						@include font-size(26);
						margin-bottom: 2rem;

						a{
							text-decoration: none;
							color: rgba($blue, 1);
						}
					}
				}
			}
		}
	}

	.container-fluid{
		padding: 0;
	}

	// .col-md-3{

	// 	@media (min-width: $b3 + 1){
	// 		flex: 0 0 25%;
	// 		max-width: 25%;
	// 	}
	// }

	// .col-md-9{

	// 	@media (min-width: $b3 + 1){
			
	// 	}
	// }
}

.ns-item,
.n-item{
	margin-bottom: 4rem;

	.inner{
		display: flex;
		flex-wrap: wrap;
		position: relative;

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;

			~ *{
				transition: $time;
			}

			&:hover,
			&:focus-visible{
				
				~ *{
					opacity: .5;
				}
			}
		}

		.img-con{
			width: 41.66666667%;

			@media (max-width: $b3){
				width: 100%;
			}
		}

		.text-con{
			width: 58.33333333%;
			padding: 2.5rem 0;
			padding-left: 3.5rem;
			padding-right: 1.5rem;

			@media (max-width: $b3){
				width: 100%;
			}

			.cat{
				text-transform: uppercase;
				margin-bottom: 0;
				@include font-size(26);
			}

			h4{
				margin-bottom: .6em;
			}

			p{
				color: $blue;
				@include font-size(17);
				letter-spacing: .197em;
				margin-bottom: 2.3em;
				position: relative;
				padding-left: 2rem;

				&::before{
					content: '';
					position: absolute;
					top: .46em;
					bottom: .46em;
					background-color: $blue;
					opacity: .81;
					left: 0;
					width: .3rem;
				}
			}

			.date{

				span{
					@include font-size(15);
					color: rgba($blue, .5);
					display: inline-block;
					margin-right: 3.5rem;
					text-transform: uppercase;

					+ span{
						margin-right: 0;
					}
				}
			}
		}
	}
}

.n-item{

	.inner{

		@media (max-width: $b3){
			
			.img-con{
				width: 100%;
				aspect-ratio: 1.8248/1;
				margin-bottom: 1rem;
			}
		}

		.text-con{

			@media (max-width: $b3){
				padding-left: 0rem;
				padding-right: 0rem;
			}

			h4{

				@media (max-width: $b3){
					@include font-size(34);
				}

				@media (max-width: 380px){
					@include font-size(26);
				}
			}
		}
	}
}

.ns-item{

	.inner{

		.img-con{
			width: 100%;
			aspect-ratio: 1.8248/1;
			margin-bottom: 1rem;
		}

		.text-con{
			width: 100%;
			padding: 0 1.5rem;

			.cat{
				@include font-size(13);
				margin-bottom: 1rem;
			}

			h4{
				@include font-size(22);
			}

			p{
				@include font-size(15);
				padding-left: 1rem;
				margin-bottom: 1.5rem;
			}
		}
	}
}

.wide-image-section{
	height: 45.7rem;
	position: relative;
	overflow: visible!important;
	z-index: 1;
	margin-bottom: 7rem;

	.single-leaders &,
	.post-type-archive-leaders &{
		margin-bottom: 0;
		height: 52rem;

		@media (max-width: $b3){
			height: 32rem;
		}

		&:before{
			display: none;
		}
	}

	&::before{
		content: '';
		position: absolute;
		left: 16.1rem;
		bottom: -11.6rem;
		width: 53.44946rem;
		height: 17.30278rem;
		background-image: url(../images/waves.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: 100% 0;
		z-index: -1;

		@media (max-width: $b3){
			display: none;
		}
	}
}

.latest-coverage-section{
	margin-top: -5.5rem;
	position: relative;
	padding-bottom: 3.1rem;

	@media (max-width: $b3){
		margin-top: 0rem;
		padding-top: 2rem;
	}
	
	&::before{
		content: '';
		position: absolute;
		left: -18.6rem;
		bottom: 0rem;
		width: 53.44946rem;
		height: 17.30278rem;
		background-image: url(../images/waves.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: 100% 0;
		z-index: -1;

		@media (max-width: $b3){
			display: none;
		}
	}


	.container{
		max-width: 161rem;
	}

	@media (min-width: $b3 + 1){
		
		.container,
		.col-12{
			padding-right: 3.1rem;
			padding-left: 3.1rem;
		}

		.row{
			margin-right: -3.1rem;
			margin-left: -3.1rem;
		}
	}

	.title-con{
		margin-bottom: 9.7rem;

		@media (max-width: $b3){
			margin-bottom: 5.7rem;
		}

		h1{
			display: inline-block;
			position: relative;
			color: $coral;
			font-weight: 900;
			letter-spacing: .02em;
			padding-bottom: 1.2rem;

			@media (min-width: $b3 + 1){
				@include font-size(72);
				@include line-height(72,68);
				padding-left: 3.4rem;
			}

			&::after{
				content: '';
				position: absolute;
				right: 0;
				bottom: 0;
				height: .3rem;
				width: 100vw;
				max-width: 100vw;
				background-color: $coral;
			}
		}
	}

	.col-md-3{
		margin-bottom: 6.1rem;
		position: relative;
		z-index: 3;

		&.photos-item{

			.inner{
				position: relative;

				&:after{
					content: '';
					position: absolute;
					width: 100%;
					height: 100%;
					left: .7rem;
					top: .7rem;
					z-index: -2;
					background-color: $coral;
					transition: $time;
				}

				&:has(a:hover),
				&:has(a:focus-visible){

					&:after{
						transform: translate(-2.4%, 3.3%) rotate(4deg);
					}
				}
			}

			a{
				position: relative;
				display: block;
				z-index: 1;

				&::before,
				&::after{
					opacity: 0;
					visibility: hidden;
					transition: $time;
				}

				&::before{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: $coral;
					mix-blend-mode: multiply;
				}

				&::after{
					content: 'SEE MORE';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					@include font-size(13);
					letter-spacing: .197em;
					padding: .4rem 0;
					border-bottom: 1px solid white;
					color: white;
				}

				&:hover,
				&:focus-visible{
					transform: translate(-6.6%, -9.2%) rotate(-3deg);

					&::before,
					&::after{
						opacity: 1;
						visibility: visible;
					}

					~ a{
						transform: translate(6.6%, 0.2%) rotate(5deg);
					}
				}



				img{
					aspect-ratio: 1.5421/1;
					object-fit: cover;
					object-position: 50% 50%;
				}

				~ a{
					position: absolute;
					z-index: -1;
					top: 0;
					left: 0;
					width: 100%;
					pointer-events: none;
					aspect-ratio: 1.5421/1;
					overflow: hidden;
				}
			}
		}
	}
}

.iframe-section{
	padding-top: 3rem;
	padding-bottom: 3rem;

	iframe{
		width: 100%;
		border: none;
	}
}

.press-release-section{
	padding-top: 7.1rem;
	padding-bottom: 11.2rem;

	@media (max-width: $b3){
		padding-top: 5.1rem;
		padding-bottom: 5.2rem;
	}

	.container{
		max-width: 161rem;
	}

	.title-con{
		margin-bottom: 4.4rem;

		h1{
			position: relative;
			color: $coral;
			font-weight: 900;
			letter-spacing: .02em;

			@media (min-width: $b3 + 1){
				@include font-size(72);
				@include line-height(72,68);
			}

			&::after{
				content: '';
				position: absolute;
				right: 0;
				bottom: 0.09em;
				height: .3rem;
				width: 97.1rem;
				background-color: $coral;
			}
		}
	}

	.press-slide{
		width: calc(100% + 1.1rem + 1.1rem);
		max-width: calc(100% + 1.1rem + 1.1rem);
		margin-left: -1.1rem;

		.swiper-wrapper-con{
			overflow: visible!important;
		}
	}

	.swiper-slide{
		padding: 0 1.1rem;
	}

	.ns-item{

		.inner{
			display: block;
			width: 100%;

			.img-con{
				height: 27.3rem;
				aspect-ratio: none;
				margin-bottom: 3.15rem;
				margin-bottom: 3.15rem;
			}

			.text-con{
				padding: 0;

				h4{
					@include font-size(36);
					margin-bottom: .7em;

					@media (max-width: $b3){
						@include font-size(32);
					}
				}

				p{
					color: rgba(black, .48);
					@include font-size(18);
					@include line-height(18,28);
					padding-left: 0;
					letter-spacing: .037em;
					margin-bottom: 2.2em;

					&:before{
						display: none;
					}
				}

				.date{

					span{
						@include font-size(20);
						color: rgba(#707070, .3);
						margin-right: 3rem;
						text-transform: uppercase;

						+ span{
							margin-right: 0;
						}
					}
				}
			}
		}
	}
}







.leg-section{
	padding-top: 9.5rem;
	padding-bottom: 14.8rem;
	background-image: url(../images/back-grey-long.jpg);
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-size: 100% auto;
	position: relative;

	@media (max-width: $b3){
		padding-top: 9.2rem;
		padding-bottom: 5.3rem;
	}

	&::after{
		content: '';
		position: absolute;
		right: -31.3rem;
		background-image: url(../images/leg-ship.svg);
		background-repeat: no-repeat;
		width: 112.6rem;
		height: 81.1rem;
		background-size: 100% 100%;
		top: 9rem;
		pointer-events: none;

		@media (max-width: $b3){
			display: none;
		}
	}

	.container{
		max-width: 153.1rem;
	}

	.row1{
		margin-bottom: -17rem;

		@media (max-width: $b3){
			margin-bottom: 3rem;
		}

		.image-side{

			@media (min-width: $b3 + 1){
				flex: 0 0 49.3%;
				max-width: 49.3%;

				img{
					width: calc(100% + 1.7rem + ((100vw - 153.1rem)/2));
					max-width: calc(100% + 1.7rem + ((100vw - 153.1rem)/2));
					float: right;
				}
			}

			img{
				box-shadow: -2.5rem -2.9rem $blue;
			}
		}

		.text-side{
			padding-top: 3.2rem;

			@media (max-width: $b3){
				padding-top: 2rem;
			}

			@media (min-width: $b3 + 1){
				flex: 0 0 50.7%;
				max-width: 50.7%;
				padding-left: 6.1rem;
			}

			@media (min-width: $b3 + 1){
				
				&.waypoint .inner:after{
					max-height: 0;
				}
				
				&.animate .inner:after{
					max-height: 72.0rem;
					transition: $time*2 $time*2;
				}
			}

			table{
				margin-bottom: 1.58em!important;
	
				html#BTT &{
					width: 100%!important;
					max-width: 100%!important;
				}
	
				*{
					text-transform: uppercase;
					font-size: 3.4rem!important;

					@media (max-width: $b3){
						font-size: 1.8rem!important;
					}
				}
			}
	
			table tbody tr:nth-of-type(6) td{
				padding-top: 2.3rem!important;
			}
	
			table tbody td{
				padding-right: .2rem !important;
				padding-top: .65rem!important;
				padding-bottom: .65rem!important;
			}
		
			table tbody tr:first-of-type td{
				padding-top: .0rem !important;
			}
	
			table tbody tr:last-of-type td{
				padding-bottom: .0rem !important;
			}
	
			table tbody td:first-of-type{
				padding-right: 0.2rem !important;
				padding-left: 0rem !important;
				width: 31.5rem !important;
				border-left: none!important;
			}
	
			table tbody tr:nth-of-type(6) td:first-of-type,
			table tbody tr:nth-of-type(7) td:first-of-type{
				width: 20.9rem!important;
			}

			.inner{
				position: relative;
				z-index: 1;
			}

			p{
				margin-bottom: 1.6em;
				
				&:first-of-type{
					opacity: .39;
					letter-spacing: .197em;
					margin-bottom: .6em;
				}
			}
			
			hr{
				margin: 1.3rem 0 2.2rem;
				width: 47%;
				background-color: $blue;
		
				@media (min-width: $b3 + 1){
					width: calc(76% + 12.5rem + 2.75rem);
					max-width: calc(76% + 12.5rem + 2.75rem);
					margin-left: -12.5rem - 2.75rem;

					&:has(+ h5){
						width: calc(83.8% + 12.5rem + 2.75rem);
						max-width: calc(83.8% + 12.5rem + 2.75rem);
						margin-bottom: 2.2rem;
					}
				}
			}

			.btn-con{
				margin-top: 4rem;
		
				.btn{
					font-weight: 400;
					font-style: italic;
					text-transform: uppercase;
					padding: 1.4rem 2.7rem;
					flex-wrap: wrap;
		
					@media (min-width: $b3 + 1){
						font-size: 3.3rem !important;
					}

					@media (max-width: $b3){
						font-size: 1.9rem !important;
						padding: 1.4rem 1.4rem;
					}
		
					strong{
						font-style: normal;
						font-weight: 900;
						margin: 0 .2em;
					}
				}
			}
		}

		h1{

			em{
				font-weight: 400;
				letter-spacing: .008em;
			}
		}
	}

	.row2{
		align-items: flex-start;

		@media (max-width: $b3){
			margin-bottom: 3rem;
		}

		.image-side{

			@media (max-width: $b3){
				order: 1;
				margin-bottom: 1rem;
			}
			
			img{
				box-shadow: 2.8rem 3.1rem 0 $coral;

				@media (max-width: $b3){
					box-shadow: 1.8rem 2.1rem 0 $coral;
				}
			}

			@media (min-width: $b3 + 1){
				flex: 0 0 48.6%;
				max-width: 48.6%;

				img{
					width: calc(100% + 15.4rem);
					max-width: calc(100% + 15.4rem);
				}
			}
		}

		.text-side{
			text-align: right;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;
			align-items: flex-start;
			
			@media (min-width: $b3 + 1){
				flex: 0 0 51.4%;
				max-width: 51.4%;
				padding-left: 11.2rem;
				padding-top: 34.4rem;
			}

			@media (max-width: $b3){
				order: 2;
			}

			@media (min-width: $b3 + 1){
				
				&.waypoint .inner:after{
					max-height: 0;
				}
				
				&.animate .inner:after{
					max-height: 57.5rem;
					transition: $time*2 $time*2;
				}
			}

			.inner{
				width: 64.7rem;
				background-color: white;
				padding: 5.2rem 8.4rem 4.2rem 8rem;
				position: relative;
				z-index: 1;

				@media (min-width: $b3 + 1){
					
					> h4:first-child{
						position: absolute;
						bottom: calc(100% + 2.7rem);
						right: 8.4rem;
					}
				}

				@media (max-width: $b3){
					padding: 3.7rem 2rem 3.7rem 2rem;
				}
				
				&::before{
					content: '';
					position: absolute;
					top: 1.4rem;
					right: 100%;
					bottom: 1.4rem;
					width: 2.4rem;
					background-color: $blue;

					@media (max-width: $b3){
						width: 1.8rem;
					}
				}
				
				@media (min-width: $b3 + 1){
					margin-right: -5.5rem;
				}
			}

			.btn-con{
				margin-top: 3.9rem;
			
				@media (min-width: $b3 + 1){
					margin-right: 1.6rem;
				}
		
				.btn{
					font-weight: 400;
					font-style: italic;
					text-transform: uppercase;
					padding: 1.4rem 2.7rem;
					flex-wrap: wrap;
		
					@media (min-width: $b3 + 1){
						font-size: 3.3rem !important;
					}

					@media (max-width: $b3){
						font-size: 1.9rem !important;
						padding: 1.4rem 1.4rem;
					}
		
					strong{
						font-style: normal;
						font-weight: 900;
						margin: 0 .2em;
					}
				}
			}
		}
	}

	.image-side{
		position: relative;
		z-index: 1;
	}	

	h1{
		@include font-size(72);
		@include line-height(72,72);
		letter-spacing: .02em;
		margin-bottom: .3em;

		@media (max-width: $b3){
			@include font-size(40);
		}
	}

	h5{
		@include font-size(32);
		@include line-height(32,48);
		letter-spacing: .02em;
	}
}

.competition-entry-section{
	position: relative;
	padding-top: 7.7rem;
	padding-bottom: 12.1rem;
	overflow: visible!important;

	&::after{
		content: '';
		position: absolute;
		left: -7.3rem;
		background-image: url(../images/leg-ship-other.svg);
		background-repeat: no-repeat;
		width: 112.6rem;
		height: 81.1rem;
		background-size: 100% 100%;
		top: 50%;
		transform: translateY(-50%);
		pointer-events: none;

		@media (max-width: $b3){
			display: none;
		}
	}

	.title-side{
		text-align: right;

		@media (min-width: $b3 + 1){
			flex: 0 0 43.7%;
			max-width: 43.7%;
		}

		h2{
			letter-spacing: .02em;
		}
	}

	.form-side{

		@media (min-width: $b3 + 1){
			flex: 0 0 56.3%;
			max-width: 56.3%;
			padding-left: 7.4rem;
			padding-right: 23.3rem;

			legend,
			label{
				width: 17.8rem;
			}

			.wpcf7-form-control-wrap{
				width: calc(100% - 17.8rem - 3.62rem);
				max-width: calc(100% - 17.8rem - 3.62rem);

				&:has([type="file"]){
					margin-left: 17.8rem + 3.62rem;
					position: relative;

					span{
						position: absolute;
						top: 100%;
						left: 0;
						width: 100%;

						+ span{
							display: none;
						}
					}
				}
			}
		}

		.wpcf7-form-control-wrap{

			&:has([type="file"]){
				position: relative;
				margin-top: 2.4rem;

				&::before{
					content: 'UPLOAD YOUR ENTRY HERE';
					position: absolute;
					top: 0;
					left: 0;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					font-size: 1.7rem!important;
					line-height: 1.2;
					font-weight: 700;
					color: $blue!important;
					text-decoration: none!important;
					font-family: $main-font;
					text-decoration: none;
					text-align: center;
					padding: 1.35rem 1.6rem;
					background-color: transparent;
					border: 1.1rem solid $blue;
					border-radius: 0;
					box-shadow: none;
					cursor: pointer;
					transition: $time;
					background-color: white;
					font-size: 2.4rem!important;
					padding: 1.45rem 2.1rem;
					pointer-events: none;
				}

				[type="file"]{
					height: 7.878rem;
					opacity: 0;
				}
			}

			&:has([type="file"]:hover),
			&:has([type="file"]:focus-visible){

				&::before{
					outline: none;
					background-color: $blue;
					border-color: $blue;
					color: $white!important;
			
					*{
						color: $white!important;
					}
				}
			}
		}
	}
}


.shop-section{
	padding-top: 28.8rem;
	padding-bottom: 4.8rem;

	@media (max-width: $b3){
		padding-top: 20.9rem;
	}

	.container{
		max-width: 158.3rem;
	}

	@media (min-width: $b3 + 1){
		
		.container,
		.col-12{
			padding-right: 5.1rem;
			padding-left: 5.1rem;
		}

		.row{
			margin-right: -5.1rem;
			margin-left: -5.1rem;
		}
	}

	.col-md-3{
		
		@media (min-width: $b3 + 1){
			flex: 0 0 21.37%;
			max-width: 21.37%;
		}
	}

	.col-md-9{
		
		@media (min-width: $b3 + 1){
			flex: 0 0 78.63%;
			max-width: 78.63%;
		}
	}

	.showing-top{

		@media (max-width: $b3){
			text-align: center;
			margin-bottom: 3rem;
		}
	}

	.filter-top{
		@include font-size(22);
		display: flex;
		justify-content: center;
		padding-top: 0.6rem;

		@media (max-width: $b3){
			@include font-size(19);
		}

		.order-by{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
		
		span,
		a{
			margin: 0 1rem .5rem;
			color: $blue;
			letter-spacing: .02em;

			@media (max-width: $b3){
				margin: 0 .5rem .5rem;
			}
		}

		span{
			font-weight: 700;
		}

		a{
			font-style: italic;
			opacity: .24;
			text-decoration: none;

			&:hover,
			&:focus-visible{
				opacity: .4;
			}

			&.active{
				opacity: .6;
			}
		}
	}

	.filter-side.filter-side.filter-side{
		padding-top: 5.2rem;

		h5{
			@include font-size(22);
			@include line-height(22,25);
			letter-spacing: .02em;
			margin-bottom: 1.4em;

			@media (max-width: $b3){
				@include font-size(19);
				padding: 0 3rem 0 0;
				cursor: pointer;
				position: relative;

				&:after{
					content: '';
					position: absolute;
					background-image: url(../images/accordion-arrow.svg);
					background-repeat: no-repeat;
					top: 50%;
					right: 0rem;
					transform: translateY(-50%) rotate(0deg);
					transition: $time;
					width: 1.3965rem;
					height: .7982rem;
					background-size: 100% auto;
				}

				+ .yith-wcan-filters{
					max-height: 0;
					overflow: hidden;
				}

				&.open{

					&:after{
						transform: translateY(-50%) rotate(180deg);
					}

					+ .yith-wcan-filters{
						max-height: 999px;
					}
				}
			}
		}

		.filter-title{
			@include font-size(22);
			@include line-height(22,25);
			letter-spacing: .02em;
			font-style: italic;
			font-weight: 500;
			color: $blue;
			margin-bottom: 1.2em;

			@media (max-width: $b3){
				@include font-size(19);
			}
		}

		[data-filter-type="price_range"]{

			.filter-items{

				.filter-item{

					label{
						margin: 0;
						width: 100%;
						text-align: left;

						[type="checkbox"]:checked{

							+ a{

								&:after{
									opacity: 1;
								}
							}
						}

						a{
							display: block;
							position: relative;
							padding-left: 2.4rem;
							@include font-size(17);
							letter-spacing: .02em;
							text-decoration: none;
							color: $blue;
							line-height: 1.6;
							text-align: left;
							
							&:before{
								position: absolute;
								content: "";
								width: 1.2rem;
								height: 1.2rem;
								left: 0;
								top: .7rem;
								border-radius: 0;
								border: .1rem solid $blue;
							}
					
							&:after{
								position: absolute;
								content: "";
								width: .8rem;
								height: .8rem;
								left: .2rem;
								top: .9rem;
								border-radius: 0;
								background-color: $blue;
								opacity: 0;
								transition: $time;
							}

							*{
								@include font-size(17);
								letter-spacing: .02em;
								text-decoration: none;
								color: $blue;
								line-height: 1.6;
							}
						}
					}
				}
			}
		}

		.filter-items.filter-checkbox{
			margin-bottom: 3.9rem;

			label{
				margin: 0;
				width: 100%;
			}

			li{

				&.active{

					a{

						&:after{
							opacity: 1;
						}
					}
				}
			}

			a{
				display: block;
				position: relative;
				padding-left: 2.4rem;
				@include font-size(17);
				letter-spacing: .02em;
				text-decoration: none;
				color: $blue;
				line-height: 1.6;
				text-align: left;

				span{
					display: none!important;
				}
				
				&:before{
					position: absolute;
					content: "";
					width: 1.2rem;
					height: 1.2rem;
					left: 0;
					top: .7rem;
					border-radius: 0;
					border: .1rem solid $blue;
				}
		
				&:after{
					position: absolute;
					content: "";
					width: .8rem;
					height: .8rem;
					left: .2rem;
					top: .9rem;
					border-radius: 0;
					background-color: $blue;
					opacity: 0;
					transition: $time;
				}
			}
		}
	}


	.shop-side{
		padding-top: .7rem;

		.container-fluid{
			padding: 0;
		}
	}
}

.product-normal{
	margin-bottom: 7.75rem;

	&.featured{
		margin-bottom: 7.05rem;

		.inner{

			.img-con{
				aspect-ratio: 1.106/1;
	
				&:before{
					background-color: $coral;
				}

				&:after{
					border-color: $coral;
				}
			}

			h5{
				color: $coral;

				*{
					color: $coral;
				}
			}

			p{
				color: rgba($coral, .6);
			}

			.prices{
				color: $coral;

				*{
					color: $coral;
				}
			}
		}
	}

	.inner{
		position: relative;

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
		}

		.img-con{
			position: relative;
			aspect-ratio: 1.3206/1;
			margin-bottom: 4.6rem;

			&:before{
				content: '';
				position: absolute;
				top: .9rem;
				left: -1.7rem;
				width: calc(100% + .6rem);
				max-width: calc(100% + .6rem);
				height: calc(100% + .6rem);
				background-color: $blue;
				z-index: -1;
			}

			&:after{
				content: '';
				position: absolute;
				width: 100%;
				height: 99.8%;
				top: -.9rem;
				left: 1.2rem;
				border: .3rem solid $blue;
			}
		}

		h5{
			@include font-size(22);
			font-weight: 600;
			font-style: normal;
			letter-spacing: .02em;
			margin-bottom: 0.1em;

			@media (max-width: $b3){
				@include font-size(19);
			}

			*{
				@include font-size(22);
				font-weight: 600;
				font-style: normal;
				letter-spacing: .02em;

				@media (max-width: $b3){
					@include font-size(19);
				}
			}
		}

		p{
			@include font-size(14);
			@include line-height(14,26);
			color: rgba($blue, .6);
			font-style: italic;
			margin-bottom: 0.5em;
			font-weight: 500;
			letter-spacing: .02em;
		}

		.prices{
			@include font-size(14);
			line-height: 1.2;
			color: $blue;
			font-weight: 600;
			letter-spacing: .02em;

			*{
				@include font-size(14);
				line-height: 1.2;
				color: $blue;
				font-weight: 600;
				letter-spacing: .02em;
			}

			.inc.none-sale{
				opacity: .5;
				text-decoration: line-through;
			}
		}
	}
}

.featured-products-section{
	padding-top: 14.8rem;

	@media (max-width: $b3){
		padding-top: 5.8rem;
	}

	.single-product &{
		padding-top: 1.7rem;
	}

	.container{
		max-width: 162.4rem;
	}

	@media (min-width: $b3 + 1){
		
		.container,
		.col-12{
			padding-right: 4.65rem;
			padding-left: 4.65rem;
		}

		.row{
			margin-right: -4.65rem;
			margin-left: -4.65rem;
		}
	}

	.row{
		justify-content: center;
	}

	.title-con{
		margin-bottom: 5.9rem;

		*{
			color: $coral;
			text-align: center;
		}
	}
}

.single-product{

	.woocommerce-breadcrumb{
		display: none;
	}

	.main-wrapper{
		padding-top: 30.9rem;

		@media (max-width: $b3){
			padding-top: 20.9rem;
		}
	}
}

.product-single-section{

	.container{
		max-width: 142.1rem;
	}

	.image-side{
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;

		@media (min-width: $b3 + 1){
			flex: 0 0 54.25%;
			max-width: 54.25%;
		}

		h4{
			width: 100%;

			@media (min-width: $b3 + 1){
				display: none;
			}
		}

		.nav-product-slide{
			width: 10.4rem;
			margin-right: 2.8rem;
			margin-top: 2rem;

			@media (max-width: $b3){
				max-height: 0!important;
				overflow: hidden!important;
			}

			.swiper-wrapper{
				flex-direction: column;
			}
	
			.swiper-slide{
				padding-top: .2rem;
				padding-right: .2rem;
				height: 12.9rem!important;
				margin-bottom: 1.1rem;

				&.swiper-slide-thumb-active{

					.img-con{

						&::before{
							border-color: $coral;
						}
					}
				}

				.img-con{
					position: relative;
					cursor: pointer;

					&::before{
						content: '';
						position: absolute;
						top: -.2rem;
						right: -.2rem;
						width: 100%;
						height: 100%;
						border: .3rem solid $blue;
					}
				}

				img{
					aspect-ratio: .8425/1;
					object-fit: cover;
					object-position: 50% 50%;
				}
			}
		}
	
		.main-product-slide{
			width: calc(100% - 2.8rem - 10.4rem);

			@media (max-width: $b3){
				width: 100%;
				margin-bottom: 1.5rem;
			}

			.swiper-slide{
				padding: 1.1rem 1.1rem 1rem 1.9rem;
			}

			.img-con{
				position: relative;
				box-shadow: -1.9rem 1rem 0 $blue;

				&::before{
					content: '';
					position: absolute;
					top: -1.1rem;
					right: -1.1rem;
					width: 100%;
					height: 100%;
					border: .3rem solid $blue;
				}
			}

			.swiper-button-next, 
			.swiper-button-prev{
				top: auto;
				bottom: 3.3rem;
				width: 2.2rem;
				height: 2.2rem;
			}

			.swiper-button-next{
				right: 3.3rem;
			}

			.swiper-button-prev{
				right: 7.8rem;
				left: auto;
			}
		}
	}

	.info-side{
		padding-top: .2em;

		@media (min-width: $b3 + 1){
			flex: 0 0 45.75%;
			max-width: 45.75%;
			padding-left: 3.2rem;
		}

		.product_meta{
			display: none;
		}

		h4{
	
			@media (max-width: $b3){
				display: none;
			}
		}
	}

	h4{
		font-weight: 700;
		margin-bottom: .095em;

		+ p.excerpt{
			color: rgba($blue, .6);
			font-weight: 500;
			font-style: italic;
			@include line-height(19,26);
			margin-bottom: 1em;
		}
	}

	.prices{
		@include font-size(30);
		@include line-height(30,41);
		color: $blue;
		font-weight: 500;
		letter-spacing: .02em;
		margin-bottom: 0.75em;

		*{
			@include font-size(30);
			@include line-height(30,41);
			color: $blue;
			font-weight: 500;
			letter-spacing: .02em;
		}

		.inc.none-sale{
			opacity: .5;
			text-decoration: line-through;
		}
	}

	.woocommerce-product-details__short-description{
		@include font-size(14);
		@include line-height(14,26);
		font-weight: 500;
		padding-left: 4.65rem;
		position: relative;
		color: $blue;
		letter-spacing: .197em;
		text-transform: uppercase;
		opacity: .56;
		margin-bottom: 2.9em;
		text-transform: uppercase;

		&::before{
			content: '';
			position: absolute;
			top: .46em;
			bottom: .16em;
			background-color: $blue;
			left: .7rem;
			width: .3rem;
			opacity: .9;
		}

		.cats{
			margin-top: 2.6rem;

			span{

				&:has( + span){
					content: ', ';
				}
			}
		}
	}

	table.variations.variations.variations.variations.variations{
		
		html#BTT &{
			width: 100%!important;
			margin-bottom: 0;
		}

		tbody{

			tr{
				background-color: transparent!important;

				&:first-of-type{

					select{
						border-top: 2px solid rgba($blue, .22)!important;
						line-height: calc(6.817rem - 4px);
					}
				}
			}

			.label{
				display: none;
			}

			.value{
				padding: 0rem !important;
				display: block;
				border: 0!important;
				width: 100%;
			}

			select{
				margin: 0;
				@include font-size(23);
				line-height: calc(6.817rem - 2px);
				height: 6.817rem;
				border-bottom: 2px solid rgba($blue, .22);
				color: $blue;
				background-image: url(../images/select-icon-alt.svg);
				padding-left: 3.2rem;
				background-size: 2.95rem auto;
				padding-left: 0;
			}
		}
	}

	.quantity.quantity.quantity.quantity.quantity.quantity{
		float: none;
		width: 100%;
		margin: 0 0 3rem;
		padding: 0 !important;

		.qty{
			border-bottom: 2px solid rgba($blue, .22);
			width: 100%;
			text-align: left;
			@include font-size(23);
			color: $blue;
			line-height: calc(6.817rem - 2px);
			height: 6.817rem;
		}
	}

	.variations_form.cart{
		padding: 0 0 0 .9rem !important;
	}
}

.description-section{
	padding-top: 3.2rem;
	padding-bottom: 3.2rem;

	.container{
		max-width: 142.1rem;
	}
}

dl.accordion{
	border-top: 2px solid rgba($blue, .22);

	dt{
		padding: 2.6rem 6rem 2.4rem 0;
		color: $blue;
		@include font-size(23);
		letter-spacing: .02em;
		font-weight: 500;
		cursor: pointer;
		position: relative;

		&:after{
			content: '';
			position: absolute;
			background-image: url(../images/accordion-arrow.svg);
			background-repeat: no-repeat;
			top: 50%;
			right: 3.85352rem;
			transform: translateY(-50%) rotate(0deg);
			transition: $time;
			width: 1.3965rem;
			height: .7982rem;
			background-size: 100% auto;
		}

		&.open{

			&:after{
				transform: translateY(-50%) rotate(180deg);
			}
		}
	}

	dd{
		border-bottom: 2px solid rgba($blue, .22);
		margin: 0;
		max-height: 0;
		overflow: hidden;
		transition: $time;

		.inner{
			padding: 0.2rem 9.4rem 4.1rem 3.9rem;

			ol,
			ul,
			p,
			li{
				@include font-size(14);
				@include line-height(14,26);
				letter-spacing: .197em;
				color: rgba($blue, .56);
				font-weight: 500;
				text-transform: uppercase;
			}

			ol,
			ul,
			p{
				margin-bottom: 1.9em;
			}
		}
	}
}

.related-products-section{
	padding-top: 10.8rem;
	padding-bottom: 3.2rem;

	.container{
		max-width: 153.1rem;
	}

	@media (min-width: $b3 + 1){
		
		.container,
		.col-12{
			padding-right: 5.1rem;
			padding-left: 5.1rem;
		}

		.row{
			margin-right: -5.1rem;
			margin-left: -5.1rem;
		}

		.title-con{
			flex: 0 0 18.7%;
			max-width: 18.7%;
			padding-right: 4.3rem;
			padding-top: 0.9rem;
		
			h3{
				width: 163%;
				max-width: 163%;
				@include font-size(57);
				@include line-height(57,56);
				letter-spacing: .05em;
			}
		}

		.product-normal{
			flex: 0 0 27.1%;
			max-width: 27.1%;
		}
	}

	.title-con{
		
		h3{
			text-align: right;
			float: right;
			font-weight: 700;
		}
	}

	.product-normal{

		&:nth-of-type(1n + 5){
			display: none;
		}
	}
}

.latest-from-the-voyage-section{

	.container{
		max-width: 134.6rem;
	}

	@media (min-width: $b3 + 1){
		
		.container,
		.col-12{
			padding-right: .8rem;
			padding-left: .8rem;
		}

		.row{
			margin-right: -.8rem;
			margin-left: -.8rem;
		}
	}

	.row{
		padding-top: 16.8rem;
		padding-bottom: 14rem;

		@media (max-width: $b3){
			padding-top: 6.8rem;
			padding-bottom: 4rem;
		}
	}

	.title-con{
		margin-bottom: 16rem;
	}

	h1{
		color: $coral;
		margin-left: auto;
		margin-right: auto;
		width: 116rem;
		padding-left: 5rem;
		padding-right: 14rem;
		letter-spacing: .015em;
		padding-bottom: 0.3rem;
		background-image: url(../images/coral-ship.svg);
		background-repeat: no-repeat;
		background-position: 100% 100%;
		background-size: auto 9.35rem;

		@media (min-width: $b3 + 1){
			@include font-size(72);

		}
	}

	.lf-item{
		margin-bottom: 6.1rem;

		.inner{
			position: relative;

			a{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;

				~ *{
					transition: $time;
				}

				&:hover,
				&:focus-visible{

					~ *{
						opacity: .5;
					}
				}
			}
		}

		.text{
			padding: 0 3.8rem 1.4rem;
		}

		.img-con{
			width: 100%;
			aspect-ratio: 437/298;
		}

		h4{
			margin-bottom: .3em;
		}

		p{
			@include line-height(19,23);
			width: 27rem;
		}

		.link{
			@include font-size(34);
			font-weight: 700;
			background-color: $blue;
			padding: 1.8rem 4.5rem;
			letter-spacing: .03em;
			color: $white;
		}
	}
}

.follow-us-here-section{
	padding-top: 5rem;
	padding-bottom: 3.8rem;
	background-image: url(../images/back-blue.jpg);
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position: 50% 100%;
	margin-bottom: 24.3rem;

	&.alt{
		background-image: none;
		background-color: $green;
		position: relative;
		z-index: 2;

		*{
			color: $green-light;
		}

		.image-side{

			.img-con{

				&:before{
					background-color: $green-light;
				}

				&:after{
					border-color: $green-light;
				}
			}
		}

		.text-side{

			.inner{
				
				&:before{
					background-image: url(../images/green-line-shape.svg);
				}
			}
		}
	}

	@media (min-width: $b3 + 1){
		overflow: visible!important;
	}

	@media (max-width: $b3){
		margin-bottom: 0rem;
	}

	.container{
		max-width: 138.3rem;
	}

	.row{
		align-items: center;
	}

	.text-side{
		text-align: right;
		display: flex;
		justify-content: flex-end;

		@media (min-width: $b3 + 1){
			flex: 0 0 57.35%;
			max-width: 57.35%;
		}

		h3{
			text-align: center;

			@media (min-width: 380px + 1){
				@include font-size(50);
			}
		}

		.inner{
			width: 51rem;
			padding-top: 5.1rem;
			position: relative;

			@media (min-width: $b3 + 1){
				padding-right: 3rem;
			}

			&:before{
				content: '';
				position: absolute;
				top: 39.4%;
				left: -33.9rem;
				width: 51.25838rem;
				height: 39.15132rem;
				background-image: url(../images/blue-line-shape.svg);
				background-repeat: no-repeat;
				transform: translateY(-50%) rotate(-.7deg);
				background-size: 100% 100%;
				pointer-events: none;

				@media (max-width: $b3){
					display: none;
				}
			}
		}

		.btn-con{

			@media (max-width: $b3){
				justify-content: center;
			}

			@media (min-width: $b3 + 1){
				padding-right: 3rem;
			}
		}

		.social-menu{
			justify-content: center;
			margin-top: 3.1rem;
			margin-bottom: 3.1rem;

			a{
				@include font-size(43);
				line-height: 43/10 + rem;
				height: 43/10 + rem;
				color: $coral;
				margin-right: 3.8rem;

				@media (max-width: $b3){
					@include font-size(34);
					line-height: 34/10 + rem;
					height: 34/10 + rem;
				}

				&:hover,
				&:focus-visible{
					color: $white;

					i{
						color: $white!important;
					}
				}

				&:last-of-type{
					margin-right: 0rem;
				}

				i{
					@include font-size(43);
					line-height: 43/10 + rem;
					height: 43/10 + rem;

					@media (max-width: $b3){
						@include font-size(34);
						line-height: 34/10 + rem;
						height: 34/10 + rem;
					}
				}
			}
		}
	}

	.image-side{

		@media (min-width: $b3 + 1){
			flex: 0 0 42.65%;
			max-width: 42.65%;
			padding-left: 4.8rem;
		}

		.img-con{
			position: relative;
			margin-top: -9rem;
			margin-bottom: -9rem;
			z-index: 1;

			@media (max-width: $b3){
				margin-top: 3rem;
				margin-bottom: 3rem;
			}

			&:before{
				content: '';
				position: absolute;
				top: 2.3rem;
				left: 2.3rem;
				width: calc(100% + 1rem);
				max-width: calc(100% + 1rem);
				height: calc(100% + 1.2rem);
				background-color: $blue;
				z-index: -1;
			}

			&:after{
				content: '';
				position: absolute;
				width: 99.7%;
				height: 99.6%;
				top: -1.2rem;
				left: -1.4rem;
				border: .3rem solid $blue;
			}
		}
	}
}

.wide-image-section{

	&:has(+ .darwin200-events-section),
	&:has(+ .special-projects-section),
	&:has(+ .photo-gallery-section){
		margin-bottom: 0;
	}
}

.f-thumbs__track{
	min-width: 100%;
}

.photo-gallery-section{
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position: 50% 100%;
	background-image: url(../images/back-grey-team.jpg);
	position: relative;

	&::before{
		content: '';
		position: absolute;
		right: 0;
		top: 58rem;
		width: 53.44946rem;
		height: 17.30278rem;
		background-image: url(../images/waves.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: 100% 0;

		@media (max-width: $b3){
			display: none;
		}
	}

	&::after{
		content: '';
		position: absolute;
		left: -34.7rem;
		bottom: 38.3rem;
		width: 53.44946rem;
		height: 17.30278rem;
		background-image: url(../images/waves.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: 100% 0;

		@media (max-width: $b3){
			display: none;
		}
	}

	.container{
		max-width: 159rem;
	}

	.row{
		padding-top: 30.5rem;
		padding-bottom: 19rem;

		@media (max-width: $b3){
			padding-top: 10.5rem;
			padding-bottom: 8rem;
		}
	}

	h1{
		@include line-height(72,68);
		display: inline-block;
		position: relative;

		@media (min-width: $b3 + 1){
			padding-left: 2.4rem;
		}
		
		&:after{
			content: '';
			position: absolute;
			bottom: .08em;
			right: 1.3em;
			width: 100vw;
			max-width: 100vw;
			height: 0.042em;
			background-color: $blue;
		}

		@media (min-width: $b3 + 1){
			@include font-size(72);
		}
	}

	.title-con{
		margin-bottom: 7.7rem;

		@media (max-width: $b3){
			margin-bottom: 4.7rem;
		}
	}

	.order-con{
		text-align: center;
		margin-bottom: 9.7rem;
		
		@media (max-width: $b3){
			margin-bottom: 5.7rem;
		}

		span,
		a{
			color: $blue;
			display: inline-block;
			margin: 0 1rem 1rem;
		}

		span{
			font-weight: 700;
			letter-spacing: .197em;
		}

		a{
			font-style: italic;
			font-weight: 400;
			letter-spacing: .197em;
			opacity: .39;
			text-decoration: none;

			&:hover,
			&:focus-visible{
				opacity: .8;
			}

			&.active{
				opacity: 1;
				pointer-events: none;
			}
		}
	}

	.ph-item{
		margin-bottom: 2.8rem;
		position: relative;
		z-index: 2;

		.inner{
			width: 100%;
			position: relative;

			a{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;

				~ *{
					transition: $time;
				}

				&:hover,
				&:focus-visible{
					
					~ *{
						opacity: .5;
					}
				}
			}
		}

		.img-con{
			aspect-ratio: 341/221;
			background-size: cover;
			background-position: 50% 50%;
			width: 99%;
			margin-bottom: 2.8rem;
			box-shadow: .5rem .7rem 0 $blue-light;
		}

		p{
			@include line-height(18,28);
			transform: translateX(.7rem);
			letter-spacing: .037em;
			color: rgba($black, .98);

			@media (min-width: $b3 + 1){
				@include font-size(18);
			}
		}
	}
}

.special-projects-section{
	background-attachment: fixed;
	background-size: cover;
	background-position: 50% 100%;
	background-image: url(../images/back-grey-team.jpg);
	position: relative;

	&::before{
		content: '';
		position: absolute;
		right: -31.3rem;
		background-image: url(../images/leg-ship.svg);
		background-repeat: no-repeat;
		width: 127.9rem;
		height: 81.1rem;
		background-size: 100% 100%;
		top: -1.4rem;
		pointer-events: none;

		@media (max-width: $b3){
			display: none;
		}
	}

	&::after{
		content: '';
		position: absolute;
		left: -15rem;
		bottom: 98.5rem;
		width: 53.44946rem;
		height: 17.30278rem;
		background-image: url(../images/waves.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: 100% 0;

		@media (max-width: $b3){
			display: none;
		}
	}

	.container{
		max-width: 134.2rem;
	}

	.row{
		padding-top: 26.4rem;
		padding-bottom: 31.3rem;

		@media (max-width: $b3){
			padding-top: 9.4rem;
			padding-bottom: 8.3rem;
		}
	}

	.title-con{
		text-align: center;
		margin-bottom: 13.7rem;

		@media (max-width: $b3){
			margin-bottom: 10rem;
		}
	}

	h1{

		@media (min-width: $b3 + 1){
			@include font-size(72);
		}
	}
}

.sp-item{
	margin-bottom: 6.3rem;
	position: relative;
	z-index: 2;
	
	&:nth-of-type(4n + 2){

		.inner{

			.text-side{

				@media (min-width: $b3 + 1){
					padding-right: 7.2rem;
				}

				h4{
					padding-right: 8rem;
					background-image: url(../images/ship-line-right.svg);
					background-position: 100% 100%;
				}

				p{

					@media (min-width: $b3 + 1){
						padding-right: 4rem;
					}
				}

				.btn-con{
					justify-content: left;
				}
			}
		}
	}

	&:nth-of-type(4n + 3){

		.inner{
			background-color: $green-light;

			.img-side{
				padding-left: 0;
				padding-right: 1.5rem;

				@media (min-width: $b3 + 1){
					order: 2;
				}
			}

			.img-con{

				&:before{
					left: auto;
					right: -1.5rem;
				}

				&:after{
					border-color: $green-light;
					left: auto;
					right: 1.1rem;
				}
			}

			.text-side{
				text-align: right;

				@media (min-width: $b3 + 1){
					padding-left: 7.2rem;
				}

				h4{
					padding-left: 8rem;
					background-image: url(../images/ship-line-left.svg);
					background-position: 0% 100%;

					@media (min-width: $b3 + 1){
						padding-right: 6.5rem;
					}
				}

				p{

					@media (min-width: $b3 + 1){
						padding-left: 4rem;
					}
				}

				.btn-con{
					justify-content: right;
				}
			}
		}
	}

	&:nth-of-type(4n + 4){

		.inner{
			background-color: $blue-light;

			.text-side{

				@media (min-width: $b3 + 1){
					padding-right: 7.2rem;
				}

				h4{
					padding-right: 8rem;
					background-image: url(../images/ship-line-right.svg);
					background-position: 100% 100%;
				}

				p{

					@media (min-width: $b3 + 1){
						padding-right: 4rem;
					}
				}

				.btn-con{
					justify-content: left;
				}
			}

			.img-con{

				&:after{
					border-color: $blue-light;
				}
			}
		}
	}

	&:nth-of-type(4n + 5){

		.inner{
			background-color: $blue;

			.img-side{
				padding-left: 0;
				padding-right: 1.5rem;

				@media (min-width: $b3 + 1){
					order: 2;
				}
			}

			.img-con{

				&:before{
					left: auto;
					right: -1.5rem;
				}

				&:after{
					border-color: $blue;
					left: auto;
					right: 1.1rem;
				}
			}

			.text-side{
				text-align: right;

				*{
					color: $white;
				}

				@media (min-width: $b3 + 1){
					padding-left: 7.2rem;
				}

				h4{
					padding-left: 8rem;
					background-image: url(../images/ship-line-left-white.svg);
					background-position: 0% 100%;

					@media (min-width: $b3 + 1){
						padding-right: 6.5rem;
					}
				}

				p{

					@media (min-width: $b3 + 1){
						padding-left: 4rem;
					}
				}

				.btn-con{
					justify-content: right;

					.btn{
						border-color: $white;
						color: $white!important;

						&:hover,
						&:focus-visible{
							border-color: $white;
							background-color: $white;
							color: $blue!important;
						}
					}
				}
			}
		}
	}

	.inner{
		background-color: $coral;
		display: flex;
		flex-wrap: wrap;

		.img-side{
			width: 30.5%;
			padding-left: 1.5rem;
			padding-top: .8rem;

			@media (max-width: $b3){
				width: 100%;
			}
		}

		.img-con{
			position: relative;
			z-index: 1;

			&:before{
				content: '';
				position: absolute;
				top: -.8rem;
				left: -1.5rem;
				width: calc(100% + .3rem);
				max-width: calc(100% + .3rem);
				height: 1.6rem;
				background-color: $white;
				z-index: -1;
			}

			&:after{
				content: '';
				position: absolute;
				width: 99.7%;
				height: 99.6%;
				top: -.8rem;
				left: 1.1rem;
				border: .3rem solid $coral;
			}
		}

		.text-side{
			width: 69.5%;
			padding: 5.4rem 5.6rem 3.4rem;

			@media (max-width: $b3){
				width: 100%;
				padding: 1.5rem 1.5rem 1.5rem;
			}

			*{
				color: $blue;
			}

			h4{
				padding-bottom: 2.6rem;
				background-repeat: no-repeat;
				background-size: 107.02204rem auto;
				margin-bottom: .65em;

				@media (max-width: $b4){
					padding-bottom: 5.6rem;
				}
			}

			p{
				@include line-height(19,23);
				margin-bottom: 1em;

				&.small{
					@include line-height(17,30);
					font-weight: 500;
					letter-spacing: .087em;
				}
			}

			.btn{
				border-width: .6rem;
				font-size: 1.6rem !important;
				padding: 1.2rem 1.3rem;
			}
		}
	}
}

.follow-us-here-section{

	&:has(+ .darwin200-events-section){
		margin-bottom: 0;
	}
}

.darwin200-events-section{
	background-attachment: fixed;
	background-size: cover;
	background-position: 50% 100%;
	background-image: url(../images/back-grey-team.jpg);
	position: relative;

	&::before{
		content: '';
		position: absolute;
		right: -31.3rem;
		background-image: url(../images/leg-ship.svg);
		background-repeat: no-repeat;
		width: 127.9rem;
		height: 81.1rem;
		background-size: 100% 100%;
		top: -1.4rem;
		pointer-events: none;

		@media (max-width: $b3){
			display: none;
		}
	}

	&::after{
		content: '';
		position: absolute;
		left: -28.4rem;
		bottom: 32.3rem;
		width: 53.44946rem;
		height: 17.30278rem;
		background-image: url(../images/waves.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: 100% 0;

		@media (max-width: $b3){
			display: none;
		}
	}

	&.past{

		@media (min-width: $b3 + 1){

			&::after{
				bottom: 35.6rem;
			}
			
			.row{
				padding-top: 37.4rem;
				padding-bottom: 18.5rem;
			}
		}
	}

	.container{
		max-width: 161.6rem;
	}

	.row{
		padding-top: 27.4rem;
		padding-bottom: 15.2rem;

		@media (max-width: $b3){
			padding-top: 9.4rem;
			padding-bottom: 8.3rem;
		}
	}

	.title-con{
		text-align: center;
		margin-bottom: 17.4rem;

		@media (max-width: $b3){
			margin-bottom: 10rem;
		}
	}

	h1{
		margin-left: auto;
		margin-right: auto;
		width: 90rem;
		@include line-height(72,68);

		@media (min-width: $b3 + 1){
			@include font-size(72);
		}
	}
}

.ev-item{
	margin-bottom: 5.6rem;
		
	.inner{
		background-color: $blue;
		display: flex;
		flex-wrap: wrap;
		padding-bottom: 1.5rem;

		.img-side{
			width: 45.3%;
			padding-left: 1.5rem;
			padding-top: .8rem;

			@media (max-width: $b3){
				width: 100%;
			}

			img{
				width: 100%;
			}
		}

		.img-con{
			position: relative;
			z-index: 1;

			&:before{
				content: '';
				position: absolute;
				top: -.8rem;
				left: -1.5rem;
				width: calc(100% + .3rem);
				max-width: calc(100% + .3rem);
				height: 1.6rem;
				background-color: $white;
				z-index: -1;
			}

			&:after{
				content: '';
				position: absolute;
				width: 99.7%;
				height: 99.6%;
				top: -.8rem;
				left: 1.1rem;
				border: .3rem solid $blue;
			}
		}

		.text-side{
			width: 54.7%;
			padding: 3.8rem 3.6rem 0rem;

			@media (max-width: $b3){
				width: 100%;
				padding: 1.5rem 1.5rem .5rem;
			}

			*{
				color: $white;
			}

			.date{
				font-weight: 600;
				@include line-height(20,23);
				letter-spacing: .037em;
				text-transform: uppercase;
				margin-bottom: .4em;

				@media (min-width: $b3 + 1){
					@include font-size(20);
				}
			}

			.location{
				@include line-height(20,23);
				text-transform: uppercase;
				margin-bottom: 1.55em;

				@media (min-width: $b3 + 1){
					@include font-size(20);
				}
			}

			h4{
				@include line-height(42,41);
				margin-bottom: .95em;
			}

			p{
				@include line-height(19,23);
				margin-bottom: 1em;

				&.small{
					@include line-height(17,30);
					font-weight: 500;
					letter-spacing: .087em;
				}
			}

			.btn-con{
				justify-content: flex-start;
				margin-top: 3.8rem;

				.btn{
					border-width: .6rem;
					font-size: 1.6rem !important;
					padding: 1.2rem 2.1rem;
					border-color: $white;
					color: $white!important;

					&:hover,
					&:focus-visible{
						border-color: $white;
						background-color: $white;
						color: $blue!important;
					}
				}
			}

		}
	}
}




































@media (min-width: $b3 + 1){
	
	.container{
		max-width: ($base-widthWO + $grid-gutter-widthWO) / 10 + rem;
	}

	.container,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: $grid-gutter-widthWO / 2 / 10 + rem;
		padding-left: $grid-gutter-widthWO / 2 / 10 + rem;
	}

	.row{
		margin-right: -$grid-gutter-widthWO / 2 / 10 + rem;
		margin-left: -$grid-gutter-widthWO / 2 / 10 + rem;
	}
}

@media (max-width: $b3){

	.container,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: 2.75rem;
		padding-left: 2.75rem;
	}

	.row{
		margin-right: -2.75rem;
		margin-left: -2.75rem;
	}
}


// @media (max-width: $b3){

// 	.container,
// 	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
// 		padding-right: 2rem;
// 		padding-left: 2rem;
// 	}

// 	.row{
// 		margin-right: -2rem;
// 		margin-left: -2rem;
// 	}
// }