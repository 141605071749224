footer{
	position: relative;
	background-color: $green;
	background-position: 50% 0%!important;

	@media (max-width: $b3){
		background-position: 51% 0%!important;
	}

	&:after{
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 95%;
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
	}


	*{
		color: white;
	}

	a{
		text-decoration: none;
		display: inline-block;

		&:hover,
		&:focus-visible{
			color: $coral;
			text-decoration: none;
		}
	}

	.container {
		max-width: 173.1rem;
		position: relative;
		z-index: 1;
	}

	.footer-top-outer{
		padding-top: 34.4rem;

		@media (max-width: $b3){
			padding-top: 1.2rem;

			.container{
				padding-left: 51px;
				padding-right: 51px;
			}
		}

		.menu,
		.contact,
		.links{
			padding-bottom: 1rem;
			min-height: 25.4rem;

			@media (max-width: $b3){
				min-height: 0;
			}
		}

		.menu,
		.contact{
			padding-top: 6.3rem;
		}

		.menu{

			@media (min-width: $b3 + 1){
				flex: 0 0 48.4%;
				max-width: 48.4%;
			}

			@media (max-width: $b3){
				order: 2;
			}
		}

		.contact{
			
			@media (min-width: $b3 + 1){
				flex: 0 0 24.4%;
				max-width: 24.4%;
				border-right: 1px solid white;
				padding-right: 12.2rem;
			}

			@media (max-width: $b3){
				order: 3;
				padding-top: 3.1rem;
			}

			> div{

				@media (max-width: $b3){
					display: flex;
					justify-content: center;
				}
			}

			div{
				text-align: right;
				@include font-size(15);
				@include line-height(15,24);
			}

			a{
				@include font-size(15);
				@include line-height(15,24);
				display: inline-block;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				@media (max-width: $b3){
					min-width: 190px;
				}

				i{
					margin-left: 1em;
					@include font-size(23);
					opacity: .72;
				}
			}
		}

		.links{
			padding-top: 3.2rem;

			@media (min-width: $b3 + 1){
				flex: 0 0 27.2%;
				max-width: 27.2%;
				padding-right: 1.1rem;
				padding-left: 12.3rem;
			}

			@media (max-width: $b3){
				order: 1;
				display: flex;
				flex-direction: column;
				margin-bottom: 26.1rem;
			}

			h5{
				@include font-size(25);
				@include line-height(25,30);
				font-weight: 700;
				margin-top: .45em;
				letter-spacing: 0;

				@media (max-width: $b3){
					order: 1;
					margin-bottom: -0.5em;
				}
			}

			.social-menu{

				@media (max-width: $b3){
					order: 3;
				}
			}
		}

		#footer-logo{
			display: block;
			height: auto;
			margin: 0;
			transition: $time;
			width: 33.574rem;
			transition: 0s;
			transition: $time;
			margin-bottom: 2.5rem;

			@media (max-width: $b3){
				order: 2;
				margin-bottom: 1.7rem;
			}
		
			svg,
			img{
				display: block;
				width: 100%;
		
				path{
					fill: $white;
				}
			}
		}

		.footer-menu{
			@include font-size(15);
			@include line-height(15,24);
			margin: 0;

			@media (min-width: $b3 + 1){
				column-count: 3;
			}

			@media (max-width: $b3){
				padding-left: 1rem;
			}

			li{
				@include font-size(15);
				@include line-height(15,24);
				margin-bottom: .45em;

				@media (max-width: $b3){
					margin-bottom: .7rem;
				}
			}

			a{
				@include font-size(15);
				@include line-height(15,24);
				color: white;
				text-transform: uppercase;
				font-weight: 500;
				letter-spacing: .01em;

				&:hover,
				&:focus-visible{
					color: $coral;
					text-decoration: none;
				}
			}
		}

		.btn-con{
			margin-top: 4rem;

			@media (max-width: $b3){
				margin-top: 2rem;
				order: 4;
			}

			.btn{
				width: 100%;
			}
		}
	}

	.footer-middle-outer{

		.row{
			justify-content: center;
			align-items: center;
			padding-top: 2rem;
			padding-bottom: 1rem;

			.col-6{
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 1rem;

				img{
					width: 100%;
					max-width: 20rem;
				}
			}
		}
	}

	.footer-bottom-outer{
		padding-top: 2rem;
		padding-bottom: 8.6rem;

		@media (max-width: $b3){
			padding-top: 3.4rem;
			padding-bottom: 6rem;

			.container{
				padding-left: 51px;
				padding-right: 51px;
			}
		}

		.copy{
			@include font-size(14);
			@include line-height(14,23);
			text-align: center;
			letter-spacing: 0;
			margin-right: auto;
			margin-left: auto;

			@media (max-width: $b3){
				@include font-size(16);
				@include line-height(16,22);

				br{
					display: none;
				}
			}

			a{
				text-decoration: none;
				letter-spacing: 0;

				&:hover,
				&:focus-visible{
					text-decoration: none;
					color: $coral;
				}
			}
		}
	}
}