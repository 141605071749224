html{
	font-size: 62.5%;
	font-size: resize(10px);
	font-family: $main-font;
	font-weight: 400;
	margin-top: 0 !important;
	//overflow-x: hidden;

	@media (max-width: $b3){
		
		&.menu-open{
			overflow: hidden;
		}
	}

	@media (max-width: $b3){
		font-size: 10px;
	}

	@media (max-width: 380px){
		font-size: 8px;
	}

	&#BTT{
		margin-top: 0 !important;
	}

	&.search-open{
		overflow: hidden;
	}
}

body{
	color: $colour;
	@include font-size(19);
	@include line-height(19,32);
	font-weight: 400;
	position: relative;
	//overflow: hidden;

	@media (max-width: $b3){
		@include font-size(18);
		@include line-height(18,28);
	}
	
	&::-webkit-scrollbar{
		display: none;
	}

	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */

	&:before,
	&:after,
	*{

		&::-webkit-scrollbar {
			display: none;
		}

		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}

	*{
		&::-webkit-scrollbar{
			display: none;
		}
	
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	
		&:before,
		&:after,
		*{
	
			&::-webkit-scrollbar {
				display: none;
			}
	
			-ms-overflow-style: none;  /* IE and Edge */
			scrollbar-width: none;  /* Firefox */
		}
	}
}

#wpadminbar{
	display: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
address,
blockquote,
pre,
table,
li{
	color: $colour;
	margin-top: 0;
	font-weight: 400;
	margin-bottom: 1.5em;
	@include font-size(19);
	@include line-height(19,32);

	@media (max-width: $b3){
		@include font-size(18);
		@include line-height(18,28);
	}

	sub, 
	sup{
		font-size: 60%;
	}

	a{
		font-size: inherit;
		text-decoration: underline;
		font-weight: inherit;
		color: inherit;

		&:hover,
		&:focus-visible{
			color: $black;
			text-decoration: none;
		}
	}

	strong{
		font-weight: 900;
		color: inherit;
	}

	em{
		
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
blockquote{
	font-family: $alt-font;
	margin-bottom: .45em;
	font-weight: 900;
	color: $blue;
	text-transform: uppercase;

	img{
		display: inline-block;
		margin: 0 .113em!important;
	}
}

h2,
h3,
h4,
h5,
h6{

	em{
		font-weight: 400;
	}

	strong em{
		font-weight: 900;
	}
}

h1{
	@include font-size(77);
	@include line-height(77,96);
	letter-spacing: .05em;
	font-weight: 700;
	margin-bottom: -0.08em;

	@media (max-width: $b3){
		@include font-size(49);
	}

	@media (max-width: 380px){
		@include font-size(46);
	}
}

h2{
	@include font-size(62);
	@include line-height(62,91);

	@media (max-width: $b3){
		@include font-size(45);
	}

	@media (max-width: 380px){
		@include font-size(43);
	}
}

h3{
	@include font-size(52);
	@include line-height(52,54);

	@media (max-width: 380px){
		@include font-size(38);
	}
}

h4{
	@include font-size(42);
	@include line-height(42,41);
	font-weight: 700;
	letter-spacing: .02em;
	margin-bottom: .2em;

	@media (max-width: 380px){
		@include font-size(36);
	}
}

h5{
	@include font-size(26);
	@include line-height(26,25);
}

h6{
	@include font-size(19);
	@include line-height(19,32);
	padding-left: 3.95rem;
	position: relative;
	font-weight: 500;
	color: $colour;
	letter-spacing: .197em;
	text-transform: uppercase;
	width: 52rem;
	opacity: .56;
	margin-bottom: 1em;

	@media (max-width: $b3){
		@include font-size(18);
		@include line-height(18,28);
	}

	&::before{
		content: '';
		position: absolute;
		top: .46em;
		bottom: .46em;
		background-color: $colour;
		left: 0;
		width: .3rem;
	}
}

p{
	@include font-size(19);
	@include line-height(19,32);
	letter-spacing: .028em;
	margin-bottom: .6em;

	@media (max-width: $b3){
		@include font-size(18);
		@include line-height(18,28);
	}

	&.small{
		@include font-size(17);
		@include line-height(17,32);
	}

	&.large{
		@include font-size(22);
		@include line-height(22,29);
		letter-spacing: .024em;

		@media (max-width: $b3){
			@include font-size(19);
			@include line-height(19,29);
		}
	}
}

mark{
	background-color: $coral;
	font-weight: 400;
	box-shadow: .05em 0 0 $coral, -.05em 0 0 $coral;
}

strong{
	font-weight: 600;
}

blockquote{
	@include font-size(21);
	@include line-height(21,32);
	font-family: $alt-font;
	margin: 0 0 1.35em;
	font-weight: 500;
	letter-spacing: .037em;
	padding: 0;
	font-style: normal;
	color: $colour;
	background-position: 50% 0;
	padding: 3rem 0 ;
	position: relative;
	z-index: 1;
	text-transform: none;

	&:before{
		content: '';
		position: absolute;
		top: .3em;
		left: -.9em;
		font-style: italic;
		width: 5.58271rem;
		height: 4.00205rem;
		background-size: 100% auto;
		background-image: url(../images/quote-start.svg);
		background-repeat: no-repeat;
	}

	&:after{
		content: '';
		top: 0;
		right: -1%;
		font-style: italic;
		display: inline-block;
		width: 5.58271rem;
		height: 4.00205rem;
		background-size: 100% auto;
		background-image: url(../images/quote-end.svg);
		background-repeat: no-repeat;
		margin-bottom: -1em;
		margin-left: -.5em;
	}

	~ .by{
		font-weight: 600;
		margin-bottom: .05em;
	}

	p{
		font-size: inherit;
		line-height: inherit;
		font-family: inherit;
		margin-bottom: 0!important;
	}
}

button{
	box-shadow: none;
	background-color: transparent;
	border: none;
	padding: 0;
}

button,
a{
	font-size: inherit;
	color: $colour;
	font-weight: 400;
	transition: $time;
	cursor: pointer;
	text-decoration: underline;

	&:before,
	&:after,
	*:before,
	*:after,
	*{
		transition: $time;
	}

	&:hover,
	&:focus-visible{
		color: $black;
		text-decoration: underline;
	}
}

hr{
	border: 0;
	width: 23.8rem;
	height: .3rem;
	margin: 1rem auto 1rem;
	background-color: $colour;
}

address{
	font-style: normal;
	@include font-size(19);
	@include line-height(19,32);
	color: $colour;
	font-weight: 400;
	margin-bottom: 1.3em;

	@media (max-width: $b3){
		@include font-size(18);
		@include line-height(18,28);
	}
}

ol,
ul,
dl{
	margin: 0;
	padding-left: 0;
	list-style-position: inside;
	font-size: 0;
	margin-bottom: .7em;

	li,
	dt,
	dd{
		@include font-size(19);
		@include line-height(19,32);
		margin-bottom: 0;

		@media (max-width: $b3){
			@include font-size(18);
			@include line-height(18,28);
		}

		a{
			font-size: inherit;
		}
	}

	dd{
		margin-bottom: 20px;
	}
}

.acf_content ol,
.acf_content ul,
.acf_content dl,
main ol,
main ul,
main dl{
	@include font-size(19);
	margin-bottom: .7em;

	@media (max-width: $b3){
		@include font-size(18);
	}
}

ul{
	list-style-position: inside;
	padding-left: 0;

	&.no-bullet{
		list-style: none;

		li{
			padding-left: 0;

			&:before{
				display: none;
			}
		}
	}

	li{
		padding-left: 1.5em;
		list-style: none;
		position: relative;

		&:before{
			content: '';
			position: absolute;
			left: 0em;
			top: .7em;
			width: .4em;
			height: .4em;
			border-radius: 1em;
			background-color: $colour;
		}

		ul{
			margin-top: 1em;
			margin-bottom: 1em;

			li{

				&:before{
					border-radius: 0;
				}
			}
		}
	}
}

ul.side-menu,
ul.sticky-menu,
ul.filter-items,
ul.woocommerce-PaymentMethods,
ul.woocommerce-shipping-methods,
ul.woocommerce-order-overview,
ul.wc_payment_methods,
ul.select2-results__options,
.woocommerce-MyAccount-navigation ul,
ul.woocommerce-error,
ul.matching-items,
ul.post-categories,
ul.tabs,
ul.small-menu,
ul.main-menu,
ul.footer-menu,
ul.sub-menu,
ul.g_fields,
ul.slick-dots,
ul.social-menu,
ul.flex,
ul.slick{
	list-style: none;
	padding-left: 0;
	padding-top: 0;

	> li{
		padding-left: 0;
		margin-bottom: 0;

		&:before,
		&:after{
			display: none;
		}
	}
}

ol:not(.pagination){
	list-style-position: outside;
	padding-left: 0;
	counter-reset: section;

	li{
		list-style: none;
		position: relative;

		&:before{
			counter-increment: section;
			content: counter(section)". ";
			right: calc(100% - .5em);
			min-width: 1.5em;
			display: inline-block;
		}
	}
}

table{
	width: 100%;
	background-color: transparent;
}

table,
table tr,
table th,
table td{
	border: none;
	border-collapse: collapse;
	padding: 6.8px 5px;
	@include font-size(22);
	@include line-height(22,22);
	letter-spacing: .02em;
	vertical-align: middle;
	text-align: left;
	font-weight: 400;
	color: $colour;
	
	@media (max-width: $b3){
		font-size: 1.4rem!important;
	}

	tr{
		height: auto!important;
		padding: 0;

		&:first-of-type{

			th,
			td{
				padding-top: 0!important;
			}
		}

		&:last-of-type{

			th,
			td{
				padding-bottom: 0!important;
			}
		}
	}

	th{
		font-weight: 900;
		border-left: .3rem solid rgba($blue, .41);
		padding: 1.3rem 1.3rem 1.3rem 3.6rem;
		color: $blue;
	}

	th,
	td{
		width: auto!important;
		height: auto!important;
	}
}

html#BTT body table{
	margin-bottom: 1.5em;
	border: none;
	width: auto!important;

	thead th{
		border: none;
		color: $blue;
		border-left: .3rem solid rgba($blue, .41);
		border-bottom: .3rem solid rgba($blue, .41);
		padding: 1.3rem 1.3rem 1.3rem 3.6rem!important;

		+ th{
			padding: 1.3rem 1.5rem!important;
			border-left: none;
		}


		*{
			color: $blue;
		}
	}

	tfoot th,
	tfoot td,
	tbody th,
	tbody td{
		border-bottom: none;
		border-top: none;
		padding: 1.3rem 1.5rem;
		vertical-align: middle;

		&:first-of-type{
			font-weight: 900;
			border-left: .3rem solid rgba($blue, .41);
			padding: 1.3rem 1.3rem 1.3rem 3.6rem;
			color: $blue;

			+ th,
			+ td{
				padding: 1.3rem 1.5rem;
				border-left: none;
			}

			p{
				padding: 0;
			}
		}

		&:not(:first-of-type){
			font-style: italic;
			color: rgba($blue, .6);
		}
	}
}

.wp-caption{

	.wp-caption-text{
		@include font-size(16);
		@include line-height(16,28);
		font-weight: 400;
		color: $colour;

		@media (max-width: $b3){
			
		}

		&:last-of-type:last-of-type{
			margin-bottom: 0;
			padding-top: 1.1rem;

			@media (max-width: $b3){
				padding-top: .3rem;
			}
		}
	}
}

svg,
p.p-img a,
.wp-caption,
video,
img{
	max-width: 100%;
	width: auto;
	height: auto;
	display: block;

	&.alignnone{
		margin: 0em 0 1.55em;
	}

	&.alignright {
		float:right;
		margin: 0em 0 1.55em 1.55em;
	}

	&.alignleft {
		float:left;
		margin: 0em 1.55em 1.55em 0;
	}

	&.aligncenter {
		clear: both;
		display: block;
		margin: 0em auto 1.55em;
	}
}

a img.alignright {
	float:right;
	margin: 0em 0 1.55em 1.55em;
}

a img.alignleft {
	float:left;
	margin: 0em 1.55em 1.55em 0;
}

a img.aligncenter {
	clear: both;
	display: block;
	margin: 0em auto 1.55em;
}

::selection {
	background: darken($coral, 5%);
	color: white;
}

::-moz-selection {
	background: darken($coral, 5%);
	color: white;
}

.fieldset{
	margin-bottom: 0;

	.field{
		position: relative;
		padding-bottom: 1px;
	}

	div.mage-error[generated]{
		position: absolute;
		bottom: 5px;
	}
}

.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus-visible,
input:focus-visible,
textarea:focus-visible,
select:focus-visible,
input[name][name][name]:focus-visible,
textarea[name][name][name]:focus-visible,
select[name][name][name]:focus-visible{
	border-color: $coral!important;
}

.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus-visible,
input.mage-error:focus-visible,
select.mage-error:focus-visible,
textarea.mage-error:focus-visible,
.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid,
li.gfield.error input,
.gfield_error input,
.gfield_error select,
.gfield_error .dk-select,
.gfield_error textarea,
input.mage-error,
select.mage-error,
textarea.mage-error{
	border-color: $red!important;
}

div.mage-error[generated] {
	color: $red;
	position: absolute;
	margin-top: 4px;
	line-height: 1.1em;
	padding-top: 2px;
	margin-left: 0px;
	text-align: left;
}

pre{
	font-size: resize(10px);
	@include font-size(10);
}

i[class^="fa-"]{
	transition: 0s 0s!important;

	&:before,
	&:after,
	*{
		transition: 0s 0s!important;
	}

	&:before{
		transition: $time!important;
	}
}

svg{

	g{
		transition: 0s 0s!important;
	}
}
