//!!BUTTON
button:focus-visible,
.btn:focus-visible{
	outline: none;
}

.btn-con{
	display: flex;
	align-items: center;
	margin: 0 -1.6rem;
	max-width: calc(100% + 1.6rem + 1.6rem);
	flex-wrap: wrap;

	.btn{
		margin: 0 1.6rem 1.2rem;

		&:first-of-type:last-of-type{
			margin-bottom: 0;
		}
	}

	&.btn-con-center{
		justify-content: center;
	}

	&.btn-con-left{
		justify-content: flex-start;
	}

	&.btn-con-right{
		justify-content: flex-end;
	}
	
}

.btn, %btn{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 1.7rem!important;
	line-height: 1.2;
	font-weight: 700;
	color: $blue!important;
	text-decoration: none!important;
	font-family: $main-font;
	text-decoration: none;
	text-align: center;
	padding: 1.35rem 1.6rem;
	background-color: transparent;
	border: 1.1rem solid $blue;
	border-radius: 0;
	box-shadow: none;
	cursor: pointer;
	position: relative;
	transition: $time;
	
	*{
		transition: $time;
		color: $blue!important;
	}
	
	&:hover,
	&:focus-visible{
		outline: none;
		background-color: $blue;
		border-color: $blue;
		color: $white!important;

		*{
			color: $white!important;
		}
	}

	&.disabled,
	&:disabled{
		opacity: .5;
		pointer-events: none;
	}
}

.btn{

	&.white{
		background-color: transparent;
		border-color: $white;
		color: $white!important;

		*{
			color: $white!important;
		}

		&:hover,
		&:focus-visible{
			background-color: $white;
			border-color: $white;
			color: $blue!important;

			*{
				color: $blue!important;
			}
		}
	}

	&.coral{
		background-color: transparent;
		border-color: $coral;
		color: $coral!important;

		*{
			color: $coral!important;
		}

		&:hover,
		&:focus-visible{
			background-color: $coral;
			border-color: $coral;
			color: $white!important;

			*{
				color: $white!important;
			}
		}
	}

	&.big{
		font-size: 2.4rem!important;
		padding: 1.45rem 2.4rem;
		
		@media (max-width: $b3){
			padding: 1.45rem 1.45rem;
		}
	}

	&.small{
		padding: 1.35rem .6rem;
		font-size: 1.7rem!important;
		letter-spacing: 0em;
	}
}

