.woocommerce-page{

	.main-wrapper{

		// ::-webkit-input-placeholder {
		// 	@include font-size(24);

		// 	@media (max-width: $b2){
		// 		@include font-size(20);
		// 	}
		
		// 	@media (max-width: $b3){
		// 		@include font-size(16);
		// 	}
		// }
		 
		// :-moz-placeholder { /* Firefox 18- */
		// 	@include font-size(24);

		// 	@media (max-width: $b2){
		// 		@include font-size(20);
		// 	}
		
		// 	@media (max-width: $b3){
		// 		@include font-size(16);
		// 	}
		// }
		
		// ::-moz-placeholder {  /* Firefox 19+ */
		// 	@include font-size(24);

		// 	@media (max-width: $b2){
		// 		@include font-size(20);
		// 	}
		
		// 	@media (max-width: $b3){
		// 		@include font-size(16);
		// 	}
		// }
		
		// :-ms-input-placeholder {
		// 	@include font-size(24);

		// 	@media (max-width: $b2){
		// 		@include font-size(20);
		// 	}
		
		// 	@media (max-width: $b3){
		// 		@include font-size(16);
		// 	}
		// }

		// input,
		// textarea{
		// 	background-color: #f4f4f4;
		// 	border-color: #f4f4f4;
		// 	@include font-size(24);
		// 	color: $black;

		// 	@media (max-width: $b2){
		// 		@include font-size(20);
		// 	}
		
		// 	@media (max-width: $b3){
		// 		@include font-size(16);
		// 	}
		// }

		// input[type="radio"],
		// input[type="checkbox"]{
		// 	display: none;
		// 	border: none!important;
		// 	margin: 0!important;

		// 	& + span,
		// 	& + .wpcf7-list-item-label,
		// 	& + label{
		// 		padding-left: 49px!important;
		// 		@include font-size(24);
		// 		line-height: 39px!important;

		// 		@media (max-width: $b2){
		// 			@include font-size(20);
		// 		}
			
		// 		@media (max-width: $b3){
		// 			@include font-size(16);
		// 		}

		// 		&:before{
		// 			width: 39px;
		// 			height: 39px;
		// 			left: 0;
		// 			top: 0;
		// 			border: 1px solid #f4f4f4;
		// 			background-color: #f4f4f4;
		// 		}

		// 		&:after{
		// 			width: 27px;
		// 			height: 27px;
		// 			left: 6px;
		// 			top: 6px;
		// 		}
		// 	}
		// }
	}
}

.woocommerce-notices-wrapper{
	width: 100%;
}

.woocommerce.woocommerce{

	&.single-product{

		.woocommerce-breadcrumb{
			color: $white;

			a{
				color: $white;
			}
		}
	}

	input[type="radio"],
	input[type="checkbox"]{

		& + span,
		& + .wpcf7-list-item-label,
		& + label{
			padding-right: 0!important;
			padding-left: 4.3rem + 3.62rem!important;
			min-height: 4.3rem;
			margin-bottom: 5px;
			text-align: left!important;
			width: 100%;
			line-height: 4.3rem;

			@media (max-width: $b3){
				@include font-size(18);
			}

			&:before{
				left: 0;
				right: auto;
				top: 0rem;

				@media (max-width: $b3){
					top: 0;
				}
			}

			&:after{
				right: auto;
				left: 1rem;
				top: 1rem;

				@media (max-width: $b3){
					top: 1rem;
				}
			}
		}
	}

	.woocommerce-breadcrumb{
		max-width: $base-width + $grid-gutter-width;
		margin: 0 auto;
		padding: 2.1rem $grid-gutter-width/2;
		@include font-size(20);
		color: $colour;
		font-weight: 500;

		a{
			color: $colour;
			font-weight: 500;
			@include font-size(20);

			&:hover,
			&:focus-visible{
				color: $blue;
			}
		}

		span{
			margin-right: .8em;
			margin-left: .8em;
		}
	}

	.woocommerce-notices-wrapper{
	
		.woocommerce-message{
			max-width: ($base-widthWO + $grid-gutter-widthWO) / 10 + rem;
			margin: 0 auto 1em;

			@media (max-width: $b3){
				max-width: calc(100% - 3.1rem);
			}
			
		}
	}
}

.woocommerce.woocommerce.woocommerce.woocommerce.woocommerce.woocommerce,
.woocommerce-page.woocommerce-page.woocommerce-page.woocommerce-page.woocommerce-page.woocommerce-page{

	.lost_reset_password{
		width: 100%;
	}

	div.woocommerce{
		max-width: $base-width + $grid-gutter-width;
		padding-right: $grid-gutter-width/2;
		padding-left: $grid-gutter-width/2;
		margin: 0 auto;
		padding-top: 4em;
		padding-bottom: 4em;
		padding-top: 30.9rem;

		@media (min-width: $b3 + 1){
			max-width: ($base-widthWO + $grid-gutter-widthWO) / 10 + rem;
			padding-right: $grid-gutter-widthWO / 2 / 10 + rem;
			padding-left: $grid-gutter-widthWO / 2 / 10 + rem;
		}

		@media (max-width: $b3){
			padding-top: 20.9rem;
			padding-right: 2.75rem;
			padding-left: 2.75rem;
		}
	}

	.form-row{
		margin-bottom: 0;
		padding: 0;

		input.input-text,
		textarea{
			margin-bottom: $input-bottom-margin;
		}

		label{
			width: 100%;
			text-align: left;
		}
	}

	form .form-row-first,
	form .form-row-last,
	form .form-row-first,
	form .form-row-last{

		@media (max-width: $b4){
			width: 100%;
		}
	}

	.woocommerce-form-login{
		
		.form-row{
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.password-input{
				width: 100%;
			}
		}
	}

	.woocommerce-password-hint{
		margin-bottom: $input-bottom-margin;
	}

	[for=mailchimp_woocommerce_newsletter][for=mailchimp_woocommerce_newsletter][for=mailchimp_woocommerce_newsletter]{
		line-height: 1.3;
	}

	[for=mailchimp_woocommerce_newsletter],
	.woocommerce-form-login__rememberme{
		margin-bottom: $input-bottom-margin;
		display: inline-block;

		span{
			margin-bottom: 0px!important;
		}
	}

	[type="submit"]{
		margin-bottom: $input-bottom-margin;
	}

	form .form-row .required{
		color: $colour;
	}

	form .show-password-input{
		top: .3em;
	}

	form .show-password-input.display-password::after,
	form .show-password-input.display-password::after{
		color: $red;
	}

	form.checkout_coupon,
	form.login,
	form.register{
		border: none;
		padding: 0;
		margin-top: 0;
	}

	.woocommerce{

		input, 
		input.input-text, 
		textarea, 
		select{
			//background-color: #EAEBEC;
			//border-color: #EAEBEC;
		}
	}

	.select2-dropdown.select2-dropdown{
		max-width: none;
		//border: 1px solid #EAEBEC;
		//background-color: #EAEBEC;
		border-radius: 0;
		top: calc(100% - 4rem + 1px);
		box-shadow: none;
		@include font-size(27);

		&.select2-dropdown--above{
			top: calc(0% + 0px);
		}

		.select2-results{
			padding: 0 3.8rem 2.0rem;
		}

		ul{

			li{
				margin-bottom: 5px;
				padding: 0;
				line-height: 1.5;
			}
		}

		.select2-search__field{
			
		}

		.select2-search{
			padding: 2.0rem 3.8rem;
		}

		.select2-results__option[data-selected=true]{
			background-color: transparent;
		}

		.select2-results__option--highlighted[data-selected]{
			color: $purple;
			background-color: transparent;
			transition: $time;
		}

		.select2-search__field{
			margin-bottom: 0;
			border: none;
			border-bottom: 1px solid $blue;
			padding: 0;
			background-color: white;
		}
	}

	.select2-container.select2-container{
		margin-bottom: $input-bottom-margin;
		height: auto;
		border: none;
		background-color: transparent;
		border-radius: 0px;
		max-width: 100%;
		height: 4rem;
		border-bottom: 1px solid $blue;

		&--open{
			

			.select2-selection__arrow.select2-selection__arrow{
				background-image: url(../images/select-icon-alt.svg);
			}
		}

		&--focus{
			
		}

		.select2-selection--single.select2-selection--single{
			border: none;
			background-color: transparent;
		}

		.select2-selection--single{
			height: 4rem;
			border: 1px solid $blue;
			background-color: transparent;
			border-radius: 0;
			line-height: 4rem;
		}

		.select2-selection__rendered{
			height: 4rem;
			line-height: 4rem;
			padding-right: 50px;
			padding-left: 0;
			color: $colour;
			@include font-size(27);
		}

		.select2-selection__arrow{
			height:  4rem;
			background-image: url(../images/select-icon-alt.svg);
			background-repeat: no-repeat;
			background-position: 100% 50%;
			width: 4.5rem;
			top: 0;
			right: 0;

			b{
				display: none;
			}
		}
	}

	.woocommerce-terms-and-conditions-checkbox-text.woocommerce-terms-and-conditions-checkbox-text.woocommerce-terms-and-conditions-checkbox-text{
		
		&:after{
			content: ''!important;
			margin: 0!important;
		}
	}

	.woocommerce-button{

		+ .woocommerce-button{
			margin-left: .4rem;
		}
	}

	#respond input#submit,
	a.button,
	button.button,
	input.button{
		@extend %btn;
		min-width: 18.7rem;
		text-transform: uppercase;

		&:disabled{
			pointer-events: none;
		}
	}

	legend{
		padding-left: 1.0rem;
		padding-right: 1.0rem;
		margin-left: -1.0rem;
		font-weight: 700;
		text-align: left;
	}

	fieldset{
		margin: 0 0 2.0rem;
		padding: 1.8rem 1.8rem;
		border-color: $grey-light;
		border-style: solid;
		border-width: .2rem;
	}

	address{
		@include font-size(20);
		@include line-height(20,30);

		@media (max-width: $b3){
			@include font-size(16);
		}
	}

	.woocommerce-Address-title{
		display: flex;
		position: relative;
		margin-bottom: 1.2rem;

		h3{
			width: 100%;
			padding-right: 5.0rem;
			margin-bottom: 0;
			@include font-size(26);
			@include line-height(26,25);
			font-family: $main-font;
			font-weight: 700;
		}

		.edit{
			position: absolute;
			bottom: .25em;
			right: 0;

			&:hover,
			&:focus-visible{
				color: $blue;
			}
		}
	}

	a.remove{
		color: $red!important;
		font-weight: 400;
		line-height: 2.8rem;

		&:hover,
		&:focus-visible{
			background-color: $red;
			color: $white!important;
		}
	}

	.coupon.coupon.coupon{
		display: flex;

		@media (max-width: $b3){
			flex-wrap: wrap;
			justify-content: flex-end;
		}

		input,
		.button{
			margin-bottom: 1.0rem;
		}

		input{
			min-width: 27.0rem;
			margin-right: .7rem;
			// background-color: #EAEBEC;
			// border-color: #EAEBEC;

			@media (min-width: $b3 + 1){
				margin-bottom: 0;
			}

			@media (max-width: $b3){
				min-width: 0;
				width: 100%;
				margin-right: 0;
			}
		}

		.button{
			width: auto;
		}
	}

	table.cart{
		//background-color: #EAEBEC;
	}

	ul.wc_payment_methods.wc_payment_methods.wc_payment_methods.wc_payment_methods.wc_payment_methods.wc_payment_methods{

		.wc_payment_method.wc_payment_method:before{
			display: none!important;
		}

	}

	table.shop_table:not(.cart){

		tr{
			background-color: transparent;
		}

		th{
			font-weight: 700!important;
			padding-left: 0;
		}

		

		td{
			padding-left: 0;
		}
	}

	.woocommerce{

		h2{
			color: $blue;
			@include font-size(26);
			@include line-height(26,25);
			font-family: $main-font;
			font-weight: 700;
			margin-bottom: .2em;
		}
	}

	table.shop_table{
		border: none;
		padding: 0;
		margin: 0;

		html#BTT &{
			width: 100%!important;
		}

		tr,
		td,
		th{
			border: none;
		}
	}

	table.shop_table td{

		@media (max-width: $b3){
			padding: .6rem .8rem;
		}
	}

	.wc-proceed-to-checkout{
		text-align: right;
		padding-bottom: 0;

		.button{
			width: 100%;
			margin-bottom: 0;
		}
	}

	table{
		text-align: left;
		border-radius: 0;

		th,
		tr,
		td{
			text-align: left;

			&.product-name.product-name.product-name.product-name.product-name.product-name,
			&.product-total.product-total.product-total.product-total.product-total.product-total{
				text-align: left;
			}
		}

		tbody{

			*{
				font-weight: 500;
			}

			tr{

				&:nth-of-type(odd){
					//background-color: #F3F3F3;
					background-color: transparent;

					th,
					td{
						background-color: transparent;
					}
				}

				&:nth-of-type(even){
					//background-color: #EAEBEC;
					background-color: transparent;

					th,
					td{
						background-color: transparent;
					}
				}

				&.cart_item + tr:not(.cart_item){
					background-color: white;
					padding-right: 0;
					padding-left: 0;

					td{
						padding-right: 0;
						padding-left: 0;
						text-align: right;
					}
				}
			}
		}

		.quantity.quantity.quantity{

			[type="number"]{
				width: 12.0rem;
				text-align: left;
				background-color: transparent;
				margin: 0 0;
				padding-left: 1.0rem;
			}
		}
		
	}

	.cart_totals{

		table{

			td{
				width: 22.0rem!important;

				@media (max-width: $b3 + 1){
					width: 100%!important;
				}
			}

			.order-total .woocommerce-Price-amount{
				font-weight: 700;
			}
		}
	}

	table{
		@include font-size(20);

		tr{
			border-bottom: none!important;
		}

		td,
		th{
			@include font-size(20);
			padding: 3.0rem 1.0rem;
		}

		.cart-subtotal,
		.order-total{

			td{
				color: $grey;
			}
		}

		small{
			color: lighten(grey, 5%);
			@include font-size(12);
			line-height: 1.4;
			display: block;

			.amount.amount.amount.amount.amount.amount{
				font-weight: 400;
			}

			*{
				@include font-size(12);
				line-height: 1.4;
			}
		}

		.product-subtotal{
			font-weight: 700;
		}

		&.woocommerce-cart-form__contents{


			@media (max-width: $b3){

				tbody{

					tr:first-of-type{

						td:first-of-type{
							border-top: none!important;
						}
					}
				}
			}
		}

		.actions{

			@media (max-width: $b3){
				padding-left: 0;
				padding-right: 0;
			}

			.button{
				width: auto;

				&:disabled{

					&:hover,
					&:focus-visible{
						background-color: $grey;
						border-color: $grey;
						color: $red!important;
					}
				}
			}
		}

		.cart_item{

			@media (max-width: $b3){
				padding: 0;
			}

			dl.variation{

				dd{
					margin-bottom: .3em;

					&:last-of-type{
						margin-bottom: 0;
					}
				}

				p{
					line-height: 1.2;
				}
			}
		}

		ul.wc-item-meta{

			li{
				margin-bottom: 0;

				&:last-of-type{
					margin-bottom: 0;
				}
			}

			p{
				line-height: 1.2;
			}
		}

		.product-remove{

			@media (max-width: $b3){
				padding-bottom: 0;
			}
		}

		.product-thumbnail + .product-name{

			@media (max-width: $b3){
				border-top: none!important;
			}
		}

		img{
			margin: 1rem auto;
			width: 9.0rem;
			min-width: 9.0rem;
		}

		.product-remove{
			width: 4.5rem !important;

			@media (max-width: $b3 + 1){
				width: 100%!important;
			}
		}

		.product-thumbnail,
		.product-price,
		.product-quantity,
		.product-subtotal{
			width: 5.0rem!important;

			@media (max-width: $b3 + 1){
				width: 100%!important;
			}
		}

		.product-quantity{

			@media (max-width: $b3 + 1){
				display: flex;
				align-items: center;
				justify-content: space-between;

			}
		}

		// input{
		// 	border-bottom: 1px solid $grey-light!important;

		// 	&:focus-visible{
		// 		border-bottom: 1px solid $red!important;
		// 	}
		// }

		.screen-reader-text{
			height: 0;
			width: 0;
		}

		&.shop_table{

			th,
			td{
				background-color: transparent;
			}
		}

		.shipping-calculator-button{
			white-space: pre;
			margin-top: 0;
		}

		.product-name{

			a{
				color: $colour;
				text-decoration: none;

				&:hover,
				&:focus-visible{
					color: $purple;
				}
			}
		}
	}

	.shop_table{

		// td:last-of-type{
		// 	width: 50px!important;
		// 	width: 210px!important;

		// 	label:before{
		// 		margin-top: 2px;
		// 	}

		// 	@media (max-width: $b3 + 1){
		// 		width: 100%!important;

		// 		&.product-total{
		// 			width: 50px!important;
		// 			width: 210px!important;

		// 			label:before{
		// 				margin-top: 2px;
		// 			}
		// 		}
		// 	}
		// }
	}

	.woocommerce ul#shipping_method li{
		padding-left: 0;
	}

	.woocommerce-checkout-review-order-table{

		.cart-subtotal,
		.order-total{

			td:last-of-type{

				@media (max-width: $b3 + 1){
					width: 15.0rem!important;

				}
			}
		}
	}

	.woocommerce-checkout{

		#createaccount{

			+ span:last-of-type{
				margin-bottom: 1.8rem;
			}
		}

		#terms{

			+ span{

				&:after{
					content: '*';
					color: $red;
					font-weight: 700;
					margin-left: .25em;
				}
			}

			~ span.required{
				display: none;
			}
		}

		h3{
			color: $blue;
			border-bottom: .2rem solid $grey-light;
			padding-bottom: 1.0rem;
		}

		h3#ship-to-different-address{
			float: none;
			min-height: 1.2728em;
			display: flex;
			align-items: flex-end;

			span{
				margin-bottom: 0;
			}

			label{
				margin-bottom: 0;
			}
		}

		.input-checkbox{
			display: none;
		}

		#order_review_heading{
			padding-top: 2.0rem;
		}
	}

	form .form-row.woocommerce-validated .select2-container,
	form .form-row.woocommerce-validated input.input-text,
	form .form-row.woocommerce-validated select{
		border-color: $green;
	}

	form .form-row.woocommerce-invalid .select2-container,
	form .form-row.woocommerce-invalid input.input-text,
	form .form-row.woocommerce-invalid select{
		border-color: $red;
	}

	form .form-row.woocommerce-invalid label{
		color: $red;
	}

	.woocommerce-checkout-payment{
		background-color: transparent!important;

		.input-checkbox{
			display: none;
		}

		.place-order{
			padding: 2.0rem 0 0!important;
		}

		.woocommerce-terms-and-conditions-wrapper{
			margin-bottom: 2.0rem;
		}
	}

	tbody{

		.button{
			margin-top: .5rem;
			margin-bottom: .5rem;
		}
	}

	tabel.order_details tbody{

		@media (max-width: $b3){

			.product-name{
				width: 100%!important;
			}
		}
	}

	.woocommerce-order{

		h2{
			@include font-size(42);
			@include line-height(42,41);
			font-weight: 900;
			color: $blue;
			margin-bottom: .45em;

			@media (max-width: 380px){
				@include font-size(36);
			}
		}
	}

	.woocommerce-customer-details address{
		border: .2rem solid $grey-light;
		border-radius: 0;
		line-height: 1.4em;

		p{
			line-height: 1.5em;
		}
	}

	form .form-row-first, 
	form .form-row-last, 
	form .form-row-first, 
	form .form-row-last{
		width: calc(50% - (#{$grid-gutter-width}/2));

		@media (min-width: $b3 + 1){
			width: calc(50% - 2.75rem);
		}

		@media (max-width: $b3){
			width: 100%;
		}
	}

	.col2-set{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.col-1,
		.col-2{
			padding-left: 0;
			padding-right: 0;
			width: 50%;
			flex: 0 0 50%;
			max-width: 50%;

			@media (max-width: $b3){
				width: 100%;
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		@media (min-width: $b3 + 1){
			.col-1{
				padding-right: calc(2.75rem);
			}

			.col-2{
				padding-left: calc(2.75rem);
			}
		}
	}
}

.woocommerce-checkout{

	.woocommerce &{
		padding-bottom: 3.0rem;
	}
}

.woocommerce td.product-name .wc-item-meta .wc-item-meta-label.wc-item-meta-label{
	float: none;
}

.woocommerce-error.woocommerce-error,
.woocommerce-info.woocommerce-info,
.woocommerce-message.woocommerce-message{
	padding: 1.0rem 2.75rem 1.0rem 2.75rem+2.75rem;
	border-top-width: 0;
	margin-top: 0;
	margin-bottom: 0px;
	background-color: $white;
	border-radius: 0;
	@include font-size(20);
	line-height: 1.3;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 8.4rem;
	margin-bottom: 2.0rem;
	flex-wrap: wrap;

	@media (max-width: $b3){
		padding: 1.0rem 2.75rem 1.0rem 2.75rem+2.75rem;
	}

	&:before{
		top: 50%;
		transform: translateY(-50%);
		left: 2.75rem;

		@media (max-width: $b3){
			left: 1.7rem;
		}
	}

	&:after{
		display: none;
	}

	.button.button.button.button.button.button{
		order: 2;
		width: auto;
		margin-top: .5rem;
		margin-bottom: .5rem;
	}

	@media (max-width: $b3){

		.button{
			width: 100%;
		}
	}
}

.woocommerce-message.woocommerce-message{
	border-top-color: $green;

	&:before{
		color: $green;
	}
}

.woocommerce-error.woocommerce-error{
	border-top-color: $red;
	color: $red;

	*{
		color: $red;
	}

	&:before{
		color: $red;
	}
}

.woocommerce-info.woocommerce-info{
	border-top-color: $green;

	&:before{
		color: $green;
	}
}

.woocommerce-view-order{

	@media (max-width: $b3){

		.product-name.product-name.product-name.product-name.product-name{
			width: 100%!important;
		}
	}
}

.page-id-1968{


	.woocommerce.woocommerce.woocommerce{
		padding-bottom: 10.0rem!important;
		padding-top: 30.9rem!important;

		@media (max-width: $b3){
			padding-bottom: 5.0rem!important;
			padding-top: 20.9rem!important;
		}
	}

	#add_payment_method #payment{
		padding: 1.5rem;
	}

	&.woocommerce-edit-address{

		.woocommerce-MyAccount-content.woocommerce-MyAccount-content.woocommerce-MyAccount-content{
			padding-top: .2rem;
		}
	}

	&.woocommerce-edit-account{

		.woocommerce-MyAccount-content.woocommerce-MyAccount-content.woocommerce-MyAccount-content{
			padding-top: 0;
		}
	}

	.woocommerce{
		display: flex;
		flex-wrap: wrap;

		.woocommerce-MyAccount-navigation{
			width: 18.0rem;

			@media (max-width: $b3){
				width: 100%;
				margin-bottom: 1em;
			}

			ul{

				@media (max-width: $b3){
					margin-bottom: 0;
					border-bottom: .1rem solid $grey-light;
					display: flex;
					flex-direction: column;
				}

				&.open{

					li.is-active{
						background-image: url(../images/arrow-up.svg);
					}

					li:not(.is-active){
						display: block;
					}
				}

			}

			li{
				padding-left: 0;
				line-height: 0;

				@media (max-width: $b3){

					&:nth-of-type(1){
						order: 2;
					}

					&:nth-of-type(2){
						order: 3;
					}

					&:nth-of-type(3){
						order: 4;
					}

					&:nth-of-type(4){
						order: 5;
					}

					&:nth-of-type(5){
						order: 6;
					}

					&:nth-of-type(6){
						order: 7;
					}

					&:nth-of-type(7){
						order: 8;
					}

					&:nth-of-type(8){
						order: 9;
					}

					&:nth-of-type(9){
						order: 10;
					}

					&:nth-of-type(10){
						order: 11;
					}

					&:nth-of-type(11){
						order: 12;
					}

					&.is-active{
						padding-right: 2.0rem;
						background-image: url(../images/arrow-down.svg);
						background-repeat: no-repeat;
						background-position: 100% 1.1rem;
						cursor: pointer;
						background-size: 1.9rem auto;

						a{
							pointer-events: none;
						}
					}

					&:not(.is-active){
						display: none;
					}
				}

				&.is-active{

					@media (max-width: $b3){
						order: 1;
					}

					a{
						padding-left: 2.0rem;
						background-position: 0 .4em;
						background-position: 0 50%;
						color: $blue;
						font-weight: 700;
					}
				}

				a{
					text-decoration: none;
					line-height: 1.2em;
					display: inline-block;
					background-image: url(../images/arrow-down.svg);
					background-repeat: no-repeat;
					overflow: hidden;
					background-position: -1.7rem .4em;
					background-position: -1.7rem 50%;
					background-size: 1.1rem auto;
					padding: .4rem 0;
					color: $colour;

					@media (max-width: $b3){
						line-height: 1.5;
						background-image: none;
						padding-left: 0!important;
					}

					&:hover,
					&:focus-visible{
						padding-left: 2.0rem;
						background-position: 0 .4em;
						background-position: 0 50%;


					}
				}
			}

			+ .woocommerce-MyAccount-content{
				width: calc(100% - 18.0rem);

				@media (min-width: $b3 + 1){
					padding-left: 2.75rem;
				}

				@media (max-width: $b3){
					width: 100%;
				}
			}
		}
	}
}

@media (max-width: $b3){
	.woocommerce.woocommerce table.shop_table_responsive tr td::before,
	.woocommerce-page.woocommerce-page table.shop_table_responsive tr td::before{
		content: attr(data-title);
	}
}