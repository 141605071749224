//!!MENU
header[role="banner"]{
	z-index: 50;
	width: 100%;
	transform: translateZ(0);
	perspective: 999999px;
	position: fixed;
	top: 0;
	left: 0;
	transition: $time;
	
	.scroll-down &,
	.scroll-up &{
		//transform: translateY(0%);
		//background-color: $teal-9;
		pointer-events: none;

		.account,
		.bag,
		.side{
			pointer-events: auto;
		}

		.side:before{
			opacity: 0;
			visibility: hidden;
		}

		#site-logo,
		#mainMenu{

			svg{
				filter: drop-shadow(0 .3rem .6rem rgba($black, .36));
			}

			a{
				text-shadow: 0 .3rem .6rem rgba($black, .36);
			}
		}

		#site-logo,
		#mainMenu{
			opacity: 0;
			visibility: hidden;
		}
	}

	@media (max-width: $b3){
		z-index: 111;
		margin-bottom: -1px;
	}


	// .scroll-down &,
	// .scroll-up &{

	// 	ul.main-menu.main-menu li > a{
	// 		color: $colour;

	// 		@media (max-width: $b3){
	// 			color: white;
	// 		}
	// 	}
	// }

	// .scroll-down &{
	// 	transform: translateY(-100%);
	// 	pointer-events: none;
	// }

	// .scroll-up &{
	// 	transform: translateY(0%);
	// 	background-color: white;
	// 	box-shadow: 0 10px 15px rgba(black, .1);

	// 	@media (min-width: $b3 + 1){
			
	// 		#site-logo{
	// 			margin-top: 30.89px;
	// 			margin-bottom: 30.89px;
	// 			width: 79px;
	// 			overflow: hidden;

	// 			img{
	// 				max-width: 280px;
	// 				width: 280px;
	// 			}
	// 		}
	// 	}
	// }


	&:has(+ .main-wrapper .woocommerce),
	&:has(+ .main-wrapper .product),
	&:has(+ .main-wrapper .shop-section),
	&:has(+ .main-wrapper .follow-our-journey-section),
	&:has(+ .main-wrapper .leader-hero-section),
	&:has(+ .main-wrapper .purple-hero-section:first-of-type),
	&:has(+ .main-wrapper .simple-hero-section:first-of-type),
	&:has(+ .main-wrapper .contact-section:first-of-type){
		
		.account,
		.bag,
		#site-logo{

			svg path{
				fill: $blue;
			}
		}

		.account,
		.bag{

			&:hover,
			&:focus-visible{
				
				svg path{
					fill: $coral;
				}
			}
		}

		ul.main-menu li > a{
			color: $blue;

			&:hover,
			&:focus-visible{
				color: $coral;
			}
		}

		.burger .line1, 
		.burger .line1:before,
		.burger .line1:after{
			background-image: url(../images/buger-line-blue.svg);
		}
	}
}

html.menu-open.menu-open.menu-open.menu-open.menu-open.menu-open{

	.burger .line1, 
	.burger .line1:before,
	.burger .line1:after{
		background-image: url(../images/buger-line.svg);
	}
}

#site-logo{
	display: block;
	height: auto;
	margin: 0;
	transition: $time;
	z-index: 1;
	width: 51.574rem;
	transition: 0s;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	transition: $time;

	@media (max-width: $b3){
		width: 23.974rem;
	}

	svg,
	img{
		display: block;
		width: 100%;

		path{
			fill: $white;
		}
	}
}

.menu-top-outer{
	transition: $time;
	padding-top: 11rem;

	@media (max-width: $b3){
		height: 13.5rem;
	}

	.woo-logins{
		display: flex;
		position: absolute;
		align-items: center;
		top: 6.3rem;
		right: 7.2rem;

		@media (max-width: $b3){
			top: 3.7rem;
			right: 4.6rem;
		}

		.bag{
			position: relative;
			width: 2.695rem;

			.cart-contents-count{
				position: absolute;
				top: 1rem;
				left: 50%;
				transform: translateX(-50%);
				width: 100%;
				text-decoration: none;
				width: 1.2rem;
				height: 1.2rem;
				border-radius: 1.2rem;
				line-height: 1.2rem;
				background-color: $blue;
				@include font-size(9);
				color: white;
				text-align: center;
			}
		}

		.account{
			width: 2.31rem;
			margin-left: 1.4rem;
		}

		.account,
		.bag{

			svg{
				filter: drop-shadow(0 0.33rem 0.6rem rgba(0, 0, 0, 0.36));
			}

			&:hover,
			&:focus-visible{
				
				svg path{
					fill: $coral;
				}
			}
		}
	}

	.side{
		width: 16.2rem;
		position: absolute;
		top: 0;
		left: 0;
		height: 100vh;

		@media (max-width: $b3){
			width: 13.3rem;
			height: 13.5rem;
		}

		// &::before{
		// 	content: '';
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	width: 100%;
		// 	height: 100%;
		// 	background-color: rgba($green, .31);
		// 	backdrop-filter: blur(1rem);
		// 	filter: brightness(.39);
		// 	transition: $time;
		// }

		.social-menu{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			flex-direction: column;

			@media (max-width: $b3){
				display: none;
			}
			
			a{
				margin: .42rem 0;
				text-shadow: 0 .3rem .6rem rgba($black, .36);
				pointer-events: auto;
			}

		}
	}

	.container{
		max-width: 159.6rem;
	}
 	
	.col-12{
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;

		@media (max-width: $b3){
			position: static;
		}
	}

	.right,
	.left{
		display: flex;
		align-items: center;
	}

	.left{
		justify-content: flex-start;
	}

	.right{
		justify-content: flex-end;
		align-self: flex-end;
	}
}

.menu-bottom-outer.menu-bottom-outer{
	position: fixed;
	left: 0;
	top: 0;
	z-index: 111;
	height: 100vh;
	width: 100vw;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: $time;
	
	.container{

		@media (max-width: $b3){
			padding-right: 8.9rem;
			padding-left: 8.9rem;
		}
	}

	.menu-open &{
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
	}

	*{
		color: white;
	}

	.row{
		height: 100vh;	
		padding-top: 14.3rem;
		padding-bottom: 12.8rem;

		@media (max-width: $b3){
			padding-top: 11rem;
			padding-bottom: 12.8rem;
			flex-direction: column;
			justify-content: flex-start;
			flex-wrap: nowrap;
			overflow: auto;
		}
	}

	.link-side{
		border-right: .4rem solid white;
		display: flex;
		align-items: center;

		@media (max-width: $b3){
			border: none;
			border-bottom: .4rem solid white;
			padding: 4.05rem 0 4.45rem;
			flex: 0;
		}
		
		@media (min-width: $b3 + 1){
			flex: 0 0 43.8%;
			max-width: 43.8%;
			padding-right: 21.2rem;
		}

		.side-menu{
			text-align: right;
			@include font-size(34);
			line-height: 1.2;
			margin-bottom: 0;
			width: 100%;

			@media (max-width: $b3){
				@include font-size(25);
				text-align: left;
			}

			li{
				@include font-size(34);
				line-height: 1.2;
				margin-bottom: .98em;

				&:last-of-type{
					margin-bottom: 0;
				}

				@media (max-width: $b3){
					@include font-size(25);
					margin-bottom: .64em;
				}
			}

			a{
				text-decoration: none;
				display: inline-block;
				@include font-size(34);
				line-height: 1.2;
				text-transform: uppercase;
				letter-spacing: .094em;
				font-weight: 600;

				@media (max-width: $b3){
					@include font-size(25);
				}

				&:hover,
				&:focus-visible{
					color: $coral;
				}
			}
		}
	}

	.mob-top{
		border-bottom: .4rem solid white;
		padding: 0 0 1.8rem;
		flex: 0;

		@media (min-width: $b3 + 1){
			display: none;
		}

		svg{
			width: 47.035rem;
			margin-bottom: 1.3rem;

			@media (max-width: $b3){
				width: 29.558rem;
				margin: 0 0 .8rem;
			}
		}

		h5{
			@include font-size(34);
			@include line-height(34,41);
			letter-spacing: 0;
			font-weight: 700;
			margin-bottom: .8em;

			@media (max-width: $b3){
				@include font-size(21);
				@include line-height(21,25);
			}
		}
	}

	.social-side{
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: center;

		@media (min-width: $b3 + 1){
			flex: 0 0 56.2%;
			max-width: 56.2%;
			padding-left: 11.8rem;
			padding-right: 14.3rem;
		}

		@media (max-width: $b3){
			padding: 5rem 0 0;
			flex: 0;
		}

		.top-con{
			padding-left: 9.3rem;

			@media (max-width: $b3){
				padding-left: 0;
			}

			svg{
				width: 47.035rem;
				margin-bottom: 1.3rem;

				@media (max-width: $b3){
					display: none;
				}
			}

			h5{
				@include font-size(34);
				@include line-height(34,41);
				letter-spacing: 0;
				font-weight: 700;
				margin-bottom: .8em;

				@media (max-width: $b3){
					display: none;
				}
			}

			.social-menu{

				a{
					min-width: 0;
					margin: 0 2.45rem .75rem 0;

					i{
						min-width: 0;
					}
				}
			}

			.contact{
				margin-top: 2.8rem;
				margin-left: -1rem;
				margin-bottom: 6.3rem;

				@media (max-width: $b3){
					margin-left: 0;
					margin-bottom: 0;
				}
				
				div{
					text-align: left;
					@include font-size(27);
					@include line-height(27,44);

					@media (max-width: $b3){
						@include font-size(18);
					}
				}
	
				a{
					@include font-size(27);
					@include line-height(27,44);
					display: inline-block;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					text-decoration: none;

					&:hover,
					&:focus-visible{
						color: $coral;
					}

					@media (max-width: $b3){
						@include font-size(18);
					}
	
					i{
						margin-right: 1em;
						@include font-size(27);
						opacity: .72;

						@media (max-width: $b3){
							@include font-size(21);
							margin-right: 1.1em;
						}
					}
				}
			}
		}

		.btn-con{
			margin: 0;
			padding: 0;
			width: 100%;
			border-top: .4rem solid white;
			padding-top: 8rem;

			@media (max-width: $b3){
				display: none;
			}

			.btn{
				margin: 0;
				width: 100%;
				font-size: 3.9rem!important;
				letter-spacing: .05em;
				font-weight: 700;
				padding: 3.65rem 0.7rem;
			}
		}
	}
}

.mob-only.mob-only{

	@media (min-width: 1300px + 1 ){
		display: none;
	}

	.social-links{
		justify-content: center;
	}
}

@import 'burger';

#mainMenu{
	margin: 0;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	transition: $time;

	@media (max-width: $b3){
		margin: 0;
		position: absolute;
		top: 0;
		background-color: $blue;
		left: 0;
		padding-top: 15.3rem;
		padding-bottom: 1rem;
		height: 100vh;
		width: 100%;
		flex-direction: column;
		justify-content: flex-start;
		opacity: 0;
		visibility: hidden;
		transition: $time;
		padding-right: 2.0rem;
		padding-left: 2.0rem;

		.menu-open &{
			opacity: 1;
			visibility: visible;
		}

		.main-menu{
			max-height: calc(100vh - 15.3rem - 1rem);
			overflow: auto;
		}
	}
}

@media (min-width: $b3 + 1){

	#mainMenu .main-menu .current-menu-ancestor:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-page-ancestor:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-menu-parent,
	#mainMenu .main-menu .current_page_parent:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-menu-item:not(.menu-item-object-custom){

		> a{
			color: $coral;
			text-decoration: none;
		}
	}
}

ul.main-menu.main-menu{
	margin: 0;
	padding: 0;
	@include font-size(0);
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	align-items: flex-end;
	justify-content: center;

	> li{
		width: calc((100% - 31.85rem - 31.85rem)/6);

		> a{
			width: 100%;
		}

		&:nth-of-type(3){
			margin-right: 31.85rem;
		}

		&:nth-of-type(4){
			margin-left: 31.85rem;
		}

		&:nth-of-type(1n + 1){

			> a{
				text-align: right;
				padding-left: 3rem;
				padding-right: 0;
			}
		}

		&:nth-of-type(1n + 4){

			> a{
				text-align: left;
				padding-left: 0;
				padding-right: 3rem;
			}

			> .sub-menu.sub-menu{
				right: auto;
				left: 0rem;
				text-align: left;

				li{
					text-align: left;
				}
			}
		}
	}
	
	li{
		@include font-size(0);
		line-height: 1;
		position: static;

		@media (max-width: $b3){
			width: 100%;
			align-items: flex-start;
			justify-content: flex-start;
		}

		@media (min-width: $b3 + 1){

			&:hover,
			&:focus-visible{
	
				> a{
					color: $coral;
					text-decoration: none;
				}
			}
		}

		@media (max-width: $b3){
			margin-bottom: 2.9rem;
		}
		
		&.no-link{

			> a{
				pointer-events: none;
			}
		}

		&.menu-item-has-children{
			flex-wrap: wrap;
			cursor: pointer;
			position: relative;

			> a{
				position: relative;
			}

			&.open{

				// &:before{
				// 	transform: translateY(-50%) rotate(0deg);
				// }

				// .sub-menu{
				// 	pointer-events: auto!important;
				// }

				

				> .sub-menu{
					max-height: 500px;
					pointer-events: auto;

					a{
						color: $coral;
						text-decoration: none;
					}
				}
			}
			
			@media (min-width: $b3 + 1){

				&:hover,
				&:focus-visible{

					> .sub-menu{
						max-height: 1000px;
						pointer-events: auto;

					}
				}
			}

			@media (max-width: $b3){
				
				> a{
					pointer-events: none;
				}

				&.open{

					> a{
						pointer-events: auto;
					}
				}
			}

			.sub-menu{
				position: absolute;
				opacity: 1;
				visibility: visible;
				background-color: transparent;
				transform: none;
				width: 15.7rem;
				max-width: 15.7rem;
				right: 0rem;
				top: 100%;
				pointer-events: none;
				padding: 0;
				margin: 0;
				justify-content: flex-start;
				max-height: 0;
				overflow: hidden;
				transition: $time;
				text-align: right;
				background-color: transparent;
				
				@media (max-width: $b3){
					position: static;
					width: 100%;
					max-width: 100%;
					padding: 0;
				}
				
				li{
					margin: 0 0;
					display: block;
					text-align: right;

					@media (min-width: $b3 + 1){
						
						+ li{
							margin: 1.48rem 0 0;
						}
					}

					@media (max-width: $b3){
						margin-bottom: 0;
						
						&:last-of-type{
							margin-bottom: 1rem;
						}
					}
				}

				a{
					@include font-size(21);
					letter-spacing: .01em;
					font-family: $main-font;
					font-weight: 500;
					padding: 0 0;

					@media (max-width: $b3){
						margin-bottom: 1rem;
					}
				}
			}
		}

		> a{
			@include font-size(18);
			@include line-height(18,22);
			color: $white;
			text-decoration: none;
			font-weight: 900;
			position: relative;
			font-family: $alt-font;
			position: relative;
			padding: .9rem 0;
			z-index: 2;
			display: inline-block;
			letter-spacing: .02em;

			@media (min-width: $b3 + 1){

				&:hover,
				&:focus-visible{
					color: $coral;
					text-decoration: none;
				}
			}

			@media (max-width: $b3){
				@include font-size(30);
				@include line-height(30, 48);
				padding: 0 0;
				color: white;
				text-align: center;
				margin-bottom: 1.8rem;
			}
		}
	}
}

.single-team,
.post-type-archive-team{

	.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu{

		#menu-item-369{
			> a{
				color: $coral;
			}
		}
	}
}

.error404,
.single-services,
.post-type-archive-services,
.single-team,
.post-type-archive-team{

	.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu{

		#menu-item-354{

			> a{
				color: white;
	
				&:hover,
				&:focus-visible{
					color: $coral;
				}
			}
		}
	}
}