@font-face {
	font-family: 'Cooper Hewitt Book';
	src: url('../fonts/CooperHewitt-Book.woff2') format('woff2'),
		url('../fonts/CooperHewitt-Book.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Cooper Hewitt Book';
	src: url('../fonts/CooperHewitt-BookItalic.woff2') format('woff2'),
		url('../fonts/CooperHewitt-BookItalic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Cooper Hewitt';
	src: url('../fonts/CooperHewitt-Medium.woff2') format('woff2'),
		url('../fonts/CooperHewitt-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Cooper Hewitt';
	src: url('../fonts/CooperHewitt-MediumItalic.woff2') format('woff2'),
		url('../fonts/CooperHewitt-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Cooper Hewitt';
	src: url('../fonts/CooperHewitt-Semibold.woff2') format('woff2'),
		url('../fonts/CooperHewitt-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Cooper Hewitt';
	src: url('../fonts/CooperHewitt-SemiboldItalic.woff2') format('woff2'),
		url('../fonts/CooperHewitt-SemiboldItalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Cooper Hewitt';
	src: url('../fonts/CooperHewitt-Bold.woff2') format('woff2'),
		url('../fonts/CooperHewitt-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Cooper Hewitt';
	src: url('../fonts/CooperHewitt-BoldItalic.woff2') format('woff2'),
		url('../fonts/CooperHewitt-BoldItalic.woff') format('woff');
	font-weight: 700;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Cooper Hewitt';
	src: url('../fonts/CooperHewitt-Heavy.woff2') format('woff2'),
		url('../fonts/CooperHewitt-Heavy.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Cooper Hewitt';
	src: url('../fonts/CooperHewitt-HeavyItalic.woff2') format('woff2'),
		url('../fonts/CooperHewitt-HeavyItalic.woff') format('woff');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('../fonts/Oswald-Bold.woff2') format('woff2'),
        url('../fonts/Oswald-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}



//BREAK POINTS
$b1:				1636px - 1px; //$base-width + $grid-gutter-width - 1px
$b2:				992px - 1px; //992px - 1px
$b3:				769px - 1px; //769px - 1px
$b4:				576px - 1px; //576px - 1px
$b5:				480px - 1px; //450px

//xs: 0,
//sm: 576px,
//md: 768px,
//lg: 992px,
//xl: $base-width

//!!COLOURS
$red:				#CE2E45;

$green-light:		#A8FE43;
$green:				#0D5949;

$coral:				#FE574C;

$blue-light:		#56E2F1;
$blue:				#002260;

$purple:			#311A30;

$grey-light:		rgba(#000, .3); 
$grey:				rgba(#000, .5); 

$white:				#ffffff;
$black:				#000000;

$colour: 			$grey;
$main-bg-colour:	$white;

$font-cooper:		'Cooper Hewitt', sans-serif;
//book - 400
//medium - 500
//semi - 600
//bold - 700
//heavy - 900

$font-oswald:		'Oswald', sans-serif;
//bold - 700


$main-font:			$font-cooper;
$alt-font:			$font-cooper;
$alt-font2:			$font-oswald;

//!!GRID SETTINGS
$grid-columns:		12 !default;
$grid-gutter-width:	55px !default;
$grid-gutter-widthWO: 55 !default;

//!!PAGE WIDTH
$base-width:		1636px;
$base-widthWO:		1636;

//!!SITE TRANSITIONS
$time:				.3s;
